import React from 'react'

import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'

import { DropdownContainer, DropdownControl, DropdownPopup } from './styles'

type Props = {
  control: React.ReactNode | ((open: boolean) => React.ReactNode)
  popup: React.ReactNode | ((onClose: () => void) => React.ReactNode)
  className?: string
  popupPosition?: 'left' | 'right'
  fullWidth?: boolean
}
const Dropdown: React.FC<Props> = ({ control, popup, className, popupPosition = 'right', fullWidth }) => {
  const { isOpen, containerRef, controlRef, onToggle, onClose } = useOutsideClickHandler<
    HTMLDivElement,
    HTMLDivElement
  >()
  return (
    <DropdownContainer className={className}>
      <DropdownControl ref={controlRef} onClick={onToggle}>
        {typeof control === 'function' ? control(isOpen) : control}
      </DropdownControl>
      {isOpen && (
        <DropdownPopup ref={containerRef} position={popupPosition} fullWidth={fullWidth}>
          {typeof popup === 'function' ? popup(onClose) : popup}
        </DropdownPopup>
      )}
    </DropdownContainer>
  )
}

Dropdown.defaultProps = {
  className: undefined,
  popupPosition: 'right',
  fullWidth: false
}

export default Dropdown
