import React, { useCallback } from 'react'

import { Text } from '@/components/typography/Text'
import { flatObject } from '@/helpers/flatObject'

import { LargeFieldBlockContainer } from './styles'

export type LargeFieldBlockProps = {
  label?: string
  error?: string
  info?: string

  className?: string
  children?: React.ReactNode
}
const LargeFieldBlock: React.FC<LargeFieldBlockProps> = ({ className, label, error, info, children }) => {
  const renderErrorsList = useCallback(
    (errorList: string[]) => (
      <ul>
        {errorList.map((errorMessage) => (
          <li key={errorMessage}>{errorMessage}</li>
        ))}
      </ul>
    ),
    []
  )

  const renderError = useCallback(() => {
    if (Array.isArray(error)) {
      return renderErrorsList(error)
    }
    if (typeof error === 'object') {
      const flatError = flatObject(error)
      return renderErrorsList(Object.keys(flatError).map((key) => flatError[key]))
    }
    return error
  }, [error, renderErrorsList])

  return (
    <LargeFieldBlockContainer className={className}>
      {label && (
        <Text size='xs' className='label'>
          {label}
        </Text>
      )}
      {children}
      {error && (
        <Text size='sm' weight='regular' className='error'>
          {renderError()}
        </Text>
      )}
      {info && (
        <Text size='sm' weight='regular' className='info'>
          {info}
        </Text>
      )}
    </LargeFieldBlockContainer>
  )
}

LargeFieldBlock.defaultProps = {
  label: undefined,
  error: undefined,
  info: undefined,

  className: undefined,
  children: undefined
}

export default LargeFieldBlock
