import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'

import LoginLayout from '@/components/layouts/LoginLayout'
import InputField from '@/components/fields/InputField'

import signUpSchema from '@/helpers/validators/forms/signUp'
import { mapAmplifyMessage } from '@/helpers/mapAmplifyMessage'
import { EMAIL_FIELD, FULL_NAME_FIELD, PASSWORD_FIELD } from '@/constants/forms/signUp'
import { SIGNIN_URL } from '@/constants/routes'

import {
  FieldWrapper,
  StyledForm,
  ActionButton,
  InputBlock,
  Title,
  ButtonsContainer,
  SuccessMessageWrapper,
  LinkButton,
  Info
} from '@/components/layouts/LoginLayout/styles'

export interface SignUpPageFormValues {
  [FULL_NAME_FIELD]: string
  [EMAIL_FIELD]: string
  [PASSWORD_FIELD]: string
}

const ERRORS: any = {
  InvalidPasswordException: PASSWORD_FIELD,
  UsernameExistsException: EMAIL_FIELD
}

const SignUpPage: React.FC = () => {
  const alert = useAlert()
  const navigate = useNavigate()
  const [user, setUser] = useState<any>()

  const handleMoveToSignIn = useCallback(() => {
    navigate(SIGNIN_URL)
  }, [navigate])

  const handleResendEmail = useCallback(async () => {
    try {
      await Auth.resendSignUp(user?.username)
      alert.show('The confirmation email has been resent.')
    } catch (e: any) {
      alert.show(e.message)
    }
  }, [user, alert])

  const handleSubmit = useCallback(
    async (values: SignUpPageFormValues, actions: any) => {
      setUser(undefined)
      try {
        const email = values[EMAIL_FIELD].toLowerCase()
        const { user: newUser, userConfirmed } = await Auth.signUp({
          username: email,
          password: values[PASSWORD_FIELD],
          attributes: {
            name: values[FULL_NAME_FIELD],
            email
          }
        })
        if (userConfirmed) {
          handleMoveToSignIn()
        } else {
          setUser(newUser)
        }
      } catch (e: any) {
        if (ERRORS[e.code]) {
          actions.setFieldError(ERRORS[e.code], mapAmplifyMessage(e.message))
        }
      }
    },
    [handleMoveToSignIn]
  )

  const renderSuccessMessage = useCallback(
    () => (
      <SuccessMessageWrapper center>
        <p>
          We&apos;ve sent you a confirmation email to
          <br />
          <b>{user?.username}</b>.
          <br />
          <br />
          Please check your email and click the link to confirm your account.
          <br />
          <br />
          If you can&apos;t find the email check your spam folder or click the link below to re-send.
        </p>
        <ButtonsContainer centerText margin='4px'>
          <LinkButton onClick={handleResendEmail}>Resend confirmation email</LinkButton>
        </ButtonsContainer>
      </SuccessMessageWrapper>
    ),
    [user, handleResendEmail]
  )

  return (
    <LoginLayout onBack={handleMoveToSignIn}>
      <Title>{user ? 'Check your email' : 'Create an account'}</Title>
      {(() => {
        if (user) {
          return renderSuccessMessage()
        }
        return (
          <Formik
            initialValues={{} as SignUpPageFormValues}
            validationSchema={signUpSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <StyledForm>
                <InputBlock>
                  <FieldWrapper key={FULL_NAME_FIELD}>
                    <InputField inputSize='md' autoFocus label='FULL NAME' name={FULL_NAME_FIELD} />
                  </FieldWrapper>
                  <FieldWrapper key={EMAIL_FIELD}>
                    <InputField inputSize='md' label='EMAIL ADDRESS' name={EMAIL_FIELD} />
                  </FieldWrapper>
                  <FieldWrapper key={PASSWORD_FIELD}>
                    <InputField inputSize='md' type='password' label='PASSWORD' name={PASSWORD_FIELD} />
                  </FieldWrapper>
                </InputBlock>
                <ButtonsContainer margin='16px'>
                  <ActionButton type='submit' size='lg' fullWidth disabled={!props.dirty}>
                    Create account
                  </ActionButton>
                </ButtonsContainer>
                <ButtonsContainer>
                  <Info>By сreating an account, you agree with our </Info>
                  <LinkButton onClick={handleMoveToSignIn}>Terms of Service</LinkButton>
                  <Info> and </Info>
                  <LinkButton onClick={handleMoveToSignIn}>Privacy Policy</LinkButton>
                </ButtonsContainer>
                <ButtonsContainer centerText>
                  <Info>Already have an account?</Info> <LinkButton onClick={handleMoveToSignIn}>Sign in</LinkButton>
                </ButtonsContainer>
              </StyledForm>
            )}
          </Formik>
        )
      })()}
    </LoginLayout>
  )
}

export default SignUpPage
