import React, { useMemo } from 'react'

import Dropdown from '@/components/controls/Dropdown'
import { Text } from '@/components/typography/Text'
import ChevronDown from '@/components/icons/menu/ChevronDown'
import Logo from '@/components/icons/Logo'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import DropdownMenuList, { DropdownMenuListItem } from '../DropdownMenuList'
import { Placeholder } from '../Placeholder'

import { AccountContainer, UserDetails } from './styles'

const Account = () => {
  const { attributes, loading, logout } = useCognitoUser()

  const items: DropdownMenuListItem[] = useMemo(
    () => [
      <UserDetails>
        <Text size='xs' className='name'>
          {attributes?.name}
        </Text>
        <Text size='xs' className='email'>
          {attributes?.email}
        </Text>
      </UserDetails>,
      [{ title: 'Logout', onClick: logout }]
    ],
    [attributes, logout]
  )

  return (
    <Dropdown
      fullWidth
      control={(open) => (
        <AccountContainer>
          <Logo />
          <div className='description'>
            <Text size='md' weight='semibold'>
              AirScape Console
              <ChevronDown className={['rotate180', open ? 'opened' : 'closed'].join(' ')} />
            </Text>
            <Text size='xs' weight='medium' className='second'>
              {loading && !attributes ? <Placeholder height='18px' /> : attributes?.email}
            </Text>
          </div>
        </AccountContainer>
      )}
      popup={<DropdownMenuList items={items} />}
    />
  )
}

export default Account
