import { StylesConfig } from '@/components/controls/Checkbox/types'
import theme from '../theme'

export const CHECKBOX_ACTIVE_STYLE_TYPE_COLORS: StylesConfig = {
    primary: {
        original: {
            background: theme.colors.primary[500],
            border: theme.colors.primary[500]
        },
        hover: {
            background: theme.colors.primary[700],
            border: theme.colors.primary[700]
        },
        disabled: {
            background: theme.colors.primary[200],
            border: theme.colors.primary[200]
        }
    },
    violet: {
        original: {
            background: theme.colors.violet[500],
            border: theme.colors.violet[500]
        },
        hover: {
            background: theme.colors.violet[700],
            border: theme.colors.violet[700]
        },
        disabled: {
            background: theme.colors.violet[200],
            border: theme.colors.violet[200]
        }
    },
    moss: {
        original: {
            background: theme.colors.moss[500],
            border: theme.colors.moss[500]
        },
        hover: {
            background: theme.colors.moss[700],
            border: theme.colors.moss[700]
        },
        disabled: {
            background: theme.colors.moss[200],
            border: theme.colors.moss[200]
        }
    }
}
