import React from 'react'
import { Outlet } from 'react-router-dom'

import SidebarMenu from '@/components/regions/SidebarMenu'

import { PageContent, PageLayout } from './styles'

const Page: React.FC = () => (
  <PageLayout>
    <SidebarMenu />
    <PageContent>
      <Outlet />
    </PageContent>
  </PageLayout>
)

export default Page
