import styled from 'styled-components'

import { getButtonPadding, getButtonSizes, getButtonStyles } from './utils'
import { ButtonProps } from './types'

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  background: transparent;
  background-clip: padding-box;
  outline: none;
  user-select: none;
  transition: all 100ms linear;
  border: 1px solid transparent;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};

  ${getButtonPadding};
  ${getButtonSizes};
  ${getButtonStyles};

  svg path {
    transition: all 100ms linear;
  }

  .text.counter {
    opacity: 0.5;
  }
`

Button.defaultProps = {
  variant: 'default',
  styleType: 'primary',
  size: 'sm',
  fullWidth: false
}

export default Button
