import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { getConfigByPathname } from '@/hooks/useCardsHelper'

import { BADGE_SIZE, BADGE_SIZE_STYLES, getStyles } from './utils'
import { BadgeProps, BadgeVariant } from './types'

const BadgeSC = styled.div.attrs(
  ({ className, size = 'xs', styleType = 'dark', variant = 'default', background }: BadgeProps) => ({
    className: ['badge', className, styleType, variant, size, background ? 'background' : ''].join(' ')
  })
)<BadgeProps>`
  display: flex;
  align-items: center;
  ${({ small = false }) => small && 'justify-content: center;'}
  ${({ size = 'xs' }) => BADGE_SIZE_STYLES[size]}
  ${({ size = 'xs', small = false }) => {
    const badgeSize = BADGE_SIZE[size]
    return small ? `width: ${badgeSize}px; height: ${badgeSize}px;` : `height: ${badgeSize}px;`
  }}
  ${getStyles}
`

const BadgeComponent: React.FC<BadgeProps> = ({ children, ...props }) => {
  const { pathname } = useLocation()
  const { part } = useMemo(() => getConfigByPathname(pathname), [pathname])
  const variant = useMemo(() => {
    if (!props.background && !props.variant) {
      return part.variant as BadgeVariant
    }
    if (props.variant) {
      return props.variant
    }
    return undefined
  }, [part.variant, props.background, props.variant])
  return (
    <BadgeSC {...props} variant={variant}>
      {children}
    </BadgeSC>
  )
}

export const Badge = React.memo(BadgeComponent)
