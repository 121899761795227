import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'
import { Theme } from '@/constants/styles/theme'
import { getColorsStyles } from '@/helpers/styles'

import { DEFAULT_STYLE_TYPE_COLORS } from '@/constants/styles/button/default'
import { GRAY_STYLE_TYPE_COLORS } from '@/constants/styles/button/gray'
import { DARK_GRAY_STYLE_TYPE_COLORS } from '@/constants/styles/button/darkGrey'
import { VIOLET_STYLE_TYPE_COLORS } from '@/constants/styles/button/violet'
import { MOSS_STYLE_TYPE_COLORS } from '@/constants/styles/button/moss'
import { CYAN_STYLE_TYPE_COLORS } from '@/constants/styles/button/cyan'

import { ButtonProps, StylesConfig, Variant } from './types'

const BUTTON_STYLES: Record<Variant, StylesConfig> = {
    default: DEFAULT_STYLE_TYPE_COLORS,
    gray: GRAY_STYLE_TYPE_COLORS,
    'dark-gray': DARK_GRAY_STYLE_TYPE_COLORS,
    violet: VIOLET_STYLE_TYPE_COLORS,
    moss: MOSS_STYLE_TYPE_COLORS,
    cyan: CYAN_STYLE_TYPE_COLORS
}

const BUTTON_PADDING_BY_SIZE = {
    '2xs': 6,
    xs: 8,
    sm: 10,
    md: 16,
    lg: 16
}
const ICON_BUTTON_PADDING_BY_SIZE = {
    '2xs': 4,
    xs: 4,
    sm: 4,
    md: 4,
    lg: 6
}

export const getButtonPadding = ({ styleType, size = 'sm', icon }: ButtonProps) => {
    if (styleType === 'link') {
        return 'padding: 0px;'
    }

    if (icon === 'icon' || icon === 'both') {
        return `padding: 0 ${ICON_BUTTON_PADDING_BY_SIZE[size]}px;`
    }
    if (icon === 'left') {
        return `padding: 0 ${BUTTON_PADDING_BY_SIZE[size]}px 0 ${ICON_BUTTON_PADDING_BY_SIZE[size]}px;`
    }
    if (icon === 'right') {
        return `padding: 0 ${ICON_BUTTON_PADDING_BY_SIZE[size]}px 0 ${BUTTON_PADDING_BY_SIZE[size]}px;`
    }

    return `padding: 0 ${BUTTON_PADDING_BY_SIZE[size]}px;`
}

export const getButtonSizes = ({ size }: ButtonProps) => {
    switch (size) {
        case '2xs': {
            return `
                height: 20px;
                border-radius: 4px;
                gap: 2px;
                svg {
                    width: 12px;
                    height: 12px;
                }
                .text.counter {
                    margin-left: 2px;
                }
                ${TEXT_SIZE_STYLES['2xs']};
                ${TEXT_WEIGHT_STYLES.medium};
            `
        }
        case 'xs': {
            return `
                height: 24px;
                border-radius: 6px;
                gap: 2px;
                svg {
                    width: 16px;
                    height: 16px;
                }
                .text.counter {
                    margin-left: 2px;
                }
                ${TEXT_SIZE_STYLES.xs};
                ${TEXT_WEIGHT_STYLES.medium};
            `
        }
        case 'sm': {
            return `
                height: 28px;
                border-radius: 6px;
                gap: 2px;
                svg {
                    width: 20px;
                    height: 20px;
                }
                .text.counter {
                    margin-left: 2px;
                }
                ${TEXT_SIZE_STYLES.sm};
                ${TEXT_WEIGHT_STYLES.medium};
            `
        }
        case 'md': {
            return `
                height: 38px;
                border-radius: 6px;
                gap: 4px;
                svg {
                    width: 20px;
                    height: 20px;
                }
                .text.counter {
                    margin-left: 4px;
                }
                ${TEXT_SIZE_STYLES.md};
                ${TEXT_WEIGHT_STYLES.medium};
            `
        }
        case 'lg': {
            return `
                height: 44px;
                border-radius: 6px;
                gap: 4px;
                svg {
                    width: 16px;
                    height: 16px;
                }
                .text.counter {
                    margin-left: 4px;
                }
                ${TEXT_SIZE_STYLES.md};
                ${TEXT_WEIGHT_STYLES.medium};
            `
        }
        default:
            return ''
    }
}

export const getButtonStyles = ({
    theme,
    variant = 'default',
    styleType = 'primary'
}: ButtonProps & { theme: Theme }) => getColorsStyles({ theme, config: BUTTON_STYLES[variant][styleType] })
