import React, { useCallback } from 'react'
import { IItemProps } from 'react-movable'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import { ButtonProps } from '@/components/controls/Button/types'
import ChevronDown from '@/components/icons/menu/ChevronDown'
import Close from '@/components/icons/cardHeader/Close'
import Expand from '@/components/icons/common/Expand'
import LayoutRight from '@/components/icons/common/LayoutRight'

import { FieldState } from '@/helpers/model/form/fields'
import { TitleConfig } from '@/helpers/model/title'
import { Badge } from '../Badge'
import { BadgeProps } from '../Badge/types'

import { Controls, SearchInput, SelectOptionContainer, SelectValueContainer } from './styles'

export type SelectValueType = 'badge' | 'icon-text' | 'text'
export type SelectTextValue = { name: string }
export type SelectIconTextValue = { name: string; icon: React.ReactNode }
export type SelectBadgeValue = { name: string; color?: string; state?: string }
export type SelectSearch = { query: string; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }
export type SelectValueProps = {
  type?: 'value' | 'option'
  valueType?: SelectValueType
  titleConfig?: TitleConfig
  dndProps?: IItemProps & { isDragged?: boolean }

  active?: boolean
  disabled?: boolean
  controlsOnHover?: boolean

  value?: SelectBadgeValue | SelectTextValue
  state?: FieldState
  search?: SelectSearch

  onClick?: () => void
  onOpen?: (expanded: boolean) => void
  onClear?: () => void
}

const DEFAULT_BADGE_PROPS = { styleType: 'light' } as Partial<BadgeProps>

const CONTROL_BUTTON_PROPS = {
  size: 'xs',
  icon: 'icon',
  styleType: 'tertiary',
  variant: 'gray'
} as Partial<ButtonProps>

const SelectValue = React.forwardRef<HTMLDivElement, SelectValueProps>(
  (
    {
      type,
      valueType,
      titleConfig,
      dndProps,

      active,
      disabled,
      controlsOnHover,

      value,
      state: stateConfig,
      search,

      onClick,
      onOpen,
      onClear
    },
    ref
  ) => {
    const handleClear = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        onClear && onClear()
      },
      [onClear]
    )
    const handleOpen = useCallback(
      (expanded = false) =>
        () =>
          onOpen && onOpen(expanded),
      [onOpen]
    )

    const renderIconText = useCallback(
      (option: SelectIconTextValue) => (
        <>
          {option.icon}{' '}
          <Text size='sm' className='text-name'>
            {option.name}
          </Text>
        </>
      ),
      []
    )
    const renderText = useCallback(
      (option: SelectTextValue) => {
        const title = titleConfig ? titleConfig.getValue((option as any)[titleConfig.key]) : option.name
        return <Text size='sm'>{title}</Text>
      },
      [titleConfig]
    )
    const renderBadges = useCallback(
      (option: SelectBadgeValue) => {
        const title = titleConfig ? titleConfig.getValue((option as any)[titleConfig.key]) : option.name
        return (
          <>
            <Badge size='sm' {...(option.color ? { background: option.color } : DEFAULT_BADGE_PROPS)}>
              <Text size='sm'>{title}</Text>
            </Badge>
            {option.state && (
              <Badge
                size='sm'
                className='state-badge'
                {...(stateConfig && stateConfig[option.state].color
                  ? { background: stateConfig[option.state].color }
                  : DEFAULT_BADGE_PROPS)}
              >
                <Text size='sm'>{option.state}</Text>
              </Badge>
            )}
          </>
        )
      },
      [stateConfig, titleConfig]
    )

    const renderContent = useCallback(() => {
      if (!value) {
        return null
      }
      if (valueType === 'badge') {
        return renderBadges(value as SelectBadgeValue)
      }
      if (valueType === 'icon-text') {
        return renderIconText(value as SelectIconTextValue)
      }
      if (valueType === 'text') {
        return renderText(value as SelectTextValue)
      }
      return null
    }, [value, valueType, renderBadges, renderIconText, renderText])

    if (type === 'value') {
      const hasControls = onClick || onClear || onOpen
      const chevronClass = ['chevrone-down', 'rotate180', active ? 'opened' : 'closed'].join(' ')
      return (
        <SelectValueContainer
          {...(dndProps || {})}
          ref={ref}
          dragable={!!dndProps}
          controlsOnHover={controlsOnHover}
          valueType={valueType}
          active={active}
          disabled={disabled}
          onClick={onClick}
        >
          {renderContent()}
          {!disabled && hasControls && (
            <Controls>
              {onOpen && (
                <>
                  <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleOpen(true)}>
                    <Expand />
                  </Button>
                  <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleOpen(false)}>
                    <LayoutRight />
                  </Button>
                </>
              )}
              {value && onClear && (
                <Button type='button' {...CONTROL_BUTTON_PROPS} onClick={handleClear}>
                  <Close />
                </Button>
              )}
              {onClick && <ChevronDown className={chevronClass} />}
            </Controls>
          )}
          {search && active && <SearchInput empty={!search.query} autoFocus {...search} />}
        </SelectValueContainer>
      )
    }

    return (
      <SelectOptionContainer
        {...(dndProps || {})}
        ref={ref}
        dragable={!!dndProps}
        valueType={valueType}
        active={active}
        onClick={onClick}
      >
        {renderContent()}
      </SelectOptionContainer>
    )
  }
)

SelectValue.defaultProps = {
  type: 'value',
  valueType: 'badge',
  titleConfig: undefined,
  dndProps: undefined,

  active: false,
  disabled: false,
  controlsOnHover: false,

  value: undefined,
  state: undefined,
  search: undefined,

  onClick: undefined,
  onOpen: undefined,
  onClear: undefined
}

export default SelectValue
