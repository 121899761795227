import * as React from 'react'

function LineMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
      <path
        className='stroke'
        d='M2.91675 7H11.0834'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(LineMinus)
