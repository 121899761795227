import { createReducer } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { setMenuExpandedAction, setMenuCollapsedAction } from '../../actions/common/menu'

export type MenuState = {
    collapsedPaths: string[]
    expanded: boolean
}

const initialState = {
    expanded: true,
    collapsedPaths: []
} as MenuState

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setMenuExpandedAction, (state, { payload }) => {
        state.expanded = payload
    })
    builder.addCase(setMenuCollapsedAction, (state, { payload }) => {
        const isCollapsed = state.collapsedPaths.includes(payload)
        const collapsedPaths = isCollapsed
            ? state.collapsedPaths.filter((key) => key !== payload)
            : [...state.collapsedPaths, payload]
        state.collapsedPaths = collapsedPaths
    })
})

const persistConfig = { key: 'menu', storage }
export const menuReducer = persistReducer(persistConfig, reducer)
