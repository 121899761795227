import { StylesConfig } from '@/components/controls/Checkbox/types'
import theme from '../theme'

export const CHECKBOX_STYLE_TYPE_COLORS: StylesConfig = {
    primary: {
        original: {
            border: theme.colors.primary[500]
        },
        hover: {
            background: theme.colors.primary[100],
            border: theme.colors.primary[500]
        },
        disabled: {
            background: theme.colors.gray[100],
            border: theme.colors.gray[200]
        }
    },
    violet: {
        original: {
            border: theme.colors.violet[500]
        },
        hover: {
            background: theme.colors.violet[100],
            border: theme.colors.violet[500]
        },
        disabled: {
            background: theme.colors.gray[100],
            border: theme.colors.gray[200]
        }
    },
    moss: {
        original: {
            border: theme.colors.moss[500]
        },
        hover: {
            background: theme.colors.moss[100],
            border: theme.colors.moss[500]
        },
        disabled: {
            background: theme.colors.gray[100],
            border: theme.colors.gray[200]
        }
    }
}
