import * as React from 'react'

function LineCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={14} height={14} viewBox='0 0 14 14' fill='none' {...props}>
      <path
        className='stroke'
        d='M11.667 3.5L5.25 9.917 2.333 7'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(LineCheck)
