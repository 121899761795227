import styled from 'styled-components'

export type CardConfig = {
  width?: string
  active?: boolean
  expandable?: boolean
  large?: boolean
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.base.white};
  ${({ theme }) => theme.shadows.regular.xs};
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  div.part {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 24px 12px;

  .second {
    color: ${({ theme }) => theme.colors.gray['400']};
  }
`

export const CardPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px 16px 24px;
`
