import { useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

function useDebounce(cb: (...args: any) => void, delay: number) {
    const cbRef = useRef(cb)
    useEffect(() => {
        cbRef.current = cb
    })
    const debounceCb = useMemo(() => debounce((...args) => cbRef.current(...args), delay), [delay])
    return debounceCb
}

export default useDebounce
