import { schema } from '@/helpers/schema'
import { ModelType } from '@/client/schema'
import { FilterOption, getFilterConfigByIndex } from '../table/filterAndSort'
import { getFormFieldName } from './fields'

type IndexFieldsConfigs = Record<string, FilterOption>
export const getIndexFieldsByModelKey = (modelKey: string): IndexFieldsConfigs => {
    const model = schema.models[modelKey] as ModelType
    return Object.keys(model.fields).reduce((accumulator, selector) => {
        const field = model.fields[selector]
        if (field.type !== 'Index') {
            return accumulator
        }
        const name = getFormFieldName(field)
        return { ...accumulator, [name]: getFilterConfigByIndex(field.__ref) }
    }, {} as IndexFieldsConfigs)
}
