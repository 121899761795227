import React from 'react'
import { Navigate, To, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { HOME_URL } from '@/constants/routes'
import { selectPages } from '@/redux/selectors/common/ui'

type Props = { defaultPath: string }
export const NavigateToCard: React.FC<Props> = ({ defaultPath }) => {
  const { pathname } = useLocation()
  const pages = useSelector(selectPages)

  if (pages.length > 0 && pathname !== pages[0].path) {
    const to: To = { pathname: pages[0].path }
    if (pages.length >= 2) {
      to.hash = pages[1].path
    }
    return <Navigate replace to={to} />
  }

  return <Navigate replace to={defaultPath} />
}
