import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import MenuItem from './components/MenuItem'
import { MenuItemConfig, MenuItemVariant } from './components/MenuItem/types'

import { MenuContainer } from './styles'

export type MenuConfig = MenuItemConfig & { options: MenuItemConfig[] }
type Props = {
  variant: MenuItemVariant
  config: MenuConfig
  collapsed: boolean
  onCollapse: (path: string) => void
  onSelect: (path: string) => void
}

const Menu: React.FC<Props> = ({ config: { options = [], ...main }, variant, collapsed, onCollapse, onSelect }) => {
  const { pathname } = useLocation()

  const isCollapsable = useMemo(() => options.length > 0, [options])

  const handleToggleCollapse = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onCollapse(main.path)
    },
    [main.path, onCollapse]
  )

  return (
    <MenuContainer>
      <MenuItem
        type='primary'
        isCollapsable={isCollapsable}
        collapsed={collapsed}
        variant={variant}
        {...main}
        path={options[0].path}
        onToggle={handleToggleCollapse}
        onSelect={onSelect}
      />
      {!collapsed &&
        options?.map((option) => (
          <MenuItem
            key={option.title}
            type='secondary'
            variant={variant}
            active={pathname === option.path}
            {...option}
            onSelect={onSelect}
          />
        ))}
    </MenuContainer>
  )
}

export default Menu
