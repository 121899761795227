/* eslint-disable max-len */
import * as React from 'react'

const WhiteSpinner: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
    <path
      className='fill'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 3.5A6.5 6.5 0 003.5 10h-2a8.5 8.5 0 013.778-7.067l.555.831-.555-.831A8.5 8.5 0 0110 1.5v2zm5.405 10.111a6.5 6.5 0 001.095-3.61h2a8.5 8.5 0 01-8.491 8.5l-.002-2a6.5 6.5 0 005.398-2.89z'
      fill='#fff'
    />
  </svg>
)

export default React.memo(WhiteSpinner)
