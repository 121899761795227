import { schema } from '@/helpers/schema'
import { ModelType, Index } from '@/client/schema'

import { FieldType } from '@/components/icons/fieldType/FieldTypeIcon'
import { capitalizeFirstLetter } from '../../formatters'
import { FieldConfig, getFieldConfig } from '../form/fields'
import { getValueType } from '../valueType'

export type FilterOption = {
    model: string
    parameterName: string
    field: FieldConfig
    value: string
    name: string
    fieldType: FieldType
}

export const getConfigByIndex = (index: Index, type: 'sort' | 'filter') => {
    const selector = type === 'filter' ? 'hash' : 'range'
    const model = index[selector]?.$ref.split('/').pop() as string
    const field = index!.__model!.fields[model]
    const fieldConfig = getFieldConfig(field, { allowExternal: true, allowFormula: true })
    const parameterName = `${fieldConfig?.name}${fieldConfig?.type === 'async-select' ? 'Id' : ''}`

    return {
        model,
        parameterName,
        field: fieldConfig,
        value: index.__key!,
        name: capitalizeFirstLetter(model),
        fieldType: getValueType(field) as FieldType
    }
}
export const getFilterConfigByIndex = (index: Index) => getConfigByIndex(index, 'filter')
export const getSortConfigByIndex = (index: Index) => getConfigByIndex(index, 'sort')

const getFilterOptionsByModelKey = (modelKey: string): FilterOption[] => {
    const { indices } = schema.models[modelKey] as ModelType
    return Object.keys(indices!).reduce((accumulator, key) => {
        const index = indices![key]
        if (!index.hash) {
            return accumulator
        }

        return [...accumulator, getFilterConfigByIndex(index)]
    }, [] as FilterOption[])
}
const getSortOptionsByModelKey = (modelKey: string): FilterOption[] => {
    const { indices } = schema.models[modelKey] as ModelType
    return Object.keys(indices!).reduce((accumulator, key) => {
        const index = indices![key]
        if (index.hash) {
            return accumulator
        }
        return [...accumulator, getSortConfigByIndex(index)]
    }, [] as FilterOption[])
}

type FilterAndSortByModel = Record<'filter' | 'sort', FilterOption[]>
export const FILTER_AND_SORT_OPTIONS_BY_MODEL_KEY: Record<string, FilterAndSortByModel> = Object.keys(
    schema.models
).reduce((accumulator, modelKey) => {
    const model = schema.models[modelKey]
    if (model.type === 'Type' && model.indices) {
        return {
            ...accumulator,
            [modelKey]: {
                filter: getFilterOptionsByModelKey(modelKey),
                sort: getSortOptionsByModelKey(modelKey)
            }
        }
    }
    return accumulator
}, {})

export const getFilterAndSortByModel = (modelKey: string) => {
    const { filter = [], sort = [] } = FILTER_AND_SORT_OPTIONS_BY_MODEL_KEY[modelKey] || {}

    return {
        filter,
        sort,
        hasFilter: filter.length > 0,
        hasSort: sort.length > 0
    }
}

export const DEFAULT_FILTER_SORT: FilterOption[] = [{ value: 'name', name: 'Name', fieldType: 'text' } as FilterOption]
