/* eslint-disable max-len */
import React from 'react'

function RichText(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M17.5 7.708h-5m5-4.375h-15m15 8.959h-5m5 4.375h-15m1.333-3.334h4c.467 0 .7 0 .879-.09a.833.833 0 00.364-.365c.09-.178.09-.411.09-.878V8c0-.467 0-.7-.09-.878a.833.833 0 00-.364-.365c-.179-.09-.412-.09-.879-.09h-4c-.466 0-.7 0-.878.09a.833.833 0 00-.364.365C2.5 7.3 2.5 7.533 2.5 8v4c0 .467 0 .7.09.878.08.157.208.285.365.364.178.091.412.091.878.091z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(RichText)
