import theme from '@/constants/styles/theme'
import { getSvgColors } from '@/helpers/styles'
import { BadgeProps, BadgeSize, BadgeStyleType, BadgeVariant } from './types'

type VariantColors = Record<BadgeVariant, string>

export const BADGE_SIZE: Record<BadgeSize, number> = {
    '2xs': 16,
    xs: 20,
    sm: 26
}

export const BADGE_SIZE_STYLES: Record<BadgeSize, string> = {
    '2xs': `
      padding: 0px 4px;
      border-radius: 4px;
    `,
    xs: `
      gap: 2px;
      padding: 0px 4px;
      border-radius: 6px;
      svg {
        width: 12px;
        height: 12px;
      }
    `,
    sm: `
      gap: 6px;
      padding: 0px 8px;
      border-radius: 6px;
      svg {
        width: 16px;
        height: 16px;
      }
    `
}

const BADGE_DARK_VARIANT_STYLES: VariantColors = {
    default: theme.colors.primary['500'],
    red: theme.colors.error['500'],
    green: theme.colors.success['500'],
    orange: theme.colors.warning['500'],
    violet: theme.colors.violet['500'],
    cyan: theme.colors.cyan['500'],
    moss: theme.colors.moss['500'],
    gray: theme.colors.gray['500']
}
const BADGE_LIGHT_VARIANT_STYLES: VariantColors = {
    default: theme.colors.primary['100'],
    red: theme.colors.error['100'],
    green: theme.colors.success['100'],
    orange: theme.colors.warning['100'],
    violet: theme.colors.violet['100'],
    cyan: theme.colors.cyan['100'],
    moss: theme.colors.moss['100'],
    gray: theme.colors.gray['100']
}

const BADGE_STYLE_TYPE_STYLES: Record<BadgeStyleType, VariantColors> = {
    dark: BADGE_DARK_VARIANT_STYLES,
    light: BADGE_LIGHT_VARIANT_STYLES
}

export const getStyles = ({ styleType = 'dark', variant = 'default', background }: BadgeProps) => {
    if (background) {
        const color = theme.colors.gray[700]
        return `
          color: ${color};
          background: ${background};
          ${getSvgColors(color)}
        `
    }
    const backgroundDefault = BADGE_STYLE_TYPE_STYLES[styleType][variant]
    const color = styleType === 'dark' ? theme.colors.base.white : theme.colors.gray[700]

    return `
      color: ${color};
      background: ${backgroundDefault};
      ${getSvgColors(color)}
    `
}
