import React, { useCallback, useMemo, useState } from 'react'
import { List, arrayMove } from 'react-movable'

import SelectValue from '@/components/blocks/SelectValue'
import Plus from '@/components/icons/cardHeader/Plus'
import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import { FieldState } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import Button from '../Button'
import AsyncSelect, { AsyncSelectValue } from '../AsyncSelect'

import { ControlsRow, Values } from './styles'

export type RelationSelectProps = LargeFieldBlockProps & {
  modelKey?: string
  disabled?: boolean
  state?: FieldState
  value?: AsyncSelectValue[]

  onOpen?: (id: string, expanded: boolean) => void
  onChange?: (values: AsyncSelectValue[]) => void
}
export const DEFAULT_VISIBLE_COUNT = 10

const RelationSelect: React.FC<RelationSelectProps> = ({
  modelKey,
  disabled,
  state: stateConfig,
  value,

  onOpen,
  onChange,

  ...fieldBlockProps
}) => {
  const [showAll, setShowAll] = useState(false)
  const [addMode, setAddMode] = useState(false)

  const titleConfig = useMemo(() => TITLE_GETTER_BY_MODEL_KEY[modelKey!], [modelKey])
  const hasManyValues = useMemo(() => value && value.length > DEFAULT_VISIBLE_COUNT, [value])
  const excludedOptions = useMemo(() => (value ? value.map(({ id }) => id) : undefined), [value])
  const visibleValues = useMemo(
    () => (value && hasManyValues && !showAll ? value.slice(0, DEFAULT_VISIBLE_COUNT) : value) || [],
    [hasManyValues, showAll, value]
  )

  const handleLink = useCallback(() => {
    setAddMode(true)
  }, [])
  const handleCloseSelect = useCallback(() => {
    setAddMode(false)
  }, [])
  const handleSelect = useCallback(
    (newValue: AsyncSelectValue) => {
      onChange && onChange([...(value || []), newValue])
      setAddMode(false)
    },
    [value, onChange]
  )
  const handleDelete = useCallback(
    (deleteValue: AsyncSelectValue) => () => {
      const newValue = (value || []).filter(({ id }) => id !== deleteValue.id)
      onChange && onChange(newValue)
    },
    [value, onChange]
  )
  const handleOpen = useCallback((id: string) => (expanded: boolean) => onOpen && onOpen(id, expanded), [onOpen])

  const handleShowAll = useCallback(() => setShowAll(true), [])

  return (
    <LargeFieldBlock {...fieldBlockProps}>
      {value && (
        <List<AsyncSelectValue>
          lockVertically
          values={visibleValues}
          onChange={({ oldIndex, newIndex }) => onChange && onChange(arrayMove(value, oldIndex, newIndex))}
          renderList={({ children, props }) => <Values {...props}>{children}</Values>}
          renderItem={({ value: option, isDragged, props: { ref, ...props } }) => (
            <SelectValue
              key={option.id}
              ref={ref}
              dndProps={{ ...props, isDragged }}
              titleConfig={titleConfig}
              controlsOnHover
              value={option}
              state={stateConfig}
              disabled={disabled}
              onOpen={handleOpen(option.id)}
              onClear={handleDelete(option)}
            />
          )}
        />
      )}
      {addMode ? (
        <AsyncSelect
          requestOnMount
          modelKey={modelKey}
          excludedOptions={excludedOptions}
          onSelect={handleSelect}
          onClose={handleCloseSelect}
        />
      ) : (
        <ControlsRow>
          <Button
            type='button'
            size='sm'
            icon='left'
            styleType='tertiary'
            variant='gray'
            disabled={disabled}
            onClick={handleLink}
          >
            <Plus />
            Link
          </Button>
          {hasManyValues && (
            <Button type='button' size='sm' styleType='tertiary' variant='gray' onClick={handleShowAll}>
              Show all {value!.length}
            </Button>
          )}
        </ControlsRow>
      )}
    </LargeFieldBlock>
  )
}

RelationSelect.defaultProps = {
  modelKey: undefined,
  value: [],
  disabled: false,
  state: undefined,

  onOpen: undefined,
  onChange: undefined
}

export default RelationSelect
