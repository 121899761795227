import * as yup from 'yup'

import { FieldNumberLike, FieldStringLike } from '@/client/schema'
import { FieldConfig } from '@/helpers/model/form/fields'
import {
    createFieldValidation,
    CreateValidation,
    getNumberValidation,
    getStringValidation
} from '@/helpers/model/form/validation'

import { ModalField } from './types'

export const mapModalFields = (fields: ModalField[]): FieldConfig[] => {
    const disabled = { edit: false, all: false }
    return fields.map((field: any) => {
        if (field.type === 'Enum') {
            const options = field.members.map((item: string) => ({ value: item, name: item }))
            return { name: field.id, disabled, type: 'select', options }
        }
        if (field.type === 'String' || field.type === 'Int') {
            return { name: field.id, disabled, type: 'input' }
        }
        return undefined
    })
}
export const getModalInitialValues = (fields: ModalField[]) =>
    fields.reduce((accumulator, field) => {
        if (field.type === 'Enum') {
            return { ...accumulator, [field.id]: field.members[0] }
        }
        return accumulator
    }, {})

const getModalEnumValidation = (field: ModalField) => {
    const validators: CreateValidation[] = [{ name: 'string' }]
    if (!field.optional) {
        validators.push({ name: 'required', params: [`Required. Select value`] })
    } else {
        validators.push({ name: 'nullable' })
    }

    return createFieldValidation(validators)
}

export const getModalFormValidation = (fields: ModalField[]) => {
    const fieldsValidation = fields.reduce((accumulator, field) => {
        if (field.type === 'Enum') {
            return { ...accumulator, [field.id]: getModalEnumValidation(field) }
        }
        if (field.type === 'String') {
            return { ...accumulator, [field.id]: getStringValidation({ ...field, __key: field.id } as FieldStringLike) }
        }
        if (field.type === 'Int') {
            return { ...accumulator, [field.id]: getNumberValidation({ ...field, __key: field.id } as FieldNumberLike) }
        }
        return accumulator
    }, {})
    return yup.object().shape(fieldsValidation)
}
