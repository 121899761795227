/* eslint-disable max-len */
import * as React from 'react'

function Users(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M13.333 2.89a3.75 3.75 0 010 6.72M15 13.972c1.26.57 2.394 1.499 3.333 2.695m-16.666 0c1.622-2.065 3.824-3.334 6.25-3.334 2.425 0 4.627 1.27 6.25 3.334m-2.5-10.417a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z'
        stroke='#fff'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Users)
