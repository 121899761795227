/* eslint-disable max-len */
import * as React from 'react'

function Table(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
      <path
        className='stroke'
        d='M2 6h12M6 2v12M5.2 2h5.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C14 3.52 14 4.08 14 5.2v5.6c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C12.48 14 11.92 14 10.8 14H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C2 12.48 2 11.92 2 10.8V5.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C3.52 2 4.08 2 5.2 2z'
        stroke='#98A2B3'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Table)
