/* eslint-disable max-len */
import React from 'react'

function Phone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M6.984 7.378c.58 1.208 1.37 2.34 2.372 3.341a12.167 12.167 0 003.341 2.372c.104.05.156.075.222.094.233.068.52.02.718-.122.056-.04.103-.088.199-.183.29-.291.437-.437.583-.532a1.667 1.667 0 011.817 0c.146.095.292.24.584.532l.162.162c.443.443.664.665.785.902.239.473.239 1.032 0 1.505-.12.238-.342.46-.785.902l-.131.131c-.442.442-.662.663-.962.831-.333.187-.85.322-1.232.32-.344 0-.58-.067-1.05-.2a15.866 15.866 0 01-6.903-4.062 15.865 15.865 0 01-4.061-6.903c-.134-.47-.2-.706-.202-1.05a2.814 2.814 0 01.32-1.232c.17-.3.39-.52.831-.962l.132-.131c.443-.443.664-.664.902-.785a1.667 1.667 0 011.504 0c.238.12.46.342.902.785l.163.162c.291.292.437.437.532.584.36.552.36 1.264 0 1.817-.095.146-.24.292-.532.583a1.682 1.682 0 00-.183.199.872.872 0 00-.122.718c.019.066.044.118.094.222z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Phone)
