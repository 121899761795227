import React, { useCallback, useState } from 'react'

import { Text } from '@/components/typography/Text'

import { OptionContainer, OptionsDelimeter } from './styles'

export type DropdownMenuOptionType = 'default' | 'delete' | undefined
export type DropdownMenuItemOptions = { title: string; value: string }
export type DropdownMenuOption = {
  title: string
  type?: DropdownMenuOptionType
  options?: DropdownMenuItemOptions[]
  onClick?: (value?: string) => void
}

const DropdownMenuOption: React.FC<DropdownMenuOption> = ({ title, type, options, onClick }) => {
  const [confirmation, setConfirmation] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const handleClick = useCallback(() => {
    if (type === 'delete' && !confirmation) {
      setConfirmation(true)
      return
    }
    if (options && options.length > 0) {
      setShowOptions(true)
      return
    }

    onClick && onClick()
  }, [confirmation, options, type, onClick])

  const handleSelectOption = useCallback(
    (value: string) => () => {
      onClick && onClick(value)
    },
    [onClick]
  )

  if (showOptions) {
    return (
      <>
        {options!.map((option) => (
          <OptionContainer key={option.value} type='default' onClick={handleSelectOption(option.value)}>
            <Text size='sm'>{option.title}</Text>
          </OptionContainer>
        ))}
      </>
    )
  }
  if (confirmation) {
    return (
      <OptionContainer type={type} confirmation onClick={handleClick}>
        <Text size='sm'>Realy delete?</Text>
      </OptionContainer>
    )
  }

  return (
    <OptionContainer type={type} onClick={handleClick}>
      <Text size='sm'>{title}</Text>
    </OptionContainer>
  )
}
DropdownMenuOption.defaultProps = {
  type: 'default',
  options: undefined,
  onClick: undefined
}

export type DropdownMenuListItem = DropdownMenuOption[] | React.ReactNode
type Props = { items: DropdownMenuListItem[] }
const DropdownMenuList: React.FC<Props> = ({ items }) => {
  return (
    <>
      {items.map((options, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {Array.isArray(options)
            ? options.map((option) => <DropdownMenuOption key={option.title} {...option} />)
            : options}
          {index !== items.length - 1 && <OptionsDelimeter />}
        </React.Fragment>
      ))}
    </>
  )
}

export default DropdownMenuList
