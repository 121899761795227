import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { persistStore } from 'redux-persist'
import { createLogger } from 'redux-logger'

import reducers from './reducers'

const isDev = process.env.NODE_ENV !== 'production'
const dmProps = { serializableCheck: false }
const logger = createLogger({ collapsed: true })

export const store = configureStore({
    reducer: reducers,
    middleware: (getDM) => (isDev ? getDM(dmProps).concat(logger) : getDM(dmProps)),
    devTools: isDev
})
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type ThunkAPI = { state: RootState; dispatch: AppDispatch }
export const useAppDispatch = () => useDispatch<AppDispatch>()
