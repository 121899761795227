import styled from 'styled-components'

export const TableOuter = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: auto;
    background: ${({ theme }) => theme.colors.gray['50']};
`

export const TableSC = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    padding-bottom: 115px;
    padding-right: 115px;

    tbody tr:hover {
        .cell.default {
            display: none;
        }
        .cell.hover {
            display: flex;
            .right.default {
                display: none;
            }
            .right.row {
                display: flex;
            }
        }
    }

    th,
    td {
        min-width: 128px;
        max-width: 256px;
        height: 38px;
        vertical-align: top;
        border-right: 1px solid ${({ theme }) => theme.colors.gray['200']};
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray['200']};
        position: sticky;

        &:first-child {
            text-align: right;
            left: 0;
        }
    }

    th {
        background: ${({ theme }) => theme.colors.gray['50']};
        color: ${({ theme }) => theme.colors.gray['500']};
        border-top: 1px solid ${({ theme }) => theme.colors.gray['200']};
        top: 0;
    }
    td {
        background: ${({ theme }) => theme.colors.base.white};
        color: ${({ theme }) => theme.colors.gray['700']};
    }
`
