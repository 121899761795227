import { createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { CognitoUserSession, CognitoUserAttribute } from 'amazon-cognito-identity-js'

import { UserAttributes, UserData } from '@/types/user'
import { ThunkAPI } from '@/redux/store'
import { selectUserState } from '@/redux/selectors/common/user'

export const USER_ACTION = 'USER'
export const LOGOUT_USER_ACTION = `${USER_ACTION}/LOGOUT`

const refreshSession = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const session = await Auth.currentSession()

    const updatedSession: CognitoUserSession = await new Promise((resolve) => {
        user.refreshSession(session.getRefreshToken(), (error: any, newSession: CognitoUserSession) => {
            resolve(newSession)
        })
    })
    const attributes: UserAttributes = await new Promise((resolve, reject) => {
        user?.getUserAttributes((error: any, values: CognitoUserAttribute[]) => {
            if (error) {
                reject()
            }
            const config = values.reduce(
                (accumulator, item) => ({ ...accumulator, [item.Name]: item.Value }),
                {} as UserAttributes
            )
            resolve(config)
        })
    })

    return { user, session: updatedSession || session, attributes }
}

export const requestUserAction = createAsyncThunk<UserData, undefined, ThunkAPI>(
    USER_ACTION,
    async () => {
        const { user: authUser, session, attributes } = await refreshSession()
        const groups = session.getAccessToken().payload['cognito:groups']
        authUser.groups = groups || []

        return {
            user: authUser,
            attributes
        }
    },
    {
        condition: (_, { getState }) => {
            const state = getState()
            const { requestId } = selectUserState(state)
            if (requestId) {
                return false
            }
            return true
        }
    }
)

export const logoutUserAction = createAsyncThunk(LOGOUT_USER_ACTION, async () => {
    await Auth.signOut()
})
