import * as React from 'react'

function Check(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='6' height='6' viewBox='0 0 6 6' fill='none' {...props}>
      <circle className='fill' cx='3' cy='3' r='3' fill='white' />
    </svg>
  )
}

export default React.memo(Check)
