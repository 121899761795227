import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { getMappedValuesByModelKey } from '@/helpers/model/form/mapValues'
import {
    requestFormData,
    requestCreateEntityData,
    requestUpdateEntityData,
    requestDeleteEntityData
} from '@/helpers/requests/form'

import { ThunkAPI } from '../store'
import { requestTableDataAction } from './table'
import { selectClient } from '../selectors/common/client'
import { selectTablePagination } from '../selectors/table'

export const FORM_ACTION = 'FORM'
export const CREATE_FORM_ACTION = `${FORM_ACTION}/CREATE`
export const UPDATE_FORM_ACTION = `${FORM_ACTION}/UPDATE`
export const DELETE_FORM_ACTION = `${FORM_ACTION}/DELETE`
export const CLEAR_FORM_ACTION = `${FORM_ACTION}/CLEAR`

export type FormDataResponse = { data: any }
export type FormDataVariables = { modelKey: string; id: string }
export const requestFormDataAction = createAsyncThunk<FormDataResponse, FormDataVariables, ThunkAPI>(
    FORM_ACTION,
    async ({ modelKey, id }, { getState }) => {
        const state = getState()
        const client = selectClient(state)
        const data = await requestFormData(modelKey, client, { id })
        return { data }
    }
)

export type CreateEntityVariables = { modelKey: string; values: Record<string, any> }
export const requestCreateEntityAction = createAsyncThunk<any, CreateEntityVariables, ThunkAPI>(
    CREATE_FORM_ACTION,
    async ({ modelKey, values }, { dispatch, getState }) => {
        const state = getState()
        const client = selectClient(state)
        const { pageIndex } = selectTablePagination(modelKey)(state)
        const mappedValues = getMappedValuesByModelKey(modelKey, values)
        const data = await requestCreateEntityData(modelKey, client, { input: mappedValues })
        dispatch(requestFormDataAction({ modelKey, id: data.id }))
        dispatch(requestTableDataAction({ modelKey, pageIndex }))

        return { data }
    }
)
export const requestUpdateEntityAction = createAsyncThunk<any, CreateEntityVariables, ThunkAPI>(
    UPDATE_FORM_ACTION,
    async ({ modelKey, values }, { dispatch, getState }) => {
        const state = getState()
        const client = selectClient(state)
        const mappedValues = getMappedValuesByModelKey(modelKey, values, true)
        const data = await requestUpdateEntityData(modelKey, client, { input: mappedValues })
        dispatch(requestFormDataAction({ modelKey, id: data.id }))

        return { data }
    }
)
export type DeleteEntityVariables = { modelKey: string; id: string }
export const requestDeleteEntityAction = createAsyncThunk<any, DeleteEntityVariables, ThunkAPI>(
    DELETE_FORM_ACTION,
    async ({ modelKey, id }, { getState }) => {
        const state = getState()
        const client = selectClient(state)
        const data = await requestDeleteEntityData(modelKey, client, { input: { id } })
        return { data }
    }
)

export const clearFormAction = createAction<string>(CLEAR_FORM_ACTION)
