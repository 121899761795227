import styled from 'styled-components'
import { CheckboxProps } from './types'
import { getCheckboxSizes, getCheckboxStyles } from './utils'

type CheckboxContainerProps = Partial<CheckboxProps>
export const CheckboxContainer = styled.div.attrs(({ disabled }: CheckboxContainerProps) => ({
    className: ['checkbox', disabled ? 'disabled' : ''].join(' ')
}))<CheckboxContainerProps>`
    display: flex;
    align-items: center;

    ${({ label }) => label && 'padding: 6px 12px;'}
    ${({ field }) => field && 'padding: 14px;'}
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        transition: all 100ms linear;
        ${getCheckboxStyles}
        ${getCheckboxSizes}

        ${({ onClick }) => !onClick && 'pointer-events: none;'}
    }
    .label {
        margin-left: 8px;
        color: ${({ theme }) => theme.colors.gray['700']};
    }
    &.disabled {
        pointer-events: none;
    }
`
