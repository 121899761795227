import React, { useCallback, useState } from 'react'
import { Auth } from 'aws-amplify'
import qs from 'query-string'
import { Formik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  FieldWrapper,
  StyledForm,
  ActionButton,
  InputBlock,
  Title,
  ButtonsContainer,
  SuccessMessageWrapper
} from '@/components/layouts/LoginLayout/styles'
import LoginLayout from '@/components/layouts/LoginLayout'
import InputField from '@/components/fields/InputField'

import setNewPassword from '@/helpers/validators/forms/setNewPassword'
import { mapAmplifyMessage } from '@/helpers/mapAmplifyMessage'
import { SIGNIN_URL } from '@/constants/routes'
import { PASSWORD_FIELD, PASSWORD_CONFIRM_FIELD } from '@/constants/forms/setNewPassword'

export interface SetNewPasswordFormValues {
  [PASSWORD_FIELD]: string
  [PASSWORD_CONFIRM_FIELD]: string
}

const SetNewPasswordPage = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [isPasswordChanged, setPasswordChanged] = useState(false)

  const handleSubmit = async ({ password }: SetNewPasswordFormValues, actions: any) => {
    try {
      const { username, code } = qs.parse(search)
      await Auth.forgotPasswordSubmit(username as string, code as string, password)
      actions.setSubmitting(false)
      setPasswordChanged(true)
    } catch (e: any) {
      actions.setFieldError('password', mapAmplifyMessage(e.message))
    }
  }
  const handleBackClick = useCallback(() => {
    navigate(SIGNIN_URL)
  }, [navigate])

  const renderSuccessMessage = useCallback(
    () => (
      <SuccessMessageWrapper center>
        <p>You&apos;ve successfully changed your AirScape password.</p>
        <ButtonsContainer>
          <ActionButton type='button' size='lg' fullWidth onClick={handleBackClick}>
            Sign in
          </ActionButton>
        </ButtonsContainer>
      </SuccessMessageWrapper>
    ),
    [handleBackClick]
  )

  return (
    <LoginLayout onBack={handleBackClick}>
      <Title>{isPasswordChanged ? 'Password changed' : 'Update your password'}</Title>
      <Formik
        initialValues={{} as SetNewPasswordFormValues}
        validationSchema={setNewPassword}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(props) => {
          if (isPasswordChanged) {
            return renderSuccessMessage()
          }
          return (
            <StyledForm>
              <InputBlock>
                <FieldWrapper>
                  <InputField label='New password' type='password' key={PASSWORD_FIELD} name={PASSWORD_FIELD} />
                </FieldWrapper>
                <FieldWrapper>
                  <InputField
                    label='Confirm new password'
                    type='password'
                    key={PASSWORD_CONFIRM_FIELD}
                    name={PASSWORD_CONFIRM_FIELD}
                  />
                </FieldWrapper>
              </InputBlock>
              <ButtonsContainer>
                <ActionButton type='submit' size='lg' fullWidth disabled={!props.dirty && props.isValid}>
                  Update Password
                </ActionButton>
              </ButtonsContainer>
            </StyledForm>
          )
        }}
      </Formik>
    </LoginLayout>
  )
}

export default SetNewPasswordPage
