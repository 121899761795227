import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SIGNIN_URL } from '@/constants/routes'
import { UserGroup } from '@/types/user'

import { useAppDispatch } from '@/redux/store'
import { logoutUserAction, requestUserAction } from '@/redux/actions/common/user'
import { selectUserState } from '@/redux/selectors/common/user'

import useUrlParameters from './useUrlParameters'

export const useCognitoUser = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { pathname } = useUrlParameters()

    const { user, attributes, loading, called } = useSelector(selectUserState)

    const hasWriteAccess = useMemo(() => {
        if (!user) {
            return false
        }
        return user.groups.includes(UserGroup.ADMIN) || user.groups.includes(UserGroup.USER)
    }, [user])

    const fetchUser = useCallback(() => dispatch(requestUserAction()), [dispatch])
    const logout = useCallback(async () => {
        await dispatch(logoutUserAction()).unwrap()
        navigate(SIGNIN_URL)
    }, [dispatch, navigate])

    useEffect(() => {
        if (user) {
            return
        }
        fetchUser()
    }, [pathname, fetchUser, user])
    useEffect(() => {
        if (!user && called && !loading) {
            logout()
        }
    }, [called, loading, user, logout])

    return {
        user,
        attributes,
        hasWriteAccess,
        loading,
        called,
        logout,
        refreshUser: fetchUser
    }
}
