import { Field, Type } from '@/client/schema'

const CORE_TYPE_TO_COLUMN_TYPE: Record<string, string> = {
    boolean: 'checkbox',
    string: 'text'
}
const TYPE_TO_COLUMN_TYPE: Record<string, string> = {
    URL: 'url',
    Date: 'date',
    Email: 'email',
    String: 'text',
    Boolean: 'checkbox'
}

const getCoreType = (coreType?: string) => {
    if (coreType && CORE_TYPE_TO_COLUMN_TYPE[coreType]) {
        return CORE_TYPE_TO_COLUMN_TYPE[coreType]
    }
    return coreType
}

const getListType = (type: Type) => {
    if (type.__kind === 'Scalar') {
        return getCoreType(type.__coreType)
    }
    return 'relation-to'
}

export const getValueType = (field: Field) => {
    if (field.type === 'Ref' || field.type === 'Index') {
        if (field.__key === 'state') {
            return 'workflow'
        }
        return 'relation-to'
    }
    if (TYPE_TO_COLUMN_TYPE[field.type]) {
        return TYPE_TO_COLUMN_TYPE[field.type]
    }
    if (field.type === 'List' || field.type === 'Set') {
        return getListType(field.of)
    }
    if (field.__kind === 'Scalar') {
        return getCoreType(field.__coreType)
    }

    if (field.type === 'Formula') {
        return TYPE_TO_COLUMN_TYPE[field.of.type]
    }

    return field.type
}
