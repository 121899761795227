import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import { RichTextProps } from '@/components/controls/RichText/types'
import { Placeholder } from '@/components/blocks/Placeholder'

const RichText = React.lazy(() => import(/* webpackChunkName: 'rich-text' */ '@/components/controls/RichText'))

interface RichTextFieldProps extends RichTextProps {
  name: string
}

const RichTextField: React.FC<RichTextFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<string>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (value: string) => {
        form.setFieldValue(field.name, value)
      }

      return (
        <React.Suspense fallback={<Placeholder height='200px' />}>
          <RichText {...field} {...props} error={error} disabled={isSubmitting || disabled} onChange={onChange} />
        </React.Suspense>
      )
    }}
  </Field>
)

export default RichTextField
