import React, { useCallback, useMemo } from 'react'

import { Badge } from '@/components/blocks/Badge'
import Tooltip from '@/components/blocks/Tooltip'
import Checkbox from '@/components/controls/Checkbox'
import Button from '@/components/controls/Button'
import { Text } from '@/components/typography/Text'
import FieldTypeIcon from '@/components/icons/fieldType/FieldTypeIcon'
import Expand from '@/components/icons/common/Expand'
import LayoutRight from '@/components/icons/common/LayoutRight'

import { BadgeVariant } from '@/components/blocks/Badge/types'
import { ModelMenuConfig } from '@/helpers/model/menu'

import { TableColumn } from '../..'
import { CellSC, Content } from './styles'

type THFirstProps = {
  modelMenuConfig: ModelMenuConfig
  selected: boolean
  selectable?: boolean
  onSelect: () => void
}
const THFirst: React.FC<THFirstProps> = ({ modelMenuConfig, selectable, selected, onSelect }) => (
  <CellSC.THFirst>
    <Content.THFirst className={['cell', selectable ? 'default' : ''].join(' ')}>
      {selected ? (
        <Checkbox size='md' selected={selected} onToggle={onSelect} />
      ) : (
        <Badge small size='xs' variant={modelMenuConfig.variant as BadgeVariant}>
          <Text size='xs'>{modelMenuConfig.title[0].toUpperCase()}</Text>
        </Badge>
      )}
    </Content.THFirst>
    {selectable && (
      <Content.THFirst className='cell hover'>
        <Checkbox size='md' selected={selected} onToggle={onSelect} />
      </Content.THFirst>
    )}
  </CellSC.THFirst>
)
THFirst.defaultProps = { selectable: false }

type THProps = { column: TableColumn }
const TH: React.FC<THProps> = ({ column: { key, type } }) => (
  <CellSC.TH>
    <Content.TH>
      <FieldTypeIcon type={type} />
      <Text size='sm'>{key}</Text>
    </Content.TH>
  </CellSC.TH>
)

type TDFirstProps = {
  index: number
  selected: boolean
  selectable?: boolean
  onOpen: (expanded?: boolean) => void
  onSelect: () => void
}
const TDFirst: React.FC<TDFirstProps> = ({ index, selected, selectable, onOpen, onSelect }) => {
  const checkbox = useMemo(() => <Checkbox size='md' selected={selected} onToggle={onSelect} />, [selected, onSelect])
  const text = useMemo(() => <Text size='xs'>{index}</Text>, [index])
  const defaultContent = useMemo(
    () => (selectable && selected ? checkbox : text),
    [selectable, selected, checkbox, text]
  )

  const handleOpen = useCallback(
    (expanded = false) =>
      () =>
        onOpen(expanded),
    [onOpen]
  )
  return (
    <CellSC.TDFirst>
      <Content.TDFirst className='cell default'>{defaultContent}</Content.TDFirst>
      <Content.TDFirst className='cell hover'>
        <Tooltip position='bottom' arrowPosition='left' description='Expand'>
          <Button size='xs' icon='icon' styleType='secondary' variant='gray' onClick={handleOpen(true)}>
            <Expand />
          </Button>
        </Tooltip>
        <Tooltip position='bottom' arrowPosition='left' description='Open a new panel on the right'>
          <Button size='xs' icon='icon' styleType='secondary' variant='gray' onClick={handleOpen(false)}>
            <LayoutRight />
          </Button>
        </Tooltip>
        {selectable && <div className='right default'>{checkbox}</div>}
        <div className={['right', selectable ? 'row' : ''].join(' ')}>{defaultContent}</div>
      </Content.TDFirst>
    </CellSC.TDFirst>
  )
}
TDFirst.defaultProps = { selectable: false }

type TDProps = { children: React.ReactNode }
const TD: React.FC<TDProps> = ({ children }) => (
  <CellSC.TD>
    <Content.TD>{children}</Content.TD>
  </CellSC.TD>
)

export default {
  THFirst,
  TH,
  TDFirst,
  TD
}
