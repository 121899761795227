import React, { useCallback, useMemo } from 'react'

import DropdownMenuList, { DropdownMenuOption } from '@/components/blocks/DropdownMenuList'
import { useCardsHelper } from '@/hooks/useCardsHelper'

import { PageConfig } from '@/redux/actions/common/ui'

type Props = { page: PageConfig; onCloseMenu: () => void }
const TableDropdownMenu: React.FC<Props> = ({ page, onCloseMenu }) => {
  const { onCloseOther } = useCardsHelper()

  const handleCloseOther = useCallback(() => {
    onCloseOther(page.path, 'table')
    onCloseMenu()
  }, [page.path, onCloseOther, onCloseMenu])

  const items: DropdownMenuOption[][] = useMemo(
    () => [
      // [{ title: 'Export table' }, { title: 'Copy link' }],
      [{ title: 'Close other panels', onClick: handleCloseOther }]
    ],
    [handleCloseOther]
  )
  return <DropdownMenuList items={items} />
}

export default TableDropdownMenu
