import { StylesConfig } from '@/components/controls/Menu/components/MenuItem/types'
import theme from '../theme'

const config = {
    original: {
        background: theme.colors.gray[100],
        color: theme.colors.base.black
    },
    hover: { background: theme.colors.gray[200] },
    disabled: {}
}

export const MENU_STYLE_TYPE_COLORS: StylesConfig = {
    primary: config,
    violet: config,
    moss: config,
    cyan: config
}
