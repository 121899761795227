import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { AsyncSelectValue } from '@/components/controls/AsyncSelect'
import { requestTableData } from '@/helpers/requests/table'
import { requestDeleteEntityData } from '@/helpers/requests/form'

import { ThunkAPI } from '../store'
import { clearFormAction } from './form'
import { selectClient } from '../selectors/common/client'
import { selectFilterByModel, selectSelectedRows, selectSortByModel, selectTablePagination } from '../selectors/table'

export const TABLE_ACTION = 'TABLE'
export const SELECT_ROW_TABLE_ACTION = `${TABLE_ACTION}/SELECT_ROW`
export const SET_COLUMNS_ACTION = `${TABLE_ACTION}/SET_COLUMNS`
export const SET_FILTER_ACTION = `${TABLE_ACTION}/SET_FILTER`
export const SET_SORT_ACTION = `${TABLE_ACTION}/SET_SORT`
export const ROWS_DELETE_TABLE_ACTION = `${TABLE_ACTION}/ROWS_DELETE`
export const CLEAR_TABLE_ACTION = `${TABLE_ACTION}/CLEAR`

export const setSelectedRowTableAction = createAction<{ modelKey: string; ids: string | string[] }>(
    SELECT_ROW_TABLE_ACTION
)
export type VisibleColumns = Record<string, boolean>
export const setTableColumnsAction = createAction<{ modelKey: string; columns: VisibleColumns }>(SET_COLUMNS_ACTION)
export type FilterData = { key: string; parameter: string; value: AsyncSelectValue | string }
export type FilterConfig = { modelKey: string; data?: FilterData }
export const setFilterTableAction = createAction<FilterConfig>(SET_FILTER_ACTION)
export type SortData = { key: string; parameter: string; value: 'ASC' | 'DESC' }
export type SortConfig = { modelKey: string; data?: SortData }
export const setSortTableAction = createAction<SortConfig>(SET_SORT_ACTION)
export const clearTableAction = createAction<string>(CLEAR_TABLE_ACTION)

export type TableDataResponse = { modelKey: string; items: any[]; nextToken: string | null; pageIndex: number }
export type TableDataVariables = { modelKey: string; pageIndex: number }

export const requestTableDataAction = createAsyncThunk<TableDataResponse, TableDataVariables, ThunkAPI>(
    TABLE_ACTION,
    async ({ modelKey, pageIndex }, { getState }) => {
        const state = getState()
        const client = selectClient(state)
        const filter = selectFilterByModel(modelKey)(state)
        const sort = selectSortByModel(modelKey)(state)
        const { nextTokens } = selectTablePagination(modelKey)(state)
        const defaultVariables = { limit: 100, nextToken: nextTokens[pageIndex] }
        const sortVariables = sort && sort.value ? { sortDirection: sort.value } : {}
        const filterVariables =
            filter && filter.value
                ? { [filter.parameter]: typeof filter.value === 'object' ? filter.value.id : filter.value }
                : {}
        const variables = { ...defaultVariables, ...filterVariables, ...sortVariables }
        const { items, nextToken } = await requestTableData(modelKey, client, variables, filter?.key || sort?.key)
        return { modelKey, items, nextToken, pageIndex }
    }
)

export type DeleteRowsVariables = { modelKey: string }
export const requestDeleteRowsAction = createAsyncThunk<null, DeleteRowsVariables, ThunkAPI>(
    ROWS_DELETE_TABLE_ACTION,
    async ({ modelKey }, { dispatch, getState }) => {
        const state = getState()
        const client = selectClient(state)
        const ids = selectSelectedRows(modelKey)(state)
        const { pageIndex } = selectTablePagination(modelKey)(state)

        await Promise.all(ids.map((id) => requestDeleteEntityData(modelKey, client, { input: { id } })))
        ids.forEach((id) => dispatch(clearFormAction(id)))
        dispatch(setSelectedRowTableAction({ modelKey, ids: [] }))
        dispatch(requestTableDataAction({ modelKey, pageIndex }))

        return null
    }
)
