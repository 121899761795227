import React from 'react'
import noop from 'lodash/noop'

import { Circle, ToggleContainer } from './styles'

type Props = {
  active?: boolean
  disabled?: boolean
  onToggle?: () => void
}

const Toggle: React.FC<Props> = ({ onToggle, ...props }) => {
  return (
    <ToggleContainer {...props} onClick={onToggle}>
      <Circle />
    </ToggleContainer>
  )
}

Toggle.defaultProps = {
  active: false,
  disabled: false,
  onToggle: noop
}

export default Toggle
