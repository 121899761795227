import { Theme } from '@/constants/styles/theme'
import { StyleStates, VariantConfig } from '@/types/styles'

type Props = {
    theme: Theme
    config: StyleStates
}

export const getSvgColors = (color: string, selector = '') => `
    svg${selector}:not(.immutable) path {
        &.fill { fill: ${color}; }
        &.stroke { stroke: ${color}; }
    }
`

const getStyleProperties = ({ background, border, color }: VariantConfig) => `
    ${background ? `background-color: ${background};` : ''}
    ${border ? `border-color: ${border};` : ''}
    ${
        color
            ? `
                color: ${color};
                ${getSvgColors(color)}
            `
            : ''
    }
`

export const getColorsStyles = ({ config }: Props) => {
    const { original, hover, disabled } = config

    return `
        ${getStyleProperties(original)}

        &:hover:not([disabled]) {
            cursor: pointer;
            ${getStyleProperties(hover)}
        }
        
        &:disabled, &.disabled {
            cursor: not-allowed;
            ${getStyleProperties(disabled)}
        }
    `
}
