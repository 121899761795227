import { useEffect, useCallback, useRef, useState } from 'react'
import noop from 'lodash/noop'

type Callbacks = {
    onOpen?: () => void
    onClose?: () => void
}
function useOutsideClickHandler<ContainerType extends HTMLElement, ControlType extends HTMLElement>({
    onOpen,
    onClose
}: Callbacks = {}) {
    const containerRef = useRef<ContainerType>()
    const controlRef = useRef<ControlType>()

    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = useCallback(() => {
        setIsOpen(true)
        onOpen && onOpen()
    }, [onOpen])
    const handleClose = useCallback(() => {
        setIsOpen(false)
        onClose && onClose()
    }, [onClose])
    const handleToggle = useCallback(() => {
        isOpen ? handleClose() : handleOpen()
    }, [isOpen, handleOpen, handleClose])

    const customHandleClickOutside = useCallback(
        (event: Event) => {
            const isNotContent =
                containerRef.current &&
                containerRef.current.contains &&
                !containerRef.current?.contains(event.target as Node)
            const isNotControl = controlRef.current
                ? controlRef.current.contains && !controlRef.current?.contains(event.target as Node)
                : true
            if (isNotContent && isNotControl) {
                handleClose()
            }
        },
        [handleClose, containerRef, controlRef]
    )

    const element = document.getElementById('root')
    useEffect(() => {
        if (element && isOpen) {
            element.addEventListener('mousedown', customHandleClickOutside)
            return () => {
                element.removeEventListener('mousedown', customHandleClickOutside)
            }
        }
        return noop
    }, [handleClose, customHandleClickOutside, element, isOpen, containerRef])

    return {
        containerRef: containerRef as React.RefObject<ContainerType>,
        controlRef: controlRef as React.RefObject<ControlType>,
        isOpen,
        onOpen: handleOpen,
        onToggle: handleToggle,
        onClose: handleClose
    }
}

export default useOutsideClickHandler
