/* eslint-disable max-len */
import * as React from 'react'

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='fill'
        d='M10.0002 11.229L5.97915 15.25C5.81248 15.4167 5.61115 15.4967 5.37515 15.49C5.13915 15.4827 4.93782 15.3957 4.77115 15.229C4.60448 15.0623 4.52115 14.8577 4.52115 14.615C4.52115 14.3717 4.60448 14.1667 4.77115 14L8.77115 10L4.75015 5.979C4.58348 5.81233 4.50348 5.60767 4.51015 5.365C4.51748 5.12167 4.60448 4.91667 4.77115 4.75C4.93782 4.58333 5.14248 4.5 5.38515 4.5C5.62848 4.5 5.83348 4.58333 6.00015 4.75L10.0002 8.771L14.0211 4.75C14.1878 4.58333 14.3925 4.5 14.6351 4.5C14.8785 4.5 15.0835 4.58333 15.2501 4.75C15.4168 4.91667 15.5001 5.12167 15.5001 5.365C15.5001 5.60767 15.4168 5.81233 15.2501 5.979L11.2292 10L15.2501 14.021C15.4168 14.1877 15.5001 14.389 15.5001 14.625C15.5001 14.861 15.4168 15.0623 15.2501 15.229C15.0835 15.3957 14.8785 15.479 14.6351 15.479C14.3925 15.479 14.1878 15.3957 14.0211 15.229L10.0002 11.229Z'
        fill='#667085'
      />
    </svg>
  )
}

export default React.memo(Close)
