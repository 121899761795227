export const REGULAR = {
    xs: 'box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);',
    sm: 'box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);',
    md: 'box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);',
    lg: 'box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);',
    xl: 'box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);',
    '2xl': 'box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);',
    '3xl': 'box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);'
}
export const FLUFFY = {
    xs: 'box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);',
    sm: 'box-shadow: 0px 6.25px 20px rgba(0, 0, 0, 0.125);',
    md: 'box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.11);',
    lg: 'box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.12);',
    xl: 'box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.12);',
    '2xl': 'box-shadow: 0px 17.5px 70px rgba(0, 0, 0, 0.12);',
    '3xl': 'box-shadow: 0px 35px 90px rgba(0, 0, 0, 0.135);'
}
export const TIGHT = {
    xs: 'box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);',
    sm: 'box-shadow: 0px 1.25px 4px rgba(0, 0, 0, 0.25);',
    md: 'box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);',
    lg: 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.24);',
    xl: 'box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);',
    '2xl': 'box-shadow: 0px 3.5px 14px rgba(0, 0, 0, 0.2);',
    '3xl': 'box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.17);',
    '4xl': 'box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.2);',
    '5xl': 'box-shadow: 0px 13px 36px rgba(0, 0, 0, 0.22);'
}
