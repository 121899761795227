import styled from 'styled-components'
import { MenuItemProps } from './types'
import { getMenuItemStyles } from './utils'

type SCMenuItemProps = Partial<MenuItemProps>
export const MenuItemIcon = styled.div.attrs(({ type = 'primary', active = false }: SCMenuItemProps) => ({
    className: ['menu-item-icon', type, active ? 'active' : null].join(' ')
}))<SCMenuItemProps>`
    position: relative;
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        ${({ type }) => (type === 'secondary' ? 'width: 16px; height: 16px;' : 'width: 24px; height: 24px;')}
    }

    .collapse-button {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        transition: all 100ms linear;
        svg {
            transition: all 100ms linear;
            transform: ${({ collapsed = false }) => (collapsed ? 'rotate(-90deg)' : 'rotate(0deg)')};
        }
    }
`

export const MenuItemContainer = styled.nav<SCMenuItemProps>`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 12px;
    gap: 8px;
    transition: all 100ms linear;

    ${({ type = 'primary' }) => type === 'secondary' && 'padding-left: 42px;'}
    ${getMenuItemStyles}
`
