import React from 'react'

import InputField from '@/components/fields/InputField'
import DatetimeField from '@/components/fields/DatetimeField'
import CheckboxField from '@/components/fields/CheckboxField'
import SelectField from '@/components/fields/SelectField'
import AsyncSelectField from '@/components/fields/AsyncSelectField'

import { FieldConfig } from '@/helpers/model/form/fields'

type Props = {
  createMode?: boolean
  hasWriteAccess?: boolean
  title?: string
  config: FieldConfig
}
const SmallFields: React.FC<Props> = ({ createMode, hasWriteAccess, title, config }) => {
  if (!config || config.name === 'id') {
    return null
  }
  const label = title || config.name.toUpperCase()
  const disabled = !hasWriteAccess || config.disabled?.all || (config.disabled?.edit && !createMode)
  if (config.type === 'input') {
    return <InputField name={config.name} label={label} disabled={disabled} placeholder='Enter text...' />
  }
  if (config.type === 'timestamp') {
    return (
      <DatetimeField time name={config.name} label={label} disabled={disabled} placeholder='Select date and time...' />
    )
  }
  if (config.type === 'checkbox') {
    return <CheckboxField name={config.name} label={label} disabled={disabled} />
  }
  if (config.type === 'select') {
    return <SelectField name={config.name} label={label} disabled={disabled} options={config.options!} />
  }
  if (config.type === 'async-select') {
    return (
      <AsyncSelectField
        name={config.name}
        modelKey={config.modelKey}
        label={label}
        disabled={disabled}
        state={config.state}
      />
    )
  }
  return null
}

SmallFields.defaultProps = {
  createMode: undefined,
  hasWriteAccess: undefined,
  title: undefined
}

export default SmallFields
