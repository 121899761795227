import styled from 'styled-components'

export const OptionContainer = styled.div<{ type?: 'default' | 'delete'; confirmation?: boolean }>`
    cursor: pointer;
    padding: 8px 12px;
    color: ${({ theme }) => theme.colors.gray['700']};
    transition: all 100ms linear;

    &:hover {
        background: ${({ theme }) => theme.colors.gray['100']};
    }

    ${({ theme, type = 'default' }) =>
        type === 'delete' &&
        `
        .text {
            color: ${theme.colors.error['500']};
        }
    `}
    ${({ theme, confirmation }) =>
        confirmation &&
        `
        background: ${theme.colors.error['500']};
        .text {
            color: ${theme.colors.base.white};
        }
        &:hover {
            background: ${theme.colors.error['600']};
        }
    `}
`

export const OptionsDelimeter = styled.div`
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background: ${({ theme }) => theme.colors.gray['100']};
`
