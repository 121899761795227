import styled from 'styled-components'

import { COLLAPSED_CARD_WIDTH } from '@/hooks/useCardsHelper'

import { Card } from '../Card'

export const StyledCard = styled(Card)<{ offsets: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: ${COLLAPSED_CARD_WIDTH}px;
    width: ${COLLAPSED_CARD_WIDTH}px;
    padding: 8px 0;

    & > svg {
        width: 20px;
        height: 20px;
        ${({ offsets }) => offsets && 'margin-top: 20px;'}
    }
    .text {
        margin-top: 8px;
        writing-mode: tb-rl;
    }
`
