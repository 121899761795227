import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { useAlert } from 'react-alert'

import { Card, CardConfig, CardTitle } from '@/components/regions/Card'
import CollapsedCard from '@/components/regions/CollapsedCard'
import { Display } from '@/components/typography/Display'
import { TextPlaceholder } from '@/components/blocks/TextPlaceholder'
import Spinner from '@/components/controls/Spinner'

import { FORM_INITIAL_VALUES_BY_MODEL_KEY } from '@/helpers/model/form/initialValues'
import { FORM_VALIDATION_SCHEMA_BY_MODEL_KEY } from '@/helpers/model/form/validation'
import { FieldConfig, FORM_FIELDS_BY_MODEL_KEY } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import { FilterOption } from '@/helpers/model/table/filterAndSort'
import { getPathByModelKey } from '@/helpers/model/menu'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useMountRequest } from '@/hooks/useMountRequest'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { setFilterTableAction } from '@/redux/actions/table'
import { requestFormDataAction, requestCreateEntityAction, requestUpdateEntityAction } from '@/redux/actions/form'
import { selectForm } from '@/redux/selectors/form'

import FormCardHeader from './components/FormCardHeader'
import SmallFields from './components/SmallFields'
import LargeFields from './components/LargeFields'
import SearchCard from '../SearchCard'
import { StyledForm, FormWrapper, Fields, LargeFieldsColumn, SmallFieldsColumns } from './styles'

export type FormCardProps = { page: PageConfig; card: CardConfig }
const FormCard: React.FC<FormCardProps> = ({ page, card }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm, onOpenIndex, onClose } = useCardsHelper()

  const [activeSearch, setActiveSearch] = useState(false)

  const { modelKey, id, path, model } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const { data, loading, called } = useSelector(selectForm(id!))
  const createMode = useMemo(() => id === 'new', [id])
  const initialValues = useMemo(() => FORM_INITIAL_VALUES_BY_MODEL_KEY[modelKey], [modelKey])
  const validationSchema = useMemo(() => FORM_VALIDATION_SCHEMA_BY_MODEL_KEY[modelKey], [modelKey])
  const { large, small } = useMemo(() => FORM_FIELDS_BY_MODEL_KEY[modelKey], [modelKey])
  const hasLargeFields = useMemo(() => large.length > 0, [large])
  const pageTitle = useMemo(() => {
    if (createMode) {
      return 'Create'
    }
    if (loading || !called || data === null) {
      return <TextPlaceholder />
    }
    const { key, getValue } = TITLE_GETTER_BY_MODEL_KEY[modelKey]
    return getValue(data[key])
  }, [modelKey, createMode, data, loading, called])

  useMountRequest(() => {
    if (!called && !loading && !createMode) {
      dispatch(requestFormDataAction({ modelKey, id: id! }))
    }
  })

  useEffect(() => {
    if (called && !loading && data === null) {
      onClose(page.path)
      alert.error(`${model.title} not found`)
    }
  }, [called, loading, data, model, page.path, alert, onClose])

  const handleSubmitForm = useCallback(
    async (values: any, actions: FormikHelpers<any>) => {
      try {
        if (createMode) {
          const { data: createData } = await dispatch(requestCreateEntityAction({ modelKey, values })).unwrap()
          onClose(page.path)
          onOpenForm(`${path.model}/${createData.id}`)
        } else {
          await dispatch(requestUpdateEntityAction({ modelKey, values })).unwrap()
        }
        actions.setSubmitting(false)
        alert.show('Saved')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [modelKey, path, page.path, createMode, alert, dispatch, onClose, onOpenForm]
  )
  const handleOpenSearch = useCallback(() => setActiveSearch(true), [])
  const handleCloseSearch = useCallback(() => setActiveSearch(false), [])

  const handleOpenForm = useCallback(
    (formModelKey: string, formId: string, expanded: boolean) => {
      const modelPath = getPathByModelKey(formModelKey)
      onOpenForm(`${modelPath}/${formId}`, expanded, page.path)
    },
    [page.path, onOpenForm]
  )
  const handleOpenIndexTable = useCallback(
    (indexModelKey: string, filter: FilterOption) => {
      dispatch(
        setFilterTableAction({
          modelKey: indexModelKey,
          data: { key: filter.value, parameter: filter.parameterName, value: data }
        })
      )
      onOpenIndex(`${path.part}/${indexModelKey}`, page.path)
    },
    [path.part, page, data, dispatch, onOpenIndex]
  )

  const renderLargeField = useCallback(
    (config: FieldConfig) => (
      <LargeFields
        key={config?.name}
        modelKey={modelKey}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
        onOpenIndexTable={handleOpenIndexTable}
      />
    ),
    [modelKey, hasWriteAccess, createMode, handleOpenForm, handleOpenIndexTable]
  )
  const renderSmallField = useCallback(
    (config: FieldConfig) => (
      <SmallFields key={config?.name} hasWriteAccess={hasWriteAccess} createMode={createMode} config={config} />
    ),
    [hasWriteAccess, createMode]
  )

  if (!card.active) {
    return <CollapsedCard title={pageTitle} page={page} />
  }

  if (activeSearch) {
    return <SearchCard page={page} onClose={handleCloseSearch} />
  }

  return (
    <Card style={{ width: card.width }}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={(data || initialValues) as any}
        onSubmit={handleSubmitForm}
      >
        {({ dirty, isValid, isSubmitting, handleSubmit }) => (
          <StyledForm>
            <FormCardHeader
              page={page}
              card={card}
              disableActions={createMode || isSubmitting}
              disabledSubmit={!dirty || !isValid || isSubmitting}
              onSubmit={handleSubmit}
              onOpenSearch={handleOpenSearch}
            />
            <FormWrapper>
              <CardTitle>
                <Display size='sm'>{pageTitle}</Display>
              </CardTitle>
              {!createMode && (!called || loading) ? (
                <Spinner />
              ) : (
                <Fields hasLargeFields={hasLargeFields}>
                  {hasLargeFields && <LargeFieldsColumn>{large.map(renderLargeField)}</LargeFieldsColumn>}
                  <SmallFieldsColumns>{small.map(renderSmallField)}</SmallFieldsColumns>
                </Fields>
              )}
            </FormWrapper>
          </StyledForm>
        )}
      </Formik>
    </Card>
  )
}

export default FormCard
