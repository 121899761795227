import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import Select, { SelectProps } from '@/components/controls/Select'

interface SelectFieldProps extends SelectProps {
  name: string
}

const SelectField: React.FC<SelectFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<string | undefined>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (value: string) => {
        form.setFieldValue(field.name, value)
      }
      const onClear = () => {
        form.setFieldValue(field.name, undefined)
      }

      return (
        <Select
          {...field}
          {...props}
          error={error}
          disabled={isSubmitting || disabled}
          onSelect={onChange}
          onClear={onClear}
        />
      )
    }}
  </Field>
)

export default SelectField
