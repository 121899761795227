/* eslint-disable max-len */
import React from 'react'

function LookupFrom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M16.667 8.75V5.667c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 00-1.092-1.093c-.535-.272-1.235-.272-2.635-.272H7.333c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.092 1.093c-.273.534-.273 1.235-.273 2.635v8.666c0 1.4 0 2.1.273 2.635a2.5 2.5 0 001.092 1.093c.535.272 1.235.272 2.635.272h2.25m8.75 0l-1.25-1.25M17.917 15a2.917 2.917 0 11-5.834 0 2.917 2.917 0 015.834 0z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(LookupFrom)
