import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import Menu from '@/components/controls/Menu'
import Account from '@/components/blocks/Account'
// import MenuItem from '@/components/controls/Menu/components/MenuItem'
// import Notification from '@/components/icons/menu/Notification'

// import { NOTIFICATIONS_URL } from '@/constants/routes'
import { MENU_CONFIG } from '@/helpers/model/menu'
import { useCardsHelper } from '@/hooks/useCardsHelper'

import { useAppDispatch } from '@/redux/store'
import { setMenuCollapsedAction } from '@/redux/actions/common/menu'
import { selectMenuState } from '@/redux/selectors/common/menu'

import { MenuBlock, SidebarMenuContainer } from './styles'

const SidebarMenu: React.FC = () => {
  const dispatch = useAppDispatch()
  const { onOpenTable } = useCardsHelper()
  const { collapsedPaths } = useSelector(selectMenuState)

  const handleToggleMenu = useCallback(
    (path: string) => {
      dispatch(setMenuCollapsedAction(path))
    },
    [dispatch]
  )

  return (
    <SidebarMenuContainer>
      <MenuBlock>
        <Account />
        {/* <MenuItem
          type='system'
          icon={<Notification />}
          title='Notifications'
          path={NOTIFICATIONS_URL}
          onSelect={onOpenTable}
        /> */}
      </MenuBlock>
      <MenuBlock>
        {MENU_CONFIG.map(({ variant, config }) => (
          <Menu
            key={variant}
            variant={variant}
            config={config}
            collapsed={collapsedPaths.includes(config.path)}
            onCollapse={handleToggleMenu}
            onSelect={onOpenTable}
          />
        ))}
      </MenuBlock>
    </SidebarMenuContainer>
  )
}

export default SidebarMenu
