import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link'
import { Auth } from 'aws-amplify'
import { ApolloLink, InMemoryCache, ApolloClient, HttpLink } from '@apollo/client'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import config from '@/helpers/config'

const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => Auth.currentCredentials()
}

const authLink = createAuthLink({
    url: config.REACT_APP_SUBSCRIPTION_GRAPHQL_URL,
    region: config.REACT_APP_GRAPHQL_REGION,
    auth
})

const httpLink = new HttpLink({ uri: config.REACT_APP_SUBSCRIPTION_GRAPHQL_URL })

const link = ApolloLink.from([
    authLink,
    createSubscriptionHandshakeLink(
        {
            url: config.REACT_APP_SUBSCRIPTION_GRAPHQL_URL,
            region: config.REACT_APP_GRAPHQL_REGION,
            auth
        },
        httpLink
    )
])

export const iamClient = new ApolloClient({
    link,
    cache: new InMemoryCache({ addTypename: false }),
    ssrForceFetchDelay: 100
})
