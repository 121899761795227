import React, { useCallback, useState } from 'react'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import Dropdown from '@/components/controls/Dropdown'
import MoreDots from '@/components/icons/cardHeader/MoreDots'

import { ImageCardContainer, ImageError, ImageSC } from '../../styles'
import ImageMenu from '../ImageMenu'

const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ alt, ...props }) => {
  const [errorLevel, setErrorLevel] = useState(0)
  const handleImageError = useCallback(() => setErrorLevel(1), [])
  const handleReloadImageError = useCallback(() => setErrorLevel(2), [])

  if (errorLevel === 2) {
    return (
      <ImageError>
        <Text size='xs'>Image loading error</Text>
      </ImageError>
    )
  }

  if (errorLevel === 1) {
    return <ImageSC {...props} loading='lazy' alt={alt} onError={handleReloadImageError} />
  }

  return <ImageSC {...props} loading='lazy' alt={alt} onError={handleImageError} />
}

type Props = {
  disabled?: boolean
  onDownload: () => void
  onDelete: () => void
} & React.ImgHTMLAttributes<HTMLImageElement>
const ImageCard: React.FC<Props> = ({ disabled, onDownload, onDelete, ...props }) => (
  <ImageCardContainer>
    <Image {...props} />
    <Dropdown
      className='delete-button'
      popupPosition='left'
      control={
        <Button type='button' size='sm' icon='icon' variant='gray' disabled={disabled}>
          <MoreDots />
        </Button>
      }
      popup={(onCloseMenu) => (
        <ImageMenu disabled={disabled} onDownload={onDownload} onDelete={onDelete} onCloseMenu={onCloseMenu} />
      )}
    />
  </ImageCardContainer>
)

ImageCard.defaultProps = {
  disabled: false
}

export default ImageCard
