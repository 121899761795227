import styled from 'styled-components'

export const Circle = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.base.white};
    transition: all 100ms linear;
`

export const ToggleContainer = styled.div<{ active?: boolean; disabled?: boolean }>`
    cursor: pointer;
    position: relative;
    width: 28px;
    height: 16px;
    background: ${({ theme }) => theme.colors.primary['200']};
    border-radius: 8px;
    transition: all 100ms linear;

    &:hover {
        background: ${({ theme }) => theme.colors.primary['200']};
    }

    ${({ theme, active }) => {
        if (active) {
            return `
          background: ${theme.colors.primary['500']};
          &:hover {
            background: ${theme.colors.primary['500']};
          }
          ${Circle} {
            left: 14px;
          }
        `
        }
        return ''
    }}
    ${({ theme, disabled, active }) =>
        disabled &&
        `
        pointer-events: none;
        background: ${active ? theme.colors.gray['500'] : theme.colors.gray['200']};
    `}
`
