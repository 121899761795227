import { createReducer } from '@reduxjs/toolkit'

import {
    PageConfig,
    setOpenPageAction,
    setExpandPageAction,
    resetPagesAction,
    ModalAction,
    setModalAction
} from '../../actions/common/ui'

export type UIState = {
    pages: PageConfig[]
    modal: ModalAction
}

const initialState = {
    pages: [],
    modal: undefined
} as UIState

export const uiReducer = createReducer(initialState, (builder) => {
    builder.addCase(setOpenPageAction, (state, { payload: { type, path, expanded, afterPage } }) => {
        const page = { type, path, expanded }
        if (state.pages.length === 0) {
            state.pages = [page]
        } else {
            const table = state.pages.find(({ type: pageType }) => pageType === 'table')
            const pages = state.pages.filter(
                ({ path: pagePath, type: pageType }) => pagePath !== path && pageType !== 'table'
            )
            if (afterPage) {
                const activePageIndex = pages.findIndex(({ path: pagePath }) => pagePath === afterPage)
                state.pages = [
                    ...(table ? [table] : []),
                    ...pages.slice(0, activePageIndex + 1),
                    page,
                    ...pages.slice(activePageIndex + 1)
                ]
            } else {
                state.pages = [...(table ? [table] : []), page, ...pages]
            }
        }
    })
    builder.addCase(setExpandPageAction, (state, { payload: { path, expanded } }) => {
        state.pages = state.pages.map((page) => {
            if (page.path === path) {
                return { ...page, expanded }
            }
            return page
        })
    })
    builder.addCase(resetPagesAction, (state, { payload }) => {
        state.pages = payload
    })
    builder.addCase(setModalAction, (state, { payload }) => {
        state.modal = payload
    })
})
