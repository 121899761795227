/* eslint-disable max-len */
import * as React from 'react'

function Edit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M9.16699 3.33332H5.66699C4.26686 3.33332 3.5668 3.33332 3.03202 3.6058C2.56161 3.84549 2.17916 4.22794 1.93948 4.69834C1.66699 5.23312 1.66699 5.93319 1.66699 7.33332V14.3333C1.66699 15.7335 1.66699 16.4335 1.93948 16.9683C2.17916 17.4387 2.56161 17.8212 3.03202 18.0608C3.5668 18.3333 4.26686 18.3333 5.66699 18.3333H12.667C14.0671 18.3333 14.7672 18.3333 15.302 18.0608C15.7724 17.8212 16.1548 17.4387 16.3945 16.9683C16.667 16.4335 16.667 15.7335 16.667 14.3333V10.8333M6.66697 13.3333H8.06242C8.47007 13.3333 8.6739 13.3333 8.86571 13.2873C9.03577 13.2464 9.19835 13.1791 9.34747 13.0877C9.51566 12.9847 9.65979 12.8405 9.94804 12.5523L17.917 4.58332C18.6073 3.89296 18.6073 2.77368 17.917 2.08332C17.2266 1.39296 16.1073 1.39296 15.417 2.08332L7.44802 10.0523C7.15977 10.3405 7.01564 10.4847 6.91257 10.6528C6.82119 10.802 6.75385 10.9645 6.71302 11.1346C6.66697 11.3264 6.66697 11.5302 6.66697 11.9379V13.3333Z'
        stroke='#98A2B3'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Edit)
