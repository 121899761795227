import React, { useCallback } from 'react'

import { Text } from '@/components/typography/Text'
import ChevronDown from '@/components/icons/menu/ChevronDown'

import { MenuItemContainer, MenuItemIcon } from './styles'
import { MenuItemProps } from './types'

const MenuItem: React.FC<MenuItemProps> = ({ icon, title, path, onToggle, onSelect, ...props }) => {
  const handleOpenPage = useCallback(() => onSelect(path), [path, onSelect])

  return (
    <MenuItemContainer {...props} onClick={handleOpenPage}>
      <MenuItemIcon {...props}>
        <div className='icon'>{icon}</div>
        {props.isCollapsable && (
          <div className='collapse-button' onClick={onToggle}>
            <ChevronDown />
          </div>
        )}
      </MenuItemIcon>
      <Text size='sm'>{title}</Text>
    </MenuItemContainer>
  )
}

export default MenuItem
