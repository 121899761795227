import React, { useCallback, useMemo } from 'react'

import { CardPagination } from '@/components/regions/Card'
import { PaginationProps, usePagination } from '@/hooks/usePagination'

import { PaginationContainer, PaginationButton } from './styles'

type Props = PaginationProps & { headerOffsets?: boolean }
const Pagination: React.FC<Props> = ({ headerOffsets, ...props }) => {
  const { pages, pageIndex, onePage, prevDisabled, nextDisabled, onPrev, onNext, onSelect } = usePagination(props)
  const Wrapper = useMemo(() => (headerOffsets ? CardPagination : React.Fragment), [headerOffsets])

  const handleSelect = useCallback((index: number) => () => onSelect(index), [onSelect])

  if (onePage) {
    return null
  }
  return (
    <Wrapper>
      <PaginationContainer>
        <PaginationButton disabled={prevDisabled} onClick={onPrev}>
          Previous
        </PaginationButton>
        {pages.map(({ title, index, key }) => (
          <PaginationButton
            key={`${title}-${key}`}
            active={index === pageIndex}
            onClick={typeof index === 'number' ? handleSelect(index) : undefined}
          >
            {title}
          </PaginationButton>
        ))}
        <PaginationButton disabled={nextDisabled} onClick={onNext}>
          Next
        </PaginationButton>
      </PaginationContainer>
    </Wrapper>
  )
}

Pagination.defaultProps = {
  headerOffsets: false
}

export default React.memo(Pagination)
