import { hexToRgb } from '@/helpers/hexToRgb'
import styled from 'styled-components'

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => hexToRgb(theme.colors.gray['700'], 0.5)};
`

export const ModalContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.base.white};
    border-radius: 8px;

    .close-button {
        position: absolute;
        top: 14px;
        right: -32px;
    }
`
