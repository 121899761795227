import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import IndexView, { IndexViewProps, IndexViewValue } from '@/components/controls/IndexView'

interface IndexViewFieldProps extends IndexViewProps {
  name: string
}

const IndexViewField: React.FC<IndexViewFieldProps> = (props) => (
  <Field {...props}>
    {({ field, form }: FieldProps<IndexViewValue>) => {
      const error = objectPath.get(form.errors, field.name)
      return <IndexView {...field} {...props} error={error} />
    }}
  </Field>
)

export default IndexViewField
