import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CardHeader } from '@/components/regions/Card'
import Button from '@/components/controls/Button'
import { Variant } from '@/components/controls/Button/types'
import Dropdown from '@/components/controls/Dropdown'
import ColumnsSettings from '@/components/controls/Table/components/ColumnsSettings'
import { Badge } from '@/components/blocks/Badge'
import { BadgeVariant } from '@/components/blocks/Badge/types'
import Tooltip from '@/components/blocks/Tooltip'
import { Text } from '@/components/typography/Text'

import Search from '@/components/icons/cardHeader/Search'
import Columns from '@/components/icons/cardHeader/Columns'
import Filter from '@/components/icons/cardHeader/Filter'
import Plus from '@/components/icons/cardHeader/Plus'
import MoreDots from '@/components/icons/cardHeader/MoreDots'
import Minimize from '@/components/icons/cardHeader/Minimize'
import Maximize from '@/components/icons/cardHeader/Maximize'
import Actions from '@/components/icons/cardHeader/Actions'
import Close from '@/components/icons/cardHeader/Close'

import { TABLE_COLUMNS_BY_MODEL_KEY } from '@/helpers/model/table/columns'
import { getFilterAndSortByModel } from '@/helpers/model/table/filterAndSort'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { setTableColumnsAction, VisibleColumns } from '@/redux/actions/table'
import {
  selectColumnsByModel,
  selectFilterByModel,
  selectSelectedRows,
  selectSortByModel
} from '@/redux/selectors/table'

import TableActions from '../Actions'
import TableDropdownMenu from '../DropdownMenu'
import FilterAndSort from '../FilterAndSort'
import { TableCardProps } from '../..'

type Props = TableCardProps & {
  onOpenSearch?: () => void
  onRequestData?: (pageIndex?: number) => void
}
const TableCardHeader: React.FC<Props> = ({ page, card: { expandable, large }, onOpenSearch, onRequestData }) => {
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm, onExpand, onClose } = useCardsHelper()

  const { modelKey, part, path } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const columns = useMemo(() => TABLE_COLUMNS_BY_MODEL_KEY[modelKey], [modelKey])

  const visibleColumns = useSelector(selectColumnsByModel(modelKey))
  const filter = useSelector(selectFilterByModel(modelKey))
  const sort = useSelector(selectSortByModel(modelKey))
  const selectedRows = useSelector(selectSelectedRows(modelKey))

  const hasFilterAndSort = useMemo(() => {
    const { hasFilter, hasSort } = getFilterAndSortByModel(modelKey)
    return hasFilter || hasSort
  }, [modelKey])
  const filterActive = useMemo(() => !!filter || !!sort, [filter, sort])
  const columnsVisibilityChanged = useMemo(
    () => Object.keys(visibleColumns).some((key) => !visibleColumns[key]),
    [visibleColumns]
  )

  const handleToggleColumn = useCallback(
    (selector: string, value: boolean) => {
      const columnsValues = { ...visibleColumns }
      columnsValues[selector] = value
      dispatch(setTableColumnsAction({ modelKey, columns: columnsValues }))
    },
    [dispatch, modelKey, visibleColumns]
  )

  const handleCreateEntity = useCallback(() => onOpenForm(`${path.model}/new`), [path, onOpenForm])
  const handleClose = useCallback(() => onClose(page.path), [page.path, onClose])

  useEffect(() => {
    if (!visibleColumns || Object.keys(visibleColumns).length === 0) {
      const columnsValues = columns.reduce((accumulator, { key }) => {
        if (key !== 'id') {
          return { ...accumulator, [key]: true }
        }
        return accumulator
      }, {} as VisibleColumns)
      dispatch(setTableColumnsAction({ modelKey, columns: columnsValues }))
    }
  }, [dispatch, columns, visibleColumns, modelKey])

  return (
    <CardHeader>
      <div className='part'>
        <Tooltip position='bottom' description='Search'>
          <Button
            icon='icon'
            size='sm'
            styleType='tertiary'
            variant='gray'
            disabled={!onOpenSearch}
            onClick={onOpenSearch}
          >
            <Search />
          </Button>
        </Tooltip>
        <Badge size='xs' variant={part.variant as BadgeVariant}>
          {part.icon}
          <Text size='xs'>{part.title}</Text>
        </Badge>
      </div>
      <div className='part'>
        {selectedRows.length > 0 && (
          <Dropdown
            control={
              <Button size='sm' variant='gray'>
                {large ? 'Actions' : <Actions />}
                <Text size='sm' className='counter'>
                  {selectedRows.length}
                </Text>
              </Button>
            }
            popup={(onCloseMenu) => <TableActions modelKey={modelKey} onCloseMenu={onCloseMenu} />}
          />
        )}
        {expandable && (
          <Tooltip position='bottom' description={page.expanded ? 'Collapse' : 'Expand'}>
            <Button
              icon='icon'
              size='sm'
              styleType='tertiary'
              variant='gray'
              onClick={onExpand(page.path, !page.expanded)}
            >
              {page.expanded ? <Minimize /> : <Maximize />}
            </Button>
          </Tooltip>
        )}
        <Dropdown
          control={
            <Tooltip position='bottom' description='Columns' hidden={large}>
              <Button
                icon={large ? 'left' : 'icon'}
                size='sm'
                styleType={columnsVisibilityChanged ? 'primary' : 'tertiary'}
                variant='gray'
              >
                <Columns />
                {large && 'Columns'}
              </Button>
            </Tooltip>
          }
          popup={<ColumnsSettings columns={columns} visibleColumns={visibleColumns} onToggle={handleToggleColumn} />}
        />
        {hasFilterAndSort && (
          <Dropdown
            control={
              <Tooltip position='bottom' description='Filter & Sort' hidden={large}>
                <Button
                  icon={large ? 'left' : 'icon'}
                  size='sm'
                  styleType={filterActive ? 'primary' : 'tertiary'}
                  variant='gray'
                >
                  <Filter />
                  {large && 'Filter & Sort'}
                </Button>
              </Tooltip>
            }
            popup={<FilterAndSort modelKey={modelKey} onRequestData={onRequestData} />}
          />
        )}
        {hasWriteAccess && (
          <Tooltip position='bottom' description='New' hidden={large}>
            <Button
              icon={large ? 'left' : undefined}
              size='sm'
              variant={part.variant as Variant}
              onClick={handleCreateEntity}
            >
              <Plus />
              {large && 'New'}
            </Button>
          </Tooltip>
        )}
        <Dropdown
          control={
            <Tooltip position='bottom' description='Menu' arrowPosition='right'>
              <Button icon='icon' size='sm' styleType='tertiary' variant='gray'>
                <MoreDots />
              </Button>
            </Tooltip>
          }
          popup={(onCloseMenu) => <TableDropdownMenu page={page} onCloseMenu={onCloseMenu} />}
        />
        {page.type === 'index' && (
          <Tooltip position='bottom' description='Close page' arrowPosition='right'>
            <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray' onClick={handleClose}>
              <Close />
            </Button>
          </Tooltip>
        )}
      </div>
    </CardHeader>
  )
}

TableCardHeader.defaultProps = {
  onOpenSearch: undefined,
  onRequestData: undefined
}

export default TableCardHeader
