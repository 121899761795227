import * as yup from 'yup'

import { EMAIL_FIELD, FULL_NAME_FIELD, PASSWORD_FIELD } from '@/constants/forms/signUp'
import { PASSWORD_ERROR_MESSAGE, PASSWORD_REGEXP } from '@/constants/values'

export default yup.object().shape({
    [FULL_NAME_FIELD]: yup.string().required('Please enter your full name.'),
    [EMAIL_FIELD]: yup
        .string()
        .email('Invalid email address. Please enter a correct email address.')
        .required('Please enter your email'),
    [PASSWORD_FIELD]: yup
        .string()
        .required('Please enter your password')
        .min(8, 'Minimum 8 characters')
        .test('password-validation', PASSWORD_ERROR_MESSAGE, (value) => PASSWORD_REGEXP.test(value))
})
