export const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const DAY_NAMES_SHORT = DAY_NAMES.map((day) => day.slice(0, 3))
export const WEEKEND_DAYS = ['Sunday', 'Saturday']

export const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const SHORT_MONTH_NAMES = MONTH_NAMES.map((name) => name.slice(0, 3))

export const PASSWORD_REGEXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)\\?\-"!@#%&\\/\\,><':;|_~`])\S{8,}$$/

export const PASSWORD_ERROR_MESSAGE =
    // eslint-disable-next-line max-len
    'Password must contain at least one uppercase character, at least one lowercase character, at least one number (0-9), and at least one special characters (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =).'
