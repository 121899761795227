import isEqual from 'lodash/isEqual'

export function getObjectDiff(obj1: Record<string, any>, obj2: Record<string, any>) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            result.push(key)
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
        }
        return result
    }, Object.keys(obj2))

    return diff
}
