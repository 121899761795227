import React from 'react'

import Tooltip from '@/components/blocks/Tooltip'
import Button from '@/components/controls/Button'
import Close from '@/components/icons/cardHeader/Close'

import { ModalContainer, ModalWrapper } from './styles'

type Props = { className?: string; onClose?: () => void; children: React.ReactNode }
const Modal: React.FC<Props> = ({ className, onClose, children }) => (
  <ModalWrapper>
    <ModalContainer className={className}>
      {children}
      {onClose && (
        <Tooltip position='bottom' arrowPosition='right' description='Close modal' className='close-button'>
          <Button type='button' icon='icon' size='xs' variant='gray' onClick={onClose}>
            <Close />
          </Button>
        </Tooltip>
      )}
    </ModalContainer>
  </ModalWrapper>
)

Modal.defaultProps = {
  className: undefined,
  onClose: undefined
}

export default Modal
