import * as React from 'react'

function Maximize(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M11.667 8.333L17.5 2.5m0 0h-5m5 0v5m-9.167 4.167L2.5 17.5m0 0h5m-5 0v-5'
        stroke='#667085'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Maximize)
