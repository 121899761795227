/* eslint-disable max-len */
import React from 'react'

function Avatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M4.43 16.199a3.334 3.334 0 013.07-2.032h5c1.38 0 2.563.837 3.07 2.032m-2.237-8.282a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0zm5 2.083a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Avatar)
