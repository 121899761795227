import React from 'react'

import { Text } from '@/components/typography/Text'

import { ModelMenuConfig } from '@/helpers/model/menu'

import { Badge } from '../Badge'
import { BadgeVariant } from '../Badge/types'
import { ChipsContainer } from './styles'

type Props = { part: ModelMenuConfig; model: ModelMenuConfig }
const ChipsSpace: React.FC<Props> = ({ part, model }) => (
  <ChipsContainer>
    <Badge size='2xs' variant={part.variant as BadgeVariant}>
      <Text size='2xs'>{part.title[0]}</Text>
    </Badge>
    <Text size='sm'>{model.title}</Text>
  </ChipsContainer>
)

export default {
  Default: ChipsContainer,
  Space: ChipsSpace
}
