import React, { useCallback, useMemo } from 'react'

import { Text } from '@/components/typography/Text'
import LineCheck from '@/components/icons/fields/LineCheck'
import Check from '@/components/icons/fields/Check'
import LineMinus from '@/components/icons/fields/LineMinus'

import { CheckboxContainer } from './styles'
import { CheckboxProps } from './types'

const ICON_BY_TYPE = {
  default: <LineCheck />,
  circle: <LineCheck />,
  radio: <Check />,
  line: <LineMinus />
}

const Checkbox: React.FC<CheckboxProps> = ({ label, onToggle, ...props }) => {
  const icon = useMemo(() => ICON_BY_TYPE[props.type || 'default'], [props.type])

  const renderLabel = useCallback(() => {
    if (typeof label === 'string') {
      return (
        <Text size='sm' className='label'>
          {label}
        </Text>
      )
    }
    return label
  }, [label])

  return (
    <CheckboxContainer {...props} onClick={onToggle}>
      <div className={['icon', props.disabled ? 'disabled' : ''].join(' ')}>{props.selected ? icon : null}</div>
      {label && renderLabel()}
    </CheckboxContainer>
  )
}

export default Checkbox
