import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Provider as AlertProvider } from 'react-alert'

import Alert, { ALERT_OPTIONS } from '@/components/blocks/Alert'
import { ApolloProviderWithClient } from '@/hocs/ApolloProviderWithClient'
import theme from '@/constants/styles/theme'

import { store, persistor } from '@/redux/store'

import App from './App'

const RootComponent = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AlertProvider template={Alert} {...ALERT_OPTIONS}>
              <ApolloProviderWithClient>
                <App />
              </ApolloProviderWithClient>
            </AlertProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

const container = document.getElementById('root') as HTMLElement

if (process.env.NODE_ENV === 'development') {
  ReactDOM.createRoot(container).render(<RootComponent />)
} else {
  ReactDOM.hydrateRoot(container, <RootComponent />)
}
