import styled from 'styled-components'

import { loadingAnimation } from '@/constants/styles/animations'

type Props = { height: string }
export const Placeholder = styled.div<Props>`
    height: ${({ height }) => height};
    width: 100%;
    background: ${({ theme }) => `linear-gradient(90deg, ${theme.colors.gray['200']}, ${theme.colors.gray['300']})`};
    background-size: 200% 200%;
    animation: ${loadingAnimation} 1s ease infinite;
    border-radius: 4px;
`
