export const MapEntries: { error: string; regexp: RegExp; msg?: string }[] = [
    { error: 'User does not exist', regexp: /user.*not.*exist/i },
    { error: 'User already exists', regexp: /user.*already.*exist/i },
    { error: 'Incorrect username or password', regexp: /incorrect.*username.*password/i },
    { error: 'Invalid password format', regexp: /validation.*password/i },
    {
        error: 'Invalid phone number format',
        regexp: /invalid.*phone/i,
        msg: 'Invalid phone number format. Please use a phone number format of +12345678900'
    }
]
export const mapAmplifyMessage = (message: string) => {
    const match = MapEntries.filter((entry) => entry.regexp.test(message))
    if (match.length === 0) {
        return message
    }
    const entry = match[0]
    const msg = entry.msg || entry.error
    return msg
}
