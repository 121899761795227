import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Spinner from '@/components/controls/Spinner'
import { SIGNIN_URL } from '@/constants/routes'

type Props = { children: React.ReactNode }
const PrivateRoute: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    Auth.currentAuthenticatedUser()
      .catch(() => navigate(SIGNIN_URL, { replace: true }))
      .finally(() => setLoading(false))
  }, [navigate])

  if (loading) {
    return <Spinner fullPage />
  }

  return <>{children}</>
}

export default PrivateRoute
