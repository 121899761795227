/* eslint-disable max-len */
import React from 'react'

function Formula(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M16.588 17.5a16.878 16.878 0 001.745-7.5c0-2.694-.628-5.24-1.745-7.5m-13.176 0A16.878 16.878 0 001.667 10c0 2.694.628 5.24 1.745 7.5M13.79 7.187h-.075c-.545 0-1.062.24-1.416.655l-4.48 5.253a1.861 1.861 0 01-1.416.655H6.33m.932-6.563h1.162c.416 0 .782.278.897.68L10.8 13.07c.114.402.48.68.896.68h1.162'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Formula)
