import config from '../helpers/config'

export const amplifyConfig = {
    Auth: {
        region: config.REACT_APP_COGNITO_REGION,
        userPoolId: config.REACT_APP_COGNITO_USER_POOL_ID,
        identityPoolId: config.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        userPoolWebClientId: config.REACT_APP_COGNITO_APP_CLIENT_ID,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-bitwise
        authenticationFlowType: 'USER_SRP_AUTH' | 'CUSTOM_AUTH',
        clientMetadata: { url: config.REACT_APP_DOMAIN_URL }
    }
}

export default amplifyConfig
