import React from 'react'

import MarkerPin from '@/components/icons/menu/MarkerPin'
import Help from '@/components/icons/menu/Help'
import Users from '@/components/icons/menu/Users'
import Table from '@/components/icons/menu/Table'
import { MenuItemConfig, MenuItemVariant } from '@/components/controls/Menu/components/MenuItem/types'
import { MenuConfig } from '@/components/controls/Menu'
import { schema } from '@/helpers/schema'
import { capitalizeFirstLetter } from '../formatters'

type MenuConfigItem = {
  variant: MenuItemVariant
  config: MenuConfig
}

const VARIANT_BY_NAME: Record<string, string> = {
  AirScape: 'violet',
  Help: 'moss',
  People: 'cyan'
}
const ICON_BY_NAME: Record<string, React.ReactNode> = {
  AirScape: <MarkerPin />,
  Help: <Help />,
  People: <Users />
}

export const getPathByModelKey = (modelKey: string) => {
  const model = schema.models[modelKey]
  if (model.type === 'Type') {
    return `/${model.ui.namespace.toLowerCase()}/${modelKey}`
  }
  return null
}

export const MENU_CONFIG: MenuConfigItem[] = Object.keys(schema.models)
  .reduce((accumulator, modelKey) => {
    const model = schema.models[modelKey]

    if (model.type === 'Type') {
      const namespacePath = `/${model.ui.namespace.toLowerCase()}`
      const typeConfig = {
        icon: <Table />,
        title: capitalizeFirstLetter(modelKey),
        path: `${namespacePath}/${modelKey}`
      }
      const currentNamespaceIndex: number = accumulator.findIndex(({ config }) => config.title === model.ui.namespace)

      if (currentNamespaceIndex === -1) {
        const namespaceConfig = {
          variant: VARIANT_BY_NAME[model.ui.namespace],
          config: {
            icon: ICON_BY_NAME[model.ui.namespace],
            title: model.ui.namespace,
            path: namespacePath,
            options: [typeConfig]
          }
        } as MenuConfigItem
        return [...accumulator, namespaceConfig]
      }
      const currentNamespace = accumulator[currentNamespaceIndex]
      const namespaceOptions = [...(currentNamespace.config.options || []), typeConfig]
      accumulator[currentNamespaceIndex] = {
        ...currentNamespace,
        config: { ...currentNamespace.config, options: namespaceOptions }
      }
      return accumulator
    }
    return accumulator
  }, [] as MenuConfigItem[])
  .sort((a, b) => (a.config.title > b.config.title ? 1 : -1))

const getFlatMenuOptions = (variant: MenuItemVariant, options: MenuItemConfig[]) =>
  options.reduce(
    (accumulator, config) => ({ ...accumulator, [config.path]: { variant, icon: config.icon, title: config.title } }),
    {}
  )

export type ModelMenuConfig = { variant: MenuItemVariant; title: string; icon: React.ReactNode }
export const MENU_CONFIG_BY_PATH: Record<string, ModelMenuConfig> = MENU_CONFIG.reduce(
  (accumulator, { variant, config }) => ({
    ...accumulator,
    [config.path]: {
      variant,
      icon: config.icon,
      title: config.title
    },
    ...(config.options ? getFlatMenuOptions(variant, config.options) : {})
  }),
  {}
)
