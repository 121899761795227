/* eslint-disable max-len */
import React from 'react'

const ShowPassword = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M2.01677 10.5943C1.90328 10.4146 1.84654 10.3247 1.81477 10.1861C1.79091 10.082 1.79091 9.91788 1.81477 9.81378C1.84654 9.67519 1.90328 9.58534 2.01677 9.40564C2.95461 7.92066 5.74617 4.16663 10.0003 4.16663C14.2545 4.16663 17.0461 7.92066 17.9839 9.40564C18.0974 9.58534 18.1541 9.67519 18.1859 9.81378C18.2098 9.91788 18.2098 10.082 18.1859 10.1861C18.1541 10.3247 18.0974 10.4146 17.9839 10.5943C17.0461 12.0793 14.2545 15.8333 10.0003 15.8333C5.74617 15.8333 2.95461 12.0793 2.01677 10.5943Z'
        stroke='black'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        className='stroke'
        d='M10.0003 12.5C11.381 12.5 12.5003 11.3807 12.5003 9.99996C12.5003 8.61925 11.381 7.49996 10.0003 7.49996C8.61962 7.49996 7.50034 8.61925 7.50034 9.99996C7.50034 11.3807 8.61962 12.5 10.0003 12.5Z'
        stroke='black'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(ShowPassword)
