import React, { useCallback } from 'react'

import Toggle from '@/components/controls/Toggle'
import { Text } from '@/components/typography/Text'

import { VisibleColumns } from '@/redux/actions/table'

import { TableColumn } from '../..'
import { ColumnRow, ColumnsContainer } from './styles'

type Props = {
  columns: TableColumn[]
  visibleColumns: VisibleColumns
  onToggle: (selector: string, value: boolean) => void
}
const ColumnsSettings: React.FC<Props> = ({ columns, visibleColumns, onToggle }) => {
  const handleToggle = useCallback(
    (selector: string) => () => {
      onToggle(selector, !visibleColumns[selector])
    },
    [visibleColumns, onToggle]
  )
  return (
    <ColumnsContainer>
      {columns.map(({ selector }) => {
        if (selector === 'id') {
          return null
        }
        return (
          <ColumnRow key={selector}>
            <Toggle active={visibleColumns[selector]} onToggle={handleToggle(selector)} />
            <Text size='sm'>{selector}</Text>
          </ColumnRow>
        )
      })}
    </ColumnsContainer>
  )
}

export default ColumnsSettings
