import React, { useCallback, useMemo } from 'react'

import { CardHeader } from '@/components/regions/Card'
import Button from '@/components/controls/Button'
import { Variant } from '@/components/controls/Button/types'
import Dropdown from '@/components/controls/Dropdown'
import { Badge } from '@/components/blocks/Badge'
import { BadgeVariant } from '@/components/blocks/Badge/types'
import Tooltip from '@/components/blocks/Tooltip'
import { Text } from '@/components/typography/Text'

import Search from '@/components/icons/cardHeader/Search'
import MoreDots from '@/components/icons/cardHeader/MoreDots'
import Close from '@/components/icons/cardHeader/Close'
import Minimize from '@/components/icons/cardHeader/Minimize'
import Maximize from '@/components/icons/cardHeader/Maximize'

import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import FormDropdownMenu from '../DropdownMenu'
import { FormCardProps } from '../..'

type Props = FormCardProps & {
  disableActions: boolean
  disabledSubmit?: boolean
  onSubmit?: () => void
  onOpenSearch?: () => void
}
const FormCardHeader: React.FC<Props> = ({
  page,
  card: { expandable },
  disableActions,
  disabledSubmit,
  onSubmit,
  onOpenSearch
}) => {
  const { hasWriteAccess } = useCognitoUser()
  const { onExpand, onClose } = useCardsHelper()

  const { model, part } = useMemo(() => getConfigByPathname(page.path), [page.path])

  const handleClose = useCallback(() => onClose(page.path), [page.path, onClose])

  return (
    <CardHeader>
      <div className='part'>
        <Tooltip position='bottom' description='Search'>
          <Button
            type='button'
            icon='icon'
            size='sm'
            styleType='tertiary'
            variant='gray'
            disabled={!onOpenSearch}
            onClick={onOpenSearch}
          >
            <Search />
          </Button>
        </Tooltip>
        <Badge size='xs' variant={model.variant as BadgeVariant}>
          {model.icon}
          <Text size='xs'>{model.title}</Text>
        </Badge>
      </div>
      <div className='part'>
        {expandable && (
          <Tooltip position='bottom' description={page.expanded ? 'Collapse' : 'Expand'}>
            <Button
              type='button'
              icon='icon'
              size='sm'
              styleType='tertiary'
              variant='gray'
              onClick={onExpand(page.path, !page.expanded)}
            >
              {page.expanded ? <Minimize /> : <Maximize />}
            </Button>
          </Tooltip>
        )}
        {hasWriteAccess && (
          <Button
            type='submit'
            size='sm'
            variant={part.variant as Variant}
            disabled={disabledSubmit}
            onClick={onSubmit}
          >
            Save
          </Button>
        )}
        <Dropdown
          control={
            <Tooltip position='bottom' description='Menu'>
              <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray'>
                <MoreDots />
              </Button>
            </Tooltip>
          }
          popup={(onCloseMenu) => (
            <FormDropdownMenu disableActions={disableActions} page={page} onCloseMenu={onCloseMenu} />
          )}
        />
        <Tooltip position='bottom' description='Close page' arrowPosition='right'>
          <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray' onClick={handleClose}>
            <Close />
          </Button>
        </Tooltip>
      </div>
    </CardHeader>
  )
}

FormCardHeader.defaultProps = {
  disabledSubmit: false,
  onSubmit: undefined,
  onOpenSearch: undefined
}

export default FormCardHeader
