import format from 'date-fns/format'
import { Field } from '@/client/schema'

export const getFieldValueGetter = (field: Field) => {
    if (field.type === 'Timestamp') {
        return (value: number) => format(new Date(value), 'dd MMM yyyy, HH:mm:ss (z)')
    }

    return (value: any) => value
}
