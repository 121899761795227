import { combineReducers } from '@reduxjs/toolkit'

import { clientReducer } from './common/client'
import { userReducer } from './common/user'
import { menuReducer } from './common/menu'
import { uiReducer } from './common/ui'
import { tableReducer } from './table'
import { formReducer } from './form'
import { searchReducer } from './search'

export default combineReducers({
    client: clientReducer,
    user: userReducer,
    menu: menuReducer,
    ui: uiReducer,
    table: tableReducer,
    form: formReducer,
    search: searchReducer
})
