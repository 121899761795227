import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { requestSearchData } from '@/helpers/requests/search'

import { ThunkAPI } from '../store'
import { selectClient } from '../selectors/common/client'
import { selectQuerySearch, selectSearchPagination } from '../selectors/search'

export const SEARCH_ACTION = 'SEARCH'
export const SET_QUERY_SEARCH_ACTION = `${SEARCH_ACTION}/SET_QUERY`
export const CLEAR_SEARCH_ACTION = `${SEARCH_ACTION}/CLEAR`

export const setQuerySearchAction = createAction<string>(SET_QUERY_SEARCH_ACTION)
export const clearSearchAction = createAction(CLEAR_SEARCH_ACTION)

export type SearchDataResponse = { items: any[]; nextToken: string | null; pageIndex: number }
export type SearchDataVariables = { modelKey: string; pageIndex: number }
export const requestSearchDataAction = createAsyncThunk<SearchDataResponse, SearchDataVariables, ThunkAPI>(
    SEARCH_ACTION,
    async ({ modelKey, pageIndex }, { getState }) => {
        const state = getState()
        const client = selectClient(state)
        const query = selectQuerySearch(state)
        const { nextTokens } = selectSearchPagination(state)
        const variables = { limit: 100, nextToken: nextTokens[pageIndex], term: query }
        const { items, nextToken } = await requestSearchData(modelKey, client, variables)
        return { items, nextToken, pageIndex }
    }
)
