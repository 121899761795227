import { gqlFindItems } from '@/client/graphql'
import { ModelType } from '@/client/schema'
import { Client } from '@/redux/actions/common/client'
import { schema } from '../schema'

type Response = { items: any[]; nextToken: string | null }
export const requestSearchData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlFindItems(schema.models[modelKey] as ModelType, 'FLAT')
    const response = await client?.query<{ [key: string]: Response }>({
        fetchPolicy: 'no-cache',
        query,
        variables: { input: variables }
    })
    const { items, nextToken } = response!.data[Object.keys(response!.data)[0]]
    return { items, nextToken }
}
