import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import Select from '@/components/controls/Select'
import AsyncSelect, { AsyncSelectValue } from '@/components/controls/AsyncSelect'
import Input from '@/components/controls/Input'
import Plus from '@/components/icons/cardHeader/Plus'
import Close from '@/components/icons/cardHeader/Close'
import { DEFAULT_FILTER_SORT, getFilterAndSortByModel } from '@/helpers/model/table/filterAndSort'
import useDebounce from '@/hooks/useDebounce'

import { useAppDispatch } from '@/redux/store'
import { FilterData, SortData, setFilterTableAction, setSortTableAction } from '@/redux/actions/table'
import { selectFilterByModel, selectSortByModel } from '@/redux/selectors/table'

import { FilterBlock, FilterEqual, FilterLine, PopupContainer, SortLine } from './styles'

const SORT_DIRECTION_OPTIONS = [
  { value: 'ASC', name: 'Ascending' },
  { value: 'DESC', name: 'Descending' }
]

type Props = {
  modelKey: string
  onRequestData?: (pageIndex?: number) => void
}
const FilterAndSort: React.FC<Props> = ({ modelKey, onRequestData }) => {
  const dispatch = useAppDispatch()

  const filter = useSelector(selectFilterByModel(modelKey))
  const sort = useSelector(selectSortByModel(modelKey))

  const {
    filter: filterOptions,
    sort: sortOptions,
    hasFilter,
    hasSort
  } = useMemo(() => getFilterAndSortByModel(modelKey), [modelKey])
  const activeFilter = useMemo(() => filterOptions.find(({ value }) => filter?.key === value), [filter, filterOptions])

  const handleRequestData = useCallback(() => onRequestData && onRequestData(0), [onRequestData])
  const handleRequestDataDebounce = useDebounce(handleRequestData, 300)

  const handleAddSort = useCallback(() => {
    const option = filter ? DEFAULT_FILTER_SORT[0] : sortOptions[0]
    dispatch(
      setSortTableAction({
        modelKey,
        data: { key: option.value, parameter: option.parameterName, value: 'ASC' }
      })
    )
    handleRequestDataDebounce()
  }, [modelKey, filter, sortOptions, dispatch, handleRequestDataDebounce])
  const handleChangeSort = useCallback(
    (key: string) => {
      const sortByKey = sortOptions.find(({ value }) => value === key)
      dispatch(
        setSortTableAction({
          modelKey,
          data: { key, parameter: sortByKey?.parameterName, value: 'ASC' } as SortData
        })
      )
      handleRequestDataDebounce()
    },
    [modelKey, sortOptions, dispatch, handleRequestDataDebounce]
  )
  const handleChangeSortDirection = useCallback(
    (value: string) => {
      dispatch(setSortTableAction({ modelKey, data: { ...sort, value } as SortData }))
      handleRequestDataDebounce()
    },
    [modelKey, sort, dispatch, handleRequestDataDebounce]
  )
  const handleDeleteSort = useCallback(() => {
    dispatch(setSortTableAction({ modelKey, data: undefined }))
    handleRequestDataDebounce()
  }, [modelKey, dispatch, handleRequestDataDebounce])

  const handleAddFilter = useCallback(() => {
    const option = filterOptions[0]
    dispatch(
      setFilterTableAction({
        modelKey,
        data: { key: option.value, parameter: option.parameterName } as FilterData
      })
    )
  }, [modelKey, filterOptions, dispatch])
  const handleChangeFilter = useCallback(
    (key: string) => {
      const filterByKey = filterOptions.find(({ value }) => value === key)
      dispatch(
        setFilterTableAction({
          modelKey,
          data: { key, parameter: filterByKey?.parameterName } as FilterData
        })
      )
    },
    [modelKey, filterOptions, dispatch]
  )
  const handleDeleteFilter = useCallback(() => {
    dispatch(setFilterTableAction({ modelKey, data: undefined }))
    handleDeleteSort()
    handleRequestDataDebounce()
  }, [modelKey, dispatch, handleDeleteSort, handleRequestDataDebounce])
  const handleAsyncSelectFilterValue = useCallback(
    (value: AsyncSelectValue) => {
      dispatch(setFilterTableAction({ modelKey, data: { ...filter, value } as FilterData }))
      handleRequestDataDebounce()
    },
    [modelKey, filter, dispatch, handleRequestDataDebounce]
  )
  const handleSelectFilterValue = useCallback(
    (value: string) => {
      dispatch(setFilterTableAction({ modelKey, data: { ...filter, value } as FilterData }))
      handleRequestDataDebounce()
    },
    [modelKey, filter, dispatch, handleRequestDataDebounce]
  )
  const handleInputFilterValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFilterTableAction({ modelKey, data: { ...filter, value: e.target.value || '' } as FilterData }))
      handleRequestDataDebounce()
    },
    [modelKey, filter, dispatch, handleRequestDataDebounce]
  )

  return (
    <PopupContainer>
      <FilterBlock>
        <Text size='xs' weight='medium' className='label'>
          FILTER
        </Text>
        {filter && (
          <FilterLine>
            <Select valueType='icon-text' value={filter.key} options={filterOptions} onSelect={handleChangeFilter} />
            <FilterEqual>
              <Text size='sm' weight='medium'>
                =
              </Text>
            </FilterEqual>
            {activeFilter?.field?.type === 'async-select' && (
              <AsyncSelect
                className='filter-value'
                modelKey={activeFilter?.model}
                state={activeFilter.field.state}
                value={filter.value as AsyncSelectValue}
                onSelect={handleAsyncSelectFilterValue}
              />
            )}
            {activeFilter?.field?.type === 'select' && (
              <Select
                className='filter-value'
                value={filter.value as string}
                options={activeFilter.field.options}
                onSelect={handleSelectFilterValue}
              />
            )}
            {activeFilter?.field?.type === 'input' && (
              <Input className='filter-value' value={filter.value as string} onChange={handleInputFilterValue} />
            )}
            <Button size='xs' icon='icon' styleType='tertiary' variant='gray' onClick={handleDeleteFilter}>
              <Close />
            </Button>
          </FilterLine>
        )}
        <Button
          size='sm'
          icon='left'
          styleType='tertiary'
          variant='gray'
          disabled={!!filter || !hasFilter}
          onClick={handleAddFilter}
        >
          <Plus />
          Add Filter
        </Button>
      </FilterBlock>
      <FilterBlock>
        <Text size='xs' weight='medium' className='label'>
          SORT
        </Text>
        {sort && (
          <SortLine>
            {filter ? (
              <Select disabled valueType='icon-text' value='name' options={DEFAULT_FILTER_SORT} />
            ) : (
              <Select valueType='icon-text' value={sort.key} options={sortOptions} onSelect={handleChangeSort} />
            )}
            <Select
              valueType='text'
              value={sort.value}
              options={SORT_DIRECTION_OPTIONS}
              onSelect={handleChangeSortDirection}
            />
            <Button size='xs' icon='icon' styleType='tertiary' variant='gray' onClick={handleDeleteSort}>
              <Close />
            </Button>
          </SortLine>
        )}
        <Button
          size='sm'
          icon='left'
          styleType='tertiary'
          variant='gray'
          disabled={!!sort || (!filter && !hasSort)}
          onClick={handleAddSort}
        >
          <Plus />
          Add Sort
        </Button>
      </FilterBlock>
    </PopupContainer>
  )
}

FilterAndSort.defaultProps = {
  onRequestData: undefined
}

export default FilterAndSort
