import styled from 'styled-components'

import {
    DisplaySize,
    DisplayWeight,
    DISPLAY_SIZE_STYLES,
    DISPLAY_WEIGHT_STYLES
} from '@/constants/styles/typography/display'

export type DisplayProps = {
    size?: DisplaySize
    weight?: DisplayWeight
}

export const Display = styled.div.attrs(({ size, weight }: DisplayProps) => ({
    className: ['display', size, weight].join(' ')
}))<DisplayProps>`
    ${({ size }) => DISPLAY_SIZE_STYLES[size!]}
    ${({ weight }) => DISPLAY_WEIGHT_STYLES[weight!]}
`

Display.defaultProps = {
    size: 'md',
    weight: 'medium'
}
