import { schema } from '@/helpers/schema'
import { Field, ModelEnum, ModelType } from '@/client/schema'

type GetFieldInitialValue = (field: Field) => any
const getFieldInitialValues: GetFieldInitialValue = (field: Field) => {
    if (field.default) {
        return field.default
    }
    if (field.type === 'Boolean') {
        return false
    }
    if (field.type === 'Ref' && field.__kind === 'RefEnum') {
        const fieldEnum = (field.__ref as ModelEnum).members
        const options = Object.keys(fieldEnum)
        return options[0]
    }

    return undefined
}

const getInitialValuesByModelKey = (modelKey: string) => {
    const model = schema.models[modelKey] as ModelType
    const fieldsValidation = Object.keys(model.fields).reduce((accumulator, selector) => {
        if (selector === 'id') {
            return accumulator
        }
        return { ...accumulator, [selector]: getFieldInitialValues(model.fields[selector]) }
    }, {})

    return fieldsValidation
}

export const FORM_INITIAL_VALUES_BY_MODEL_KEY: Record<string, any> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type') {
            return { ...accumulator, [modelKey]: getInitialValuesByModelKey(modelKey) }
        }
        return accumulator
    },
    {}
)
