import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'

import Modal from '@/components/regions/Modal'
import Button from '@/components/controls/Button'
import SmallFields from '@/pages/cards/FormCard/components/SmallFields'

import { useAppDispatch } from '@/redux/store'
import { setModalAction } from '@/redux/actions/common/ui'
import { requestRunActionAction } from '@/redux/actions/actions'
import { selectModal } from '@/redux/selectors/common/ui'

import { Buttons, Container, Fields, Title } from './styles'
import { getModalFormValidation, getModalInitialValues, mapModalFields } from './utils'

const ActionFormModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const alert = useAlert()

  const modal = useSelector(selectModal)

  const schema = useMemo(() => getModalFormValidation(modal?.data.fields), [modal])
  const initialValues = useMemo(() => getModalInitialValues(modal?.data.fields), [modal])
  const fields = useMemo(() => mapModalFields(modal?.data.fields), [modal])

  const handleCloseModal = useCallback(() => dispatch(setModalAction(undefined)), [dispatch])
  const handleSubmitForm = useCallback(
    async (payload: any) => {
      try {
        const { ids, action, entity } = modal?.data.args
        handleCloseModal()
        await dispatch(requestRunActionAction({ modelKey: entity, ids, action, payload })).unwrap()
        alert.show('Action started')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [modal, alert, dispatch, handleCloseModal]
  )

  return (
    <Modal>
      <Container>
        <Title>{modal?.data.args.action.replaceAll('_', ' ')}</Title>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
        >
          {({ dirty, isValid, isSubmitting, handleSubmit }) => (
            <>
              <Fields>
                {fields.map((field, index) => (
                  <SmallFields
                    key={field?.name}
                    hasWriteAccess
                    title={modal?.data.fields[index].title}
                    config={field}
                  />
                ))}
              </Fields>
              <Buttons>
                <Button type='button' size='sm' styleType='tertiary' variant='gray' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  type='button'
                  size='sm'
                  styleType='primary'
                  variant='default'
                  disabled={!dirty || !isValid || isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Buttons>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  )
}

export default ActionFormModal
