/* eslint-disable max-len */
import React from 'react'

const ArrowBack = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='fill'
        d='M13.335 2.488a1.041 1.041 0 00-1.475 0L4.935 9.413a.83.83 0 000 1.175l6.925 6.925a1.041 1.041 0 001.475 0 1.041 1.041 0 000-1.475L7.302 9.996l6.042-6.041c.4-.4.4-1.067-.009-1.467z'
        fill='#545454'
      />
    </svg>
  )
}

export default React.memo(ArrowBack)
