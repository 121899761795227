import React, { useMemo } from 'react'

import Assignments from '@/components/icons/fieldType/Assignments'
import Avatar from '@/components/icons/fieldType/Avatar'
import Checkbox from '@/components/icons/fieldType/Checkbox'
import Comments from '@/components/icons/fieldType/Comments'
import Date from '@/components/icons/fieldType/Date'
import Documents from '@/components/icons/fieldType/Documents'
import Email from '@/components/icons/fieldType/Email'
import Files from '@/components/icons/fieldType/Files'
import Formula from '@/components/icons/fieldType/Formula'
import LookupFrom from '@/components/icons/fieldType/LookupFrom'
import MultiSelect from '@/components/icons/fieldType/MultiSelect'
import Number from '@/components/icons/fieldType/Number'
import Phone from '@/components/icons/fieldType/Phone'
import RelationTo from '@/components/icons/fieldType/RelationTo'
import RichText from '@/components/icons/fieldType/RichText'
import SingleSelect from '@/components/icons/fieldType/SingleSelect'
import Text from '@/components/icons/fieldType/Text'
import URL from '@/components/icons/fieldType/URL'
import Workflow from '@/components/icons/fieldType/Workflow'

export type FieldType =
  | 'avatar'
  | 'assignments'
  | 'checkbox'
  | 'comments'
  | 'date'
  | 'documents'
  | 'email'
  | 'files'
  | 'formula'
  | 'lookup-from'
  | 'multiselect'
  | 'number'
  | 'phone'
  | 'relation-to'
  | 'rich-text'
  | 'single-select'
  | 'text'
  | 'url'
  | 'workflow'
type Props = {
  type?: FieldType
}

const ICON_BY_TYPE = {
  avatar: Avatar,
  assignments: Assignments,
  checkbox: Checkbox,
  comments: Comments,
  date: Date,
  documents: Documents,
  email: Email,
  files: Files,
  formula: Formula,
  'lookup-from': LookupFrom,
  multiselect: MultiSelect,
  number: Number,
  phone: Phone,
  'relation-to': RelationTo,
  'rich-text': RichText,
  'single-select': SingleSelect,
  text: Text,
  url: URL,
  workflow: Workflow
}

const FieldTypeIcon: React.FC<Props> = ({ type, ...props }) => {
  const Icon = useMemo(() => (type && ICON_BY_TYPE[type]) || React.Fragment, [type])

  if (!type) {
    return null
  }

  return <Icon {...props} />
}

FieldTypeIcon.defaultProps = {
  type: undefined
}

export default React.memo(FieldTypeIcon)
