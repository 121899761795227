import styled from 'styled-components'

export const LargeFieldBlockContainer = styled.div`
    background: ${({ theme }) => theme.colors.gray['100']};
    padding: 8px 12px 12px;
    border-radius: 6px;

    .label {
        margin: 0 14px 4px;
        color: ${({ theme }) => theme.colors.gray['700']};
    }
    .error {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.error['500']};
        ul {
            list-style-position: inside;
        }
    }
    .info {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.gray['500']};
    }
`
