import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { selectSelf } from '.'
import { initialTableState, TableReducerState, TableState } from '../reducers/table'

export const selectTableState = createSelector(selectSelf, (state: RootState) => state.table)
export const selectTableByModelKey = (modelKey: string) =>
    createSelector(selectTableState, (state: TableReducerState) => state[modelKey] || initialTableState)
export const selectTable = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => ({
        loading: state.loading,
        called: state.called,
        data: state.data
    }))
export const selectSelectedRows = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => state.selectedRows)
export const selectTablePagination = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => ({
        pageIndex: state.pageIndex,
        nextTokens: state.nextTokens
    }))
export const selectItemsCount = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => {
        const { nextTokens, pageIndex, itemsCount } = state
        const hasMore = nextTokens[nextTokens.length - 1] !== null
        const sum = itemsCount.reduce((acumulator, count) => acumulator + count, 0)
        const startTableCount = itemsCount.slice(0, pageIndex).reduce((acumulator, count) => acumulator + count, 1)

        return { count: `${sum}${hasMore ? '+' : ''}`, startTableCount }
    })

export const selectColumnsByModel = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => state.columns || {})
export const selectFilterByModel = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => state.filter)
export const selectSortByModel = (modelKey: string) =>
    createSelector(selectTableByModelKey(modelKey), (state: TableState) => state.sort)
