import styled from 'styled-components'

export const PopupContainer = styled.div`
    width: 624px;
`

export const FilterBlock = styled.div`
    padding: 12px;

    .label {
        padding: 0 14px;
    }
    & > button {
        margin-top: 14px;
    }
`

const ControlsLine = styled.div`
    display: flex;
    align-items: center;
    gap: 0 4px;
    margin-top: 14px;
`
export const FilterLine = styled(ControlsLine)`
    .select {
        width: 30%;
    }
    .filter-value {
        width: 60%;
    }
`
export const FilterEqual = styled.div`
    background: ${({ theme }) => theme.colors.gray['100']};
    color: ${({ theme }) => theme.colors.gray['400']};
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    border-radius: 8px;
    padding: 8px 14px;
`
export const SortLine = styled(ControlsLine)`
    .select {
        width: 50%;
    }
`
