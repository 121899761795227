import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useAlert } from 'react-alert'
import qs from 'query-string'

import { HOME_URL } from '@/constants/routes'
import { useCognitoUser } from '@/hooks/useCognitoUser'

const ERRORS: Record<string, string> = {
  'User cannot be confirmed. Current status is CONFIRMED': 'User already verified.'
}

const ChangeEmailConfirmPage = () => {
  const alert = useAlert()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { refreshUser } = useCognitoUser()
  const { code } = useMemo(() => qs.parse(search), [search])

  useEffect(() => {
    Auth.verifyCurrentUserAttributeSubmit('email', code as string)
      .then(() => {
        refreshUser()
        navigate(HOME_URL)
        alert.show('Email changed.')
      })
      .catch((e) => {
        navigate(HOME_URL)
        alert.show(ERRORS[e.message] || e.message)
      })
  }, [code, navigate, alert, refreshUser])

  return null
}

export default ChangeEmailConfirmPage
