import React, { useMemo } from 'react'
import { useSubscription } from '@apollo/client'

import { schema } from '@/helpers/schema'
import { ModelType } from '@/client/schema'
import { gqlOnChangeItem } from '@/client/graphql'

import { useAppDispatch } from '@/redux/store'
import { setSubscriptionDataAction } from '@/redux/actions/subscription'

type Props = { modelKey: string }
const Subscription: React.FC<Props> = ({ modelKey }) => {
  const dispatch = useAppDispatch()
  const subscription = useMemo(() => gqlOnChangeItem(schema.models[modelKey] as ModelType, true), [modelKey])
  useSubscription(subscription, {
    variables: {},
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const response = data[Object.keys(data)[0]]
      dispatch(setSubscriptionDataAction({ modelKey, response }))
    }
  })
  return null
}

export default Subscription
