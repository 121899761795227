/* eslint-disable max-len */
import React from 'react'

function Number(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M9.167 3.333L5.833 16.667m8.334-13.334l-3.334 13.334M3.5 12.5h11.75m-10.5-5H16.5'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Number)
