import { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router'
import qs from 'query-string'
import { getIsServerSide } from '@/helpers/getIsServerSide'

export const getQueryString = (query: Record<string, any>) =>
    qs.stringify(query, { encode: false, arrayFormat: 'comma' })

export const getUrlParams = () => {
    if (!getIsServerSide()) {
        return { query: {} }
    }
    const { search } = window.location
    const query = qs.parse(search)
    return { query }
}

const useUrlParameters = () => {
    const navigate = useNavigate()
    const { search, pathname: originalPathname, hash } = useLocation()
    const query = useMemo(() => qs.parse(search), [search])

    const handleChangeQuery = useCallback(
        <T = any>(parameters: Record<any, T>, pathname?: string) => {
            const searchValue = getQueryString({
                ...(!pathname ? (query as any) : {}),
                ...parameters
            })
            navigate({ pathname: pathname || originalPathname, search: searchValue })
        },
        [navigate, originalPathname, query]
    )

    return {
        query,
        hash,
        pathname: originalPathname,
        handleChangeQuery
    }
}

export default useUrlParameters
