import { createReducer } from '@reduxjs/toolkit'

import { getObjectDiff } from '@/helpers/getObjectDiff'
import { insertPage, PaginationType } from '@/hooks/usePagination'

import {
    requestTableDataAction,
    requestDeleteRowsAction,
    setSelectedRowTableAction,
    setTableColumnsAction,
    setFilterTableAction,
    setSortTableAction,
    clearTableAction,
    VisibleColumns,
    FilterData,
    SortData
} from '../actions/table'
import { setSubscriptionDataAction } from '../actions/subscription'

export type TableData = {
    called: boolean
    loading: boolean
    data: any[]
    itemsCount: number[]
}
export type TableUI = {
    columns: VisibleColumns
    filter?: FilterData
    sort?: SortData
    selectedRows: string[]
}

export type TableState = TableData & TableUI & PaginationType
export type TableReducerState = Record<string, TableState>

export const initialTableState = {
    data: [],
    loading: false,
    called: false,

    selectedRows: [],
    itemsCount: [],
    filter: undefined,
    sort: undefined,
    columns: {},

    pageIndex: 0,
    nextTokens: [null]
} as TableState

const initialState = {} as TableReducerState

export const tableReducer = createReducer(initialState, (builder) => {
    builder.addCase(requestTableDataAction.pending, (state, { meta }) => {
        const { modelKey } = meta.arg
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].called = true
        state[modelKey].loading = true
    })
    builder.addCase(requestTableDataAction.fulfilled, (state, { payload: { items, nextToken, pageIndex }, meta }) => {
        const { modelKey } = meta.arg
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].loading = false
        state[modelKey].data = items
        state[modelKey].pageIndex = pageIndex
        state[modelKey].itemsCount[pageIndex] = items.length

        if (state[modelKey].nextTokens[pageIndex + 1] === undefined) {
            state[modelKey].nextTokens = insertPage(state[modelKey].nextTokens, pageIndex + 1, nextToken)
        }
    })
    builder.addCase(requestTableDataAction.rejected, (state, { meta }) => {
        const { modelKey } = meta.arg
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].loading = false
    })

    builder.addCase(requestDeleteRowsAction.pending, (state, { meta }) => {
        const { modelKey } = meta.arg
        state[modelKey].loading = true
    })

    builder.addCase(setSelectedRowTableAction, (state, { payload: { modelKey, ids } }) => {
        if (typeof ids === 'string') {
            const rows = state[modelKey].selectedRows.includes(ids)
                ? state[modelKey].selectedRows.filter((id) => id !== ids)
                : [...state[modelKey].selectedRows, ids]
            state[modelKey].selectedRows = rows
        } else {
            state[modelKey].selectedRows = ids
        }
    })

    builder.addCase(setSubscriptionDataAction, (state, { payload: { modelKey, response } }) => {
        const tableEntityIndex = state[modelKey].data.findIndex(({ id }) => id === response.id)
        if (tableEntityIndex !== -1) {
            const diff = getObjectDiff(response.before || {}, response.after || {})
            diff.forEach((fieldKey: string) => {
                state[modelKey].data[tableEntityIndex][fieldKey] = response.after[fieldKey]
            })
        }
    })

    builder.addCase(setTableColumnsAction, (state, { payload: { modelKey, columns } }) => {
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].columns = columns
    })
    builder.addCase(setFilterTableAction, (state, { payload: { modelKey, data } }) => {
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].filter = data
    })
    builder.addCase(setSortTableAction, (state, { payload: { modelKey, data } }) => {
        if (!state[modelKey]) {
            state[modelKey] = { ...initialTableState }
        }
        state[modelKey].sort = data
    })

    builder.addCase(clearTableAction, (state, { payload }) => {
        delete state[payload]
    })
})
