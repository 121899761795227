/* eslint-disable max-len */
import React from 'react'

function URL(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M10.59 15.303L9.41 16.482a4.167 4.167 0 11-5.892-5.893l1.178-1.178m10.607 1.178l1.178-1.178a4.167 4.167 0 10-5.892-5.893L9.41 4.697m-2.327 8.22l5.833-5.834'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(URL)
