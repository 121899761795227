import styled, { css, keyframes } from 'styled-components'
import { TooltipProps } from './types'

const showTooltipAnimation = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`

interface TooltipContainerProps {
    useClickTooltip?: boolean
    showTooltip: boolean
}

export const StyledTooltip = styled.div<Partial<TooltipProps>>`
    position: absolute;
    z-index: 100;
    border-radius: 6px;
    padding: 6px 8px;
    visibility: hidden;
    opacity: 0;
    animation-duration: 100ms;
    animation-delay: ${({ delay }) => `${delay}ms`};
    animation-fill-mode: forwards;

    .text {
        white-space: nowrap;
    }

    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 8px;
        z-index: 3;
    }

    ${({ position, arrowPosition }) => {
        switch (position) {
            case 'top': {
                if (arrowPosition === 'left') {
                    return `
                      left: 50%;
                      bottom: calc(100% + 12px);
                      transform: translate(-20%, 0);

                      &::after {
                        bottom: -5px;
                        left: 20%;
                        transform: translateX(-50%) rotate(45deg);
                      }
                      &::before {
                        bottom: -0.5px;
                        left: 20%;
                        transform: translateX(-50%);
                      }
                    `
                }
                return `
                  left: 50%;
                  bottom: calc(100% + 12px);
                  transform: translate(-50%, 0);
                  
                  &::after {
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg);
                  }
                  &::before {
                    bottom: -0.5px;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                `
            }
            case 'right':
                return `
                  top: 50%;
                  left: calc(100% + 12px);
                  transform: translate(0, -50%);

                  &::after {
                    left: -5px;
                    top: 50%;
                    transform: translateY(-50%) rotate(135deg);
                  }
                  &::before {
                    left: -0.5px;
                    top: 50%;
                    transform: translateX(-50%);
                  }
                `
            case 'bottom': {
                if (arrowPosition === 'left') {
                    return `
                      left: 50%;
                      top: calc(100% + 12px);
                      transform: translate(-20px, 0);

                      &::after {
                        top: -5px;
                        left: 14px;
                        transform: rotate(-135deg);
                      }
                      &::before {
                        top: -0.5px;
                        left: 14px;
                      }
                    `
                }
                if (arrowPosition === 'right') {
                    return `
                      right: 50%;
                      top: calc(100% + 12px);
                      transform: translate(20px, 0);

                      &::after {
                        top: -5px;
                        right: 14px;
                        transform: rotate(-135deg);
                      }
                      &::before {
                        top: -0.5px;
                        right: 14px;
                      }
                    `
                }
                return `
                  left: 50%;
                  top: calc(100% + 12px);
                  transform: translate(-50%, 0);

                  &::after {
                    top: -5px;
                    left: 50%;
                    transform: translateX(-50%) rotate(-135deg);
                  }
                  &::before {
                    top: -0.5px;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                `
            }
            case 'left':
                return `
                  top: 50%;
                  right: calc(100% + 12px);
                  transform: translate(0, -50%);

                  &::after {
                    right: -5px;
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                  }
                  &::before {
                    right: -0.5px;
                    top: 50%;
                    transform: translateX(-50%);
                  }
                `
            default:
                return ''
        }
    }}

    ${({ theme, styleType }) => {
        switch (styleType) {
            case 'dark': {
                return `
                  background: ${theme.colors.gray['800']};
                  color: ${theme.colors.base.white};

                  &::after {
                    background: linear-gradient(
                      to bottom right,
                      transparent 0%,
                      transparent 50%,
                      ${theme.colors.gray['800']} 50%,
                      ${theme.colors.gray['800']} 100%
                    );
                  }
                `
            }
            case 'light': {
                return `
                  background: ${theme.colors.base.white};
                  border: 1px solid ${theme.colors.gray['800']};
                  border-radius: 8px;
                  box-shadow: 0 0 15px 2px rgb(0 0 0 / 10%);
                  color: #101828;

                  &::after {
                    background: ${theme.colors.base.white};
                    border: 1px solid ${theme.colors.gray['800']};
                  }
                  &::before {
                    background: ${theme.colors.base.white};
                  }
                `
            }
            default:
                return ''
        }
    }}
`

export const TooltipContainer = styled.div<TooltipContainerProps>`
    position: relative;

    &:hover {
        ${StyledTooltip} {
            ${({ showTooltip }) =>
                !showTooltip
                    ? css`
                          animation-name: ${showTooltipAnimation};
                      `
                    : ''};
        }
    }

    ${({ showTooltip }) =>
        showTooltip &&
        `
        ${StyledTooltip} {
          visibility: visible;
          opacity: 1;
        }
    `}
`

export const ChildrenWrapper = styled.span`
    cursor: pointer;
`
