import React, { useMemo } from 'react'

import { Text } from '@/components/typography/Text'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'

import { ChildrenWrapper, StyledTooltip, TooltipContainer } from './styles'
import { TooltipProps } from './types'

const Tooltip: React.FC<TooltipProps> = ({ description, children, className, hidden, showOnClick, ...props }) => {
  const { containerRef, controlRef, isOpen, onToggle } = useOutsideClickHandler<HTMLDivElement, HTMLDivElement>()

  const WrapperComponent = useMemo(() => (showOnClick ? ChildrenWrapper : React.Fragment), [showOnClick])
  const wrapperProps = useMemo(
    () => (showOnClick ? { onClick: onToggle, ref: controlRef } : {}),
    [controlRef, showOnClick, onToggle]
  )

  return (
    <TooltipContainer className={className} useClickTooltip={showOnClick} showTooltip={isOpen}>
      <WrapperComponent {...wrapperProps}>{children}</WrapperComponent>
      {!!description && !hidden && description && (
        <StyledTooltip ref={containerRef} {...props}>
          <Text size='xs'>{description}</Text>
        </StyledTooltip>
      )}
    </TooltipContainer>
  )
}

Tooltip.defaultProps = {
  styleType: 'dark',
  position: 'top',
  arrowPosition: 'center',
  delay: 1000,
  hidden: false,
  showOnClick: false
} as TooltipProps

export default Tooltip
