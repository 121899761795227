import { schema } from '@/helpers/schema'
import { Field, ModelType } from '@/client/schema'

type GetFieldMappedValue = (
    field: Field,
    selector: string,
    values: Record<string, any>
) => { field: string; value: any } | undefined
const getFieldValue: GetFieldMappedValue = (field: Field, selector: string, values: Record<string, any>) => {
    const value = values[selector]

    if (field.type === 'String' && value === '') {
        return { field: selector, value: null }
    }
    if (field.type === 'JSON' && value === '{}') {
        return { field: selector, value: null }
    }
    if (field.type === 'Int' && value !== undefined) {
        return { field: selector, value: value ? Number.parseInt(value, 10) : null }
    }
    if (field.type === 'Timestamp') {
        if (!value) {
            return { field: selector, value: null }
        }
        const fieldValue =
            typeof value === 'number' ? value : Math.round(field.isMs ? value.getTime() : value.getTime() / 1000)
        return { field: selector, value: fieldValue }
    }
    if (field.type === 'Float' && value !== undefined) {
        return { field: selector, value: value ? Number.parseFloat(value) : null }
    }
    if (field.type === 'Ref' && field.__kind === 'RefType') {
        return { field: `${selector}Id`, value: value ? value.id : null }
    }
    if ((field.type === 'Set' || field.type === 'List') && field.__kind === 'ArrayLikeOfRefEnum') {
        const fieldValue = value && Array.isArray(value) && value.length > 0 ? value : null
        return { field: selector, value: fieldValue }
    }
    if ((field.type === 'Set' || field.type === 'List') && field.__kind === 'ArrayLikeOfRefType') {
        const fieldValue = value ? value.map((item: { id: string }) => item.id) : null
        return { field: `${selector}Ids`, value: fieldValue }
    }
    if (!value && typeof value !== 'boolean') {
        return { field: selector, value: null }
    }

    return { field: selector, value }
}

export const getMappedValuesByModelKey = (modelKey: string, values: Record<string, any>, update = false) => {
    const model = schema.models[modelKey] as ModelType
    const fieldsValidation = Object.keys(model.fields).reduce((accumulator, selector) => {
        const field = model.fields[selector]
        if (
            field.external ||
            field.type === 'Formula' ||
            field.type === 'Index' ||
            (update && field.readonly) ||
            (!update && field.type === 'ID' && field.formula)
        ) {
            return accumulator
        }
        const mappedValue = getFieldValue(field, selector, values)
        if (mappedValue) {
            return { ...accumulator, [mappedValue.field]: mappedValue.value }
        }
        return accumulator
    }, {})

    return fieldsValidation
}
