import styled from 'styled-components'

export const ChipsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    background: ${({ theme }) => theme.colors.gray['100']};
    border-radius: 6px;
    padding: 3px 6px 3px 8px;
`
