export type DisplaySize = '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'
export type DisplayWeight = 'regular' | 'medium' | 'semibold' | 'bold'

export type DisplaySizeConfig = Record<DisplaySize, string>
export type DisplayWeightConfig = Record<DisplayWeight, string>

export const DISPLAY_SIZE_STYLES: DisplaySizeConfig = {
    '2xl': `
      font-size: 72px;
      line-height: 90px;
    `,
    xl: `
      font-size: 60px;
      line-height: 72px;
    `,
    lg: `
      font-size: 48px;
      line-height: 60px;
    `,
    md: `
      font-size: 36px;
      line-height: 44px;
    `,
    sm: `
      font-size: 30px;
      line-height: 38px;
    `,
    xs: `
      font-size: 24px;
      line-height: 32px;
    `
}

export const DISPLAY_WEIGHT_STYLES: DisplayWeightConfig = {
    regular: 'font-weight: 400;',
    medium: 'font-weight: 500;',
    semibold: 'font-weight: 600;',
    bold: 'font-weight: 700;'
}
