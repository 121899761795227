import { createReducer } from '@reduxjs/toolkit'

import { UserData } from '@/types/user'
import { requestUserAction, logoutUserAction } from '../../actions/common/user'

export type UserState = UserData & {
    requestId?: string
    loading: boolean
    called: boolean
}

const initialState = {
    requestId: undefined,
    user: undefined,
    attributes: undefined,
    loading: false,
    called: false
} as UserState

export const userReducer = createReducer(initialState, (builder) => {
    builder.addCase(requestUserAction.pending, (state, { meta }) => {
        state.requestId = meta.requestId
        state.loading = true
        state.called = true
    })
    builder.addCase(requestUserAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.requestId = undefined
        state.user = payload.user
        state.attributes = payload.attributes
    })
    builder.addCase(requestUserAction.rejected, (state) => {
        state.loading = false
        state.requestId = undefined
    })

    builder.addCase(logoutUserAction.pending, () => initialState)
    builder.addCase(logoutUserAction.fulfilled, () => initialState)
    builder.addCase(logoutUserAction.rejected, () => initialState)
})
