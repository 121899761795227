export const hexToRgb = (hex: string, alpha?: number) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) {
        return 'transparent'
    }
    const colors = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    const name = alpha ? 'rgba' : 'rgb'
    const alphaValue = alpha ? `, ${alpha}` : ''

    return `${name}(${colors.join(', ')}${alphaValue})`
}
