import * as React from 'react'

function ChevronDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
      <path
        className='stroke'
        d='M4 6l4 4 4-4'
        stroke='#101828'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(ChevronDown)
