import React, { useCallback } from 'react'

import { FieldType } from '@/components/icons/fieldType/FieldTypeIcon'
import { ModelMenuConfig } from '@/helpers/model/menu'
import { VisibleColumns } from '@/redux/actions/table'

import Cell from './components/Cell'
import DataCell, { DataCellValues } from './components/DataCell'
import { TableOuter, TableSC } from './styles'

export type TableColumn = {
  key: string
  selector: string
  type: FieldType
  dataCell: DataCellValues
}
type Props = {
  modelMenuConfig: ModelMenuConfig
  startCount: number
  selectable?: boolean
  columns: TableColumn[]
  visibleColumns: VisibleColumns
  selectedRows: string[]
  data: any[]
  onSelectRow: (value: string | string[]) => void
  onOpenRow: (row: any, expanded?: boolean) => void
}

const Table: React.FC<Props> = ({
  modelMenuConfig,
  startCount,
  selectable,
  columns,
  visibleColumns,
  selectedRows,
  data,
  onSelectRow,
  onOpenRow
}) => {
  const handleSelectRow = useCallback((id: string) => () => onSelectRow(id), [onSelectRow])
  const handleSelectAll = useCallback(() => {
    const allSelected = data.length === selectedRows.length
    const rows = allSelected ? [] : data.map((row) => row.id)
    onSelectRow(rows)
  }, [selectedRows, data, onSelectRow])

  const handleOpenRow = useCallback((row: any) => (expanded?: boolean) => onOpenRow(row, expanded), [onOpenRow])
  return (
    <TableOuter>
      <TableSC>
        <thead>
          <tr>
            <Cell.THFirst
              key='id'
              selectable={selectable}
              selected={data.length === selectedRows.length}
              modelMenuConfig={modelMenuConfig}
              onSelect={handleSelectAll}
            />
            {columns.map((column) => {
              if (column.key === 'id' || !visibleColumns[column.key]) {
                return null
              }
              return <Cell.TH key={column.key} column={column} />
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const selected = selectedRows.includes(row.id)
            return (
              <tr key={row.id}>
                <Cell.TDFirst
                  key='id'
                  index={startCount + index}
                  selectable={selectable}
                  selected={selected}
                  onOpen={handleOpenRow(row)}
                  onSelect={handleSelectRow(row.id)}
                />
                {columns.map(({ key, selector, dataCell }) => {
                  if (selector === 'id' || !visibleColumns[key]) {
                    return null
                  }
                  return (
                    <Cell.TD key={selector}>
                      <DataCell selector={selector} row={row} {...dataCell} />
                    </Cell.TD>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </TableSC>
    </TableOuter>
  )
}

Table.defaultProps = {
  selectable: false
}

export default Table
