import * as React from 'react'

function Search(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M17.5 17.5l-5-5m1.667-4.167a5.833 5.833 0 11-11.667 0 5.833 5.833 0 0111.667 0z'
        stroke='#667085'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Search)
