/* eslint-disable max-len */
import * as React from 'react'

function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 12C0 2.118 2.118 0 12 0s12 2.118 12 12-2.118 12-12 12S0 21.882 0 12zm3.774-6.667C4.998 3.248 7.433 2.667 12 2.667c4.567 0 7.002.581 8.226 2.666H3.774zM2.912 8c-.124.778-.195 1.662-.226 2.667h18.628c-.031-1.005-.102-1.89-.226-2.667H2.912zm0 8c-.124-.778-.195-1.662-.226-2.667h18.628c-.031 1.005-.102 1.89-.226 2.667H2.912zm.862 2.667c1.224 2.085 3.659 2.666 8.226 2.666 4.567 0 7.002-.581 8.226-2.666H3.774z'
        fill='#155EEF'
      />
    </svg>
  )
}

export default React.memo(Logo)
