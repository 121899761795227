import React from 'react'
import { AlertTemplateProps, positions, transitions } from 'react-alert'

import { AlertContainer } from './styles'

export const ALERT_OPTIONS = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '12px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 10000,
    marginTop: '30px'
  }
}

const Alert = ({ style, message, options: { type } }: AlertTemplateProps) => {
  return (
    <AlertContainer style={style} alertType={type}>
      {message}
    </AlertContainer>
  )
}

export default Alert
