import { schema } from '@/helpers/schema'
import { Field, FieldRef, ModelEnum, ModelType } from '@/client/schema'
import { SelectOption } from '@/components/controls/Select'

export const getFormFieldName = (field: Field) => {
    if (field.type === 'Ref' && field.__kind === 'RefEnum') {
        return field.__key || field.__ref.__key
    }
    return field.__key
}

type FieldType =
    | 'input'
    | 'timestamp'
    | 'json'
    | 'rich-text'
    | 'checkbox'
    | 'select'
    | 'async-select'
    | 'enum-select'
    | 'index'
    | 'relation'
    | 'image'
export type StateConfig = { color: string; finaly?: boolean }
export type FieldState = Record<string, StateConfig>
type DefaultFieldConfig = {
    name: string
    type: FieldType
    large?: boolean
    disabled?: { all?: boolean; edit?: boolean }
}
type ImageFieldConfig = {
    list?: boolean
    modalPath?: string
    path?: string
    extension?: string
    size?: { width: number; height: number }
    pathWithoutSize?: boolean
}
type SelectFieldConfig = { options?: SelectOption[] }
type AsyncSelectFieldConfig = {
    modelKey?: string
    state?: FieldState
}
type FieldConfigType = DefaultFieldConfig & ImageFieldConfig & SelectFieldConfig & AsyncSelectFieldConfig
export type FieldConfig = FieldConfigType | undefined
type GetFieldConfigOptions = { allowExternal: boolean; allowFormula: boolean }
export const getFieldConfig = (
    field: Field,
    { allowExternal = false, allowFormula = false } = {} as GetFieldConfigOptions
): FieldConfig => {
    if (field.visible === false) {
        return undefined
    }
    const isExternal = !allowExternal && field.external
    const isFormula = !allowFormula && field.type === 'Formula'
    if (isFormula) {
        return undefined
    }
    const name = getFormFieldName(field)
    const disabled = { edit: field.readonly, all: isExternal }
    if (
        field.type === 'String' ||
        field.type === 'Int' ||
        field.type === 'Float' ||
        field.type === 'ID' ||
        field.type === 'Phone' ||
        field.type === 'Email' ||
        field.type === 'URL'
    ) {
        return { name, disabled, type: 'input' }
    }
    if (field.type === 'Timestamp') {
        return { name, disabled, type: 'timestamp' }
    }
    if (field.type === 'JSON') {
        return { name, disabled, type: 'json', large: true }
    }
    if (field.type === 'List' && field.__kind === 'ArrayLikeOfScalar') {
        const fieldRef = field.of as FieldRef
        const refConfig = getFieldConfig(fieldRef as Field)
        return {
            ...refConfig,
            list: true,
            name,
            disabled
        } as FieldConfig
    }
    if (field.type === 'Img') {
        return {
            name,
            disabled,
            modalPath: field.modalPath,
            path: field.path,
            size: field.size,
            extension: field.extension,
            pathWithoutSize: field.pathWithoutSize,
            type: 'image',
            large: true
        }
    }
    if (field.type === 'Markdown') {
        return { name, disabled, type: 'rich-text', large: true }
    }
    if (field.type === 'Boolean') {
        return { name, disabled, type: 'checkbox' }
    }
    if (field.type === 'Index') {
        const {
            fields: { state },
            __key: modelKey
        } = field.__ref.__model as ModelType
        const { members } = (state as FieldRef).__ref as ModelEnum
        return { name, modelKey, disabled, type: 'index', state: members as FieldState, large: true }
    }
    if (field.type === 'Ref' && field.__kind === 'RefEnum') {
        const fieldEnum = (field.__ref as ModelEnum).members
        const options = Object.keys(fieldEnum).reduce(
            (accumulator, item) => [...accumulator, { value: item, name: item, color: fieldEnum[item].color }],
            [] as SelectOption[]
        )
        return { name, disabled, type: 'select', options }
    }
    if (field.type === 'Ref' && field.__kind === 'RefType') {
        const {
            fields: { state },
            __key: modelKey
        } = field.__ref as ModelType
        if (state) {
            const { members } = (state as FieldRef).__ref as ModelEnum
            return { name, modelKey, disabled, type: 'async-select', state: members as FieldState }
        }
        return { name, modelKey, disabled, type: 'async-select' }
    }
    if (field.type === 'Set' && field.__kind === 'ArrayLikeOfRefEnum') {
        const fieldRef = field.of as FieldRef
        const fieldEnum = (fieldRef.__ref as ModelEnum).members
        const options = Object.keys(fieldEnum).reduce(
            (accumulator, item) => [...accumulator, { value: item, name: item, color: fieldEnum[item].color }],
            [] as SelectOption[]
        )
        return { name, disabled, type: 'enum-select', options, large: true }
    }
    if (field.type === 'Set' || (field.type === 'List' && field.__kind === 'ArrayLikeOfRefType')) {
        const fieldRef = field.of as FieldRef
        const modelKey = (fieldRef.__ref as ModelType).__key
        return { name, modelKey, disabled, type: 'relation', large: true }
    }

    return undefined
}

type ModelFields = { small: FieldConfig[]; large: FieldConfig[] }
const getFieldsByModelKey = (modelKey: string): ModelFields => {
    const model = schema.models[modelKey] as ModelType
    return Object.keys(model.fields).reduce(
        (accumulator, selector) => {
            const config = getFieldConfig(model.fields[selector])
            if (!config) {
                return accumulator
            }
            if (config?.large) {
                return { ...accumulator, large: [...accumulator.large, config] }
            }
            return { ...accumulator, small: [...accumulator.small, config] }
        },
        { small: [], large: [] } as ModelFields
    )
}

export const FORM_FIELDS_BY_MODEL_KEY: Record<string, ModelFields> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type') {
            return { ...accumulator, [modelKey]: getFieldsByModelKey(modelKey) }
        }
        return accumulator
    },
    {}
)
