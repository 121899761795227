import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link'
import { Auth } from 'aws-amplify'
import { ApolloLink, InMemoryCache, ApolloClient, HttpLink } from '@apollo/client'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import config from '@/helpers/config'

const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
        const session = await Auth.currentSession()
        const token = session.getIdToken().getJwtToken()
        return token
    }
}

const URL = `${config.REACT_APP_DOMAIN_URL}/graphql`
const authLink = createAuthLink({
    url: URL,
    region: config.REACT_APP_GRAPHQL_REGION,
    auth
})

const httpLink = new HttpLink({ uri: URL })

const link = ApolloLink.from([
    authLink,
    createSubscriptionHandshakeLink(
        {
            url: config.REACT_APP_SUBSCRIPTION_GRAPHQL_URL,
            region: config.REACT_APP_GRAPHQL_REGION,
            auth
        },
        httpLink
    )
])

export const cognitoClient = new ApolloClient({
    link,
    cache: new InMemoryCache({ addTypename: false }),
    ssrForceFetchDelay: 100
})
