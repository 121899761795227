import * as React from 'react'

function Expand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill='none' {...props}>
      <path
        className='stroke'
        d='M9.333 6.667L14 2m0 0h-4m4 0v4M6.667 9.333L2 14m0 0h4m-4 0v-4'
        stroke='#667085'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Expand)
