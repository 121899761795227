import * as React from 'react'

function Filter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M5 10h10M2.5 5h15m-10 10h5'
        stroke='#667085'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Filter)
