import styled from 'styled-components'

import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'

interface FloatInputContainerProps {
    hasError?: boolean
}
interface InputProps {
    inputSize?: 'sm' | 'md'
    hasValue?: boolean
}

export const InputSC = styled.input<InputProps>`
    width: 100%;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.gray['900']};
    transition: all 100ms linear 0s;
    -webkit-appearance: none;
    ${TEXT_WEIGHT_STYLES.medium};
    ${({ inputSize = 'sm' }) => `
        ${inputSize === 'sm' ? 'padding: 8px 14px;' : 'padding: 10px 14px;'}
        ${TEXT_SIZE_STYLES[inputSize]}
    `}

    &::placeholder {
        color: ${({ theme }) => theme.colors.gray['400']};
        ${({ inputSize = 'sm' }) => TEXT_SIZE_STYLES[inputSize]}
        ${TEXT_WEIGHT_STYLES.medium};
    }
    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.primary['300']};
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colors.primary['100']};
    }
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary['300']};
    }
    &:disabled {
        outline: none;
        color: ${({ theme }) => theme.colors.gray['400']};
        background: ${({ theme }) => theme.colors.gray['100']};
        border-color: ${({ theme }) => theme.colors.gray['300']};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        ${({ inputSize = 'sm' }) => TEXT_SIZE_STYLES[inputSize]}
        -webkit-text-fill-color: ${({ theme }) => theme.colors.gray['900']};
        -webkit-box-shadow: 0 0 0px 100px #fff inset;
    }
`

export const InputContainer = styled.div<FloatInputContainerProps>`
    width: 100%;
    height: auto;
    position: relative;

    button.icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
    }

    ${({ theme, hasError }) =>
        hasError &&
        `
          ${InputSC} {
            border: 1px solid #FDA29B;
          }
          ${InputSC}:focus {
            border-color: ${theme.colors.error['300']};
            box-shadow: 0px 0px 0px 2px ${theme.colors.error['100']};
          }
    `}
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .label {
        margin: 0 14px 4px;
        color: ${({ theme }) => theme.colors.gray['700']};
    }
    .error {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.error['500']};
    }
    .info {
        margin: 4px 14px 0;
        color: ${({ theme }) => theme.colors.gray['500']};
    }
`
