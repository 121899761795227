import styled from 'styled-components'

export const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const ColumnRow = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 16px;
    color: ${({ theme }) => theme.colors.gray['700']};

    .text {
        margin-left: 8px;
    }
`
