import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'

import {
  FieldWrapper,
  StyledForm,
  ActionButton,
  InputBlock,
  Title,
  ButtonsContainer
} from '@/components/layouts/LoginLayout/styles'
import LoginLayout from '@/components/layouts/LoginLayout'
import InputField from '@/components/fields/InputField'
import Input from '@/components/controls/Input'

import { FULL_NAME_FIELD, PASSWORD_FIELD, PASSWORD_CONFIRM_FIELD } from '@/constants/forms/requireNewPassword'
import { HOME_URL } from '@/constants/routes'
import requireNewPassword from '@/helpers/validators/forms/requireNewPassword'
import { mapAmplifyMessage } from '@/helpers/mapAmplifyMessage'

export interface RequireNewPasswordFormValues {
  [FULL_NAME_FIELD]?: string
  [PASSWORD_FIELD]: string
  [PASSWORD_CONFIRM_FIELD]: string
}

const ERRORS = {
  'Invalid attributes given, name is missing': {
    field: FULL_NAME_FIELD,
    message: 'Required'
  }
} as Record<string, { field: string; message: string }>

type Props = { user: any; onBack: () => void }
const RequireNewPasswordPage: React.FC<Props> = ({ user, onBack }) => {
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async ({ password, name }: RequireNewPasswordFormValues, actions: any) => {
      try {
        await Auth.completeNewPassword(user, password, { name })
        actions.setSubmitting(false)
        navigate(HOME_URL)
      } catch (e: any) {
        const error = mapAmplifyMessage(e.message)
        const mappedError = ERRORS[error] || { field: PASSWORD_FIELD, message: error }
        actions.setFieldError(mappedError.field, mappedError.message)
      }
    },
    [user, navigate]
  )
  return (
    <LoginLayout onBack={onBack}>
      <Title>Choose a strong password</Title>
      <Formik
        initialValues={{} as RequireNewPasswordFormValues}
        validationSchema={requireNewPassword}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StyledForm>
            <InputBlock>
              {(user.challengeParam.requiredAttributes || []).includes('name') && (
                <>
                  <FieldWrapper>
                    <Input
                      inputSize='md'
                      label='EMAIL'
                      type='text'
                      disabled
                      value={user.challengeParam.userAttributes.email}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      inputSize='md'
                      label='FULL NAME'
                      type='text'
                      key={FULL_NAME_FIELD}
                      name={FULL_NAME_FIELD}
                    />
                  </FieldWrapper>
                </>
              )}
              <FieldWrapper>
                <InputField
                  inputSize='md'
                  label='NEW PASSWORD'
                  type='password'
                  key={PASSWORD_FIELD}
                  name={PASSWORD_FIELD}
                />
              </FieldWrapper>
              <FieldWrapper>
                <InputField
                  inputSize='md'
                  label='CONFIRM NEW PASSWORD'
                  type='password'
                  key={PASSWORD_CONFIRM_FIELD}
                  name={PASSWORD_CONFIRM_FIELD}
                />
              </FieldWrapper>
            </InputBlock>
            <ButtonsContainer>
              <ActionButton type='submit' size='lg' fullWidth disabled={!props.dirty && props.isValid}>
                Update Password
              </ActionButton>
            </ButtonsContainer>
          </StyledForm>
        )}
      </Formik>
    </LoginLayout>
  )
}

export default RequireNewPasswordPage
