import React from 'react'
import { useSelector } from 'react-redux'

import { selectModal } from '@/redux/selectors/common/ui'

import ActionFormModal from './ActionFormModal'

import { ModalType } from './types'

const Modals: React.FC = () => {
  const modal = useSelector(selectModal)

  if (modal?.type === ModalType.Action) {
    return <ActionFormModal />
  }

  return null
}

export default Modals
