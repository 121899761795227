import { useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useAlert } from 'react-alert'
import qs from 'query-string'

import { SIGNIN_URL } from '@/constants/routes'
import { PASSWORD_FIELD } from '@/constants/forms/signIn'

export interface SignUpConfirmPageFormValues {
  [PASSWORD_FIELD]: string
}

const ERRORS: Record<string, string> = {
  'User cannot be confirmed. Current status is CONFIRMED': 'User already verified.'
}

const SignUpConfirmPage = () => {
  const alert = useAlert()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { username, code } = useMemo(() => qs.parse(search), [search])

  useEffect(() => {
    Auth.confirmSignUp(username as string, code as string)
      .then(() => {
        navigate(SIGNIN_URL)
        alert.show('Account verified. Sign in to continue.')
      })
      .catch((e) => {
        navigate(SIGNIN_URL)
        alert.show(ERRORS[e.message] || e.message)
      })
  }, [username, code, navigate, alert])

  return null
}

export default SignUpConfirmPage
