import config from './config'

export const getImageName = (
    id?: string,
    extension?: string,
    size?: { width: number; height: number },
    pathWithoutSize?: boolean
) => {
    if (!id || !extension || !size) {
        return undefined
    }
    if (pathWithoutSize) {
        return `${id}.${extension}`
    }
    return `${id}_${size.width}x${size.height}.${extension}`
}
export const getImageUrl = (
    id?: string,
    path?: string,
    extension?: string,
    size?: { width: number; height: number },
    pathWithoutSize?: boolean
) => {
    if (!id || !path || !extension || !size) {
        return undefined
    }
    const url = `${config.REACT_APP_DOMAIN_URL}/media`
    return `${url}${path}/${getImageName(id, extension, size, pathWithoutSize)}`
}

const toDataURL = (url: string) =>
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))

export const downloadImage = async (url: string, name: string) => {
    const a = document.createElement('a')
    a.href = await toDataURL(url)
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}
