import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { HOME_URL } from '@/constants/routes'
import { MENU_CONFIG_BY_PATH, ModelMenuConfig } from '@/helpers/model/menu'

import { useAppDispatch } from '@/redux/store'
import { PageConfig, resetPagesAction, setExpandPageAction, setOpenPageAction } from '@/redux/actions/common/ui'
import { clearTableAction } from '@/redux/actions/table'
import { clearFormAction } from '@/redux/actions/form'
import { selectPages } from '@/redux/selectors/common/ui'

export const COLLAPSED_CARD_WIDTH = 38
export const CARDS_GAP = 8

export type ConfigByPathname = {
    id?: string
    path: { part: string; model: string; form?: string }
    modelKey: string
    type: 'form' | 'table'
    part: ModelMenuConfig
    model: ModelMenuConfig
}
export const getPageParamsByPathname = (pathname: string) => {
    const [, partPathname, modelKey, id] = pathname.split('/')
    const partPath = `/${partPathname}`
    const modelPath = `/${partPathname}/${modelKey}`
    const formPath = id ? `/${partPathname}/${modelKey}` : undefined
    return {
        id,
        modelKey,
        path: { part: partPath, model: modelPath, form: formPath }
    }
}
export const getConfigByPathname = (pathname: string): ConfigByPathname => {
    const { id, modelKey, path } = getPageParamsByPathname(pathname)
    return {
        id,
        modelKey,
        path,
        type: id ? 'form' : 'table',
        part: MENU_CONFIG_BY_PATH[path.part],
        model: MENU_CONFIG_BY_PATH[path.model]
    }
}

export type CardType = 'form' | 'table' | 'index'
export type ActivePage = {
    page: PageConfig
    active: boolean
    width: string
}
export const useCardsHelper = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const pages = useSelector(selectPages)

    const modelKey = useMemo(() => {
        if (!pages[0]) {
            return null
        }
        const config = getConfigByPathname(pages[0]?.path)
        return config.modelKey
    }, [pages])
    const { pathname, hash } = useMemo(() => location, [location])
    const activeCards = useMemo(() => {
        if (pages.length === 1) {
            return [{ page: pages[0], active: true, width: '100%' }]
        }
        const expandedPageIndex = pages.findIndex((page) => page.expanded)
        const rightPageIndex = pages.findIndex((page) => page.type !== 'table' && hash.replace('#', '') === page.path)
        const leftPageIndex = rightPageIndex - 1
        const leftCardsBorder = leftPageIndex - 3 >= 0 ? leftPageIndex - 3 : 0
        const rightCardsBorder = rightPageIndex + 3 <= pages.length - 1 ? rightPageIndex + 3 : pages.length - 1
        const count = rightCardsBorder - leftCardsBorder
        const cards = pages.slice(leftCardsBorder, rightCardsBorder + 1)
        if (expandedPageIndex !== -1) {
            return cards.map((page) => {
                const index = pages.findIndex(({ path: pagePath }) => pagePath === page.path)
                const active = index === expandedPageIndex
                const width = active
                    ? `calc(100% - ${(count - 1 || 1) * (COLLAPSED_CARD_WIDTH + CARDS_GAP)}px)`
                    : `${COLLAPSED_CARD_WIDTH}px`
                return { page, active, width }
            })
        }
        return cards.map((page) => {
            const index = pages.findIndex(({ path: pagePath }) => pagePath === page.path)
            const active = index === rightPageIndex || index === leftPageIndex
            const defaultWidth =
                count >= 2 ? `calc(50% - ${((count - 2) * (COLLAPSED_CARD_WIDTH + CARDS_GAP)) / 2}px)` : '50%'
            const width = active ? defaultWidth : `${COLLAPSED_CARD_WIDTH}px`
            return { page, active, width }
        })
    }, [hash, pages])

    const handleOpenTable = useCallback(
        (path: string) => {
            if (pages && pages[0] && pages[0].path === path) {
                return
            }
            const config = getConfigByPathname(path)
            navigate(path, { replace: true })
            dispatch(clearTableAction(config.modelKey))
            dispatch(resetPagesAction([{ type: 'table', path: config.path.model, expanded: false }]))
        },
        [pages, navigate, dispatch]
    )
    const handleOpenIndex = useCallback(
        (path: string, activePagePath?: string) => {
            navigate({ pathname, hash: path })
            dispatch(setOpenPageAction({ type: 'index', path, expanded: false, afterPage: activePagePath }))
        },
        [pathname, navigate, dispatch]
    )
    const handleOpenForm = useCallback(
        (path: string, expanded = false, activePagePath?: string) => {
            navigate({ pathname, hash: path })
            dispatch(setOpenPageAction({ type: 'form', path, expanded, afterPage: activePagePath }))
        },
        [pathname, navigate, dispatch]
    )
    const handleExpandePage = useCallback(
        (path: string, expanded: boolean) => () => dispatch(setExpandPageAction({ path, expanded })),
        [dispatch]
    )
    const handleOpenCard = useCallback(
        (path: string, type: CardType) => {
            const expandedPageIndex = pages.findIndex((page) => page.expanded)
            if (expandedPageIndex !== -1) {
                handleExpandePage(pages[expandedPageIndex].path, !pages[expandedPageIndex].expanded)()
            }
            if (type === 'table') {
                navigate({ pathname, hash: pages[1].path }, { replace: true })
            } else {
                navigate({ pathname, hash: path }, { replace: true })
            }
        },
        [pathname, pages, navigate, handleExpandePage]
    )
    const handleClose = useCallback(
        (path: string) => {
            const pageIndex = pages.findIndex(({ path: pagePath }) => pagePath === path)
            const page = pages[pageIndex]
            const config = getConfigByPathname(page.path)
            const isActive = activeCards.findIndex((card) => card.page.path === path && card.active) !== -1
            if (isActive) {
                const nextActivePage = pageIndex < pages.length - 1 ? pages[pageIndex + 1] : pages[pageIndex - 1]
                if (nextActivePage.path === pathname) {
                    navigate(pathname, { replace: true })
                } else {
                    navigate({ pathname, hash: nextActivePage.path }, { replace: true })
                }
            }
            if (page.type === 'form') {
                dispatch(clearFormAction(config.id!))
            }
            if (page.type === 'index') {
                dispatch(clearTableAction(config.modelKey))
            }
            dispatch(resetPagesAction(pages.filter(({ path: pagePath }) => pagePath !== path)))
        },
        [pathname, activeCards, pages, navigate, dispatch]
    )
    const handleCloseFormsById = useCallback(
        (ids: string[]) => {
            const newPages = pages.filter((page) => {
                const { id } = getPageParamsByPathname(page.path)
                return !ids.includes(id)
            })
            if (newPages.length > 1) {
                navigate({ pathname, hash: newPages[1].path }, { replace: true })
            } else {
                navigate(pathname, { replace: true })
            }
            dispatch(resetPagesAction(newPages))
        },
        [pathname, pages, navigate, dispatch]
    )
    const handleCloseOther = useCallback(
        (path: string, type: 'form' | 'table') => {
            if (type === 'table') {
                dispatch(resetPagesAction(pages.filter((page) => page.type === 'table')))
            }
            dispatch(resetPagesAction(pages.filter((page) => page.type === 'table' || page.path === path)))
        },
        [pages, dispatch]
    )

    useEffect(() => {
        if (pathname !== HOME_URL && pages.length === 0) {
            handleOpenTable(pathname)
        }
        if (hash && pages.length === 0) {
            handleOpenForm(hash.replace('#', ''))
        }
    }, [pathname, hash, pages, navigate, handleOpenTable, handleOpenForm])

    return {
        location,
        activeCards,
        modelKey,
        onOpenCard: handleOpenCard,
        onOpenTable: handleOpenTable,
        onOpenIndex: handleOpenIndex,
        onOpenForm: handleOpenForm,
        onExpand: handleExpandePage,
        onClose: handleClose,
        onCloseFormsById: handleCloseFormsById,
        onCloseOther: handleCloseOther
    }
}
