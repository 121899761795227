/* eslint-disable max-len */
import React from 'react'

function Email(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M13.333 6.667v4.166a2.5 2.5 0 105 0V10a8.333 8.333 0 10-3.266 6.617M13.333 10a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Email)
