import { createReducer } from '@reduxjs/toolkit'

import { ClientKey, requestClientAction, setClientAction } from '../../actions/common/client'

export interface ClientState {
    loading: boolean
    clientKey: ClientKey | null
}

const initialState = {
    loading: false,
    clientKey: null
} as ClientState

export const clientReducer = createReducer(initialState, (builder) => {
    builder.addCase(requestClientAction.pending, (state) => {
        state.loading = true
    })
    builder.addCase(requestClientAction.fulfilled, (state, { payload }) => {
        state.loading = false
        state.clientKey = payload
    })
    builder.addCase(requestClientAction.rejected, (state) => {
        state.loading = false
    })

    builder.addCase(setClientAction, (state, { payload }) => {
        state.clientKey = payload
    })
})
