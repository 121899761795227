import { createReducer } from '@reduxjs/toolkit'

import { insertPage, PaginationType } from '@/hooks/usePagination'

import { clearSearchAction, requestSearchDataAction, setQuerySearchAction } from '../actions/search'

export type SearchState = {
    called: boolean
    loading: boolean
    data: any[]
    query: string
} & PaginationType

const initialState = {
    called: false,
    loading: false,
    pageIndex: 0,
    nextTokens: [null],
    data: [],
    query: ''
} as SearchState

export const searchReducer = createReducer(initialState, (builder) => {
    builder.addCase(requestSearchDataAction.pending, (state) => {
        state.called = true
        state.loading = true
    })
    builder.addCase(requestSearchDataAction.fulfilled, (state, { payload: { items, nextToken, pageIndex } }) => {
        state.loading = false
        state.data = items
        state.pageIndex = pageIndex

        if (state.nextTokens[pageIndex + 1] === undefined) {
            state.nextTokens = insertPage(state.nextTokens, pageIndex + 1, nextToken)
        }
    })
    builder.addCase(requestSearchDataAction.rejected, (state) => {
        state.loading = false
    })

    builder.addCase(setQuerySearchAction, (state, { payload }) => {
        state.query = payload
        state.pageIndex = initialState.pageIndex
        state.nextTokens = initialState.nextTokens
    })
    builder.addCase(clearSearchAction, () => initialState)
})
