import * as yup from 'yup'

import { PASSWORD_FIELD, PASSWORD_CONFIRM_FIELD } from '@/constants/forms/requireNewPassword'
import { PASSWORD_ERROR_MESSAGE, PASSWORD_REGEXP } from '@/constants/values'

export default yup.object().shape({
    [PASSWORD_FIELD]: yup
        .string()
        .required('Please enter new password')
        .min(8, 'Minimum 8 characters')
        .test('password-validation', PASSWORD_ERROR_MESSAGE, (value) => PASSWORD_REGEXP.test(value)),
    [PASSWORD_CONFIRM_FIELD]: yup
        .string()
        .required('Please enter new password')
        .min(8, 'Minimum 8 characters')
        .test('password-validation', PASSWORD_ERROR_MESSAGE, (value) => PASSWORD_REGEXP.test(value)),
    [PASSWORD_CONFIRM_FIELD]: yup.string().oneOf([yup.ref(PASSWORD_FIELD), null], 'Passwords must match')
})
