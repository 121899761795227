import React from 'react'
import { Field, FieldProps } from 'formik'

import Checkbox from '@/components/controls/Checkbox'
import { CheckboxProps } from '@/components/controls/Checkbox/types'

interface CheckboxFieldProps extends CheckboxProps {
  name: string
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<boolean>) => {
      const { isSubmitting } = form
      const onChange = () => {
        form.setFieldValue(field.name, !field.value)
      }

      return (
        <Checkbox
          {...field}
          {...props}
          disabled={isSubmitting || disabled}
          field
          selected={field.value}
          onToggle={onChange}
        />
      )
    }}
  </Field>
)

export default CheckboxField
