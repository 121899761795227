/* eslint-disable max-len */
import * as React from 'react'

function MarkerPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M4.167 11.905c-1.543.68-2.5 1.63-2.5 2.678 0 2.071 3.73 3.75 8.333 3.75 4.602 0 8.333-1.679 8.333-3.75 0-1.049-.957-1.997-2.5-2.678M15 6.667c0 3.386-3.75 5-5 7.5-1.25-2.5-5-4.114-5-7.5a5 5 0 1110 0zm-4.167 0a.833.833 0 11-1.666 0 .833.833 0 011.666 0z'
        stroke='#fff'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(MarkerPin)
