import styled from 'styled-components'
import { AlertType } from 'react-alert'

import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'

export const AlertContainer = styled.div<{ alertType?: AlertType }>`
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 10px 16px;
    ${TEXT_SIZE_STYLES.sm};
    ${TEXT_WEIGHT_STYLES.medium};
    ${({ theme }) => theme.shadows.regular.lg};
    color: ${({ theme }) => theme.colors.base.white};
    background: ${({ theme, alertType }) =>
        alertType === 'error' ? theme.colors.error['500'] : theme.colors.gray['900']};
`
