import { keyframes } from 'styled-components'
import theme from './theme'

export const loadingAnimation = keyframes`
  0% { background-position: 0% 50%;}
  50% { background-position: 50% 100%;}
  100% { background-position: 0% 50%;}
`

export const dotsAnimation = keyframes`
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: ${theme.colors.gray['700']};
    text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
  }
  60% { text-shadow: .25em 0 0 ${theme.colors.gray['700']}, .5em 0 0 rgba(0,0,0,0); }
  80%, 100% { text-shadow: .25em 0 0 ${theme.colors.gray['700']}, .5em 0 0 ${theme.colors.gray['700']}; }
`
