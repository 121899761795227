import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { HOME_URL } from '@/constants/routes'

type Props = { children: React.ReactNode; redirectRoute?: string }
const UnauthenticatedRoute: React.FC<Props> = ({ children, redirectRoute = HOME_URL }) => {
  const navigate = useNavigate()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(() => navigate(redirectRoute, { replace: true }))
  })

  return <>{children}</>
}

UnauthenticatedRoute.defaultProps = {
  redirectRoute: undefined
}

export default UnauthenticatedRoute
