import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import ImageSelect, { ImageSelectProps, ImageSelectValue } from '@/components/controls/ImageSelect'

interface ImageSelectFieldProps extends ImageSelectProps {
  name: string
}

const ImageSelectField: React.FC<ImageSelectFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<ImageSelectValue>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name) || objectPath.get(form.errors, `${field.name}.id`)
      const onChange = (value: ImageSelectValue) => {
        form.setFieldValue(field.name, value)
      }

      return <ImageSelect {...field} {...props} error={error} disabled={isSubmitting || disabled} onSelect={onChange} />
    }}
  </Field>
)

export default ImageSelectField
