import React from 'react'

import LoadingCircle from '../../icons/common/LoadingCircle'
import WhiteSpinner from '../../icons/common/WhiteSpinner'

import { SpinnerContainer } from './styles'

type Props = {
  fullPage?: boolean
  white?: boolean
}

const Spinner: React.FC<Props> = ({ fullPage, white }) => (
  <SpinnerContainer className='spinner' fullPage={fullPage}>
    {white ? <WhiteSpinner /> : <LoadingCircle />}
  </SpinnerContainer>
)

Spinner.defaultProps = {
  fullPage: false,
  white: false
}

export default Spinner
