import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import RelationSelect, { RelationSelectProps } from '@/components/controls/RelationSelect'
import { AsyncSelectValue } from '@/components/controls/AsyncSelect'

interface RelationSelectFieldProps extends RelationSelectProps {
  name: string
}

const RelationSelectField: React.FC<RelationSelectFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<AsyncSelectValue[]>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (values: AsyncSelectValue[]) => {
        form.setFieldValue(field.name, values)
      }

      return (
        <RelationSelect {...field} {...props} error={error} disabled={isSubmitting || disabled} onChange={onChange} />
      )
    }}
  </Field>
)

export default RelationSelectField
