import React, { useCallback, useMemo } from 'react'

import DropdownMenuList, { DropdownMenuOption } from '@/components/blocks/DropdownMenuList'

type Props = {
  disabled?: boolean
  onDownload: () => void
  onDelete: () => void
  onCloseMenu: () => void
}
const ImageMenu: React.FC<Props> = ({ disabled, onDownload, onDelete, onCloseMenu }) => {
  const handleDownload = useCallback(() => {
    onDownload()
    onCloseMenu()
  }, [onCloseMenu, onDownload])
  const handleDelete = useCallback(() => {
    onDelete()
    onCloseMenu()
  }, [onCloseMenu, onDelete])

  const items: DropdownMenuOption[][] = useMemo(
    () => [
      [
        { title: 'Download', onClick: handleDownload },
        ...(!disabled ? ([{ title: 'Delete', onClick: handleDelete }] as DropdownMenuOption[]) : [])
      ]
    ],
    [disabled, handleDownload, handleDelete]
  )

  return <DropdownMenuList items={items} />
}

ImageMenu.defaultProps = { disabled: false }

export default ImageMenu
