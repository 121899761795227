/* eslint-disable max-len */
import React from 'react'

function Files(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M17.627 9.083l-7.513 7.513a4.375 4.375 0 11-6.187-6.187l7.513-7.513a2.917 2.917 0 014.125 4.125l-7.219 7.218a1.458 1.458 0 01-2.062-2.063l6.334-6.334'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Files)
