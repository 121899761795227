/* eslint-disable max-len */
import React from 'react'

function Workflow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M17.044 10.744a7.083 7.083 0 01-13.179 2.798l-.208-.361m-.702-3.925a7.083 7.083 0 0113.179-2.798l.208.361M2.911 15.055l.61-2.277 2.277.61m8.404-6.776l2.277.61.61-2.277'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Workflow)
