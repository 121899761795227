import { useEffect, useState } from 'react'
import useDebounce from './useDebounce'

export const useMountRequest = (requestFunction: () => void) => {
    const [isRequested, setRequested] = useState(false)
    const requestFunctionDebounce = useDebounce(requestFunction, 50)

    useEffect(() => {
        if (!isRequested) {
            requestFunctionDebounce()
            setRequested((value) => !value)
        }
    }, [isRequested, requestFunctionDebounce])
}
