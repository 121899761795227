/* eslint-disable max-len */
import React from 'react'

function RelationTo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M12.5 12.5v-5m0 0h-5m5 0l-5 5M18.333 10a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(RelationTo)
