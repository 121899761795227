import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Card, CardConfig, CardTitle } from '@/components/regions/Card'
import CollapsedCard from '@/components/regions/CollapsedCard'
import Spinner from '@/components/controls/Spinner'
import Table from '@/components/controls/Table'
import Pagination from '@/components/controls/Pagination'
import { Display } from '@/components/typography/Display'

import { TABLE_COLUMNS_BY_MODEL_KEY } from '@/helpers/model/table/columns'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useMountRequest } from '@/hooks/useMountRequest'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { requestTableDataAction, setSelectedRowTableAction } from '@/redux/actions/table'
import {
  selectColumnsByModel,
  selectItemsCount,
  selectSelectedRows,
  selectTable,
  selectTablePagination
} from '@/redux/selectors/table'

import TableCardHeader from './components/TableCardHeader'
import SearchCard from '../SearchCard'

export type TableCardProps = { page: PageConfig; card: CardConfig }
const TableCard: React.FC<TableCardProps> = ({ page, card }) => {
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm } = useCardsHelper()
  const { modelKey, model, path } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const columns = useMemo(() => TABLE_COLUMNS_BY_MODEL_KEY[modelKey], [modelKey])

  const [activeSearch, setActiveSearch] = useState(false)

  const visibleColumns = useSelector(selectColumnsByModel(modelKey))
  const selectedRows = useSelector(selectSelectedRows(modelKey))
  const pagination = useSelector(selectTablePagination(modelKey))
  const { count, startTableCount } = useSelector(selectItemsCount(modelKey))
  const { data, loading, called } = useSelector(selectTable(modelKey))

  const handleRequestData = useCallback(
    (pageIndex = pagination.pageIndex) => {
      if (pageIndex !== pagination.pageIndex) {
        dispatch(setSelectedRowTableAction({ modelKey, ids: [] }))
      }
      dispatch(requestTableDataAction({ modelKey, pageIndex }))
    },
    [modelKey, pagination.pageIndex, dispatch]
  )
  const handleSelectRow = useCallback(
    (value: string | string[]) => dispatch(setSelectedRowTableAction({ modelKey, ids: value })),
    [modelKey, dispatch]
  )
  const handleOpenRow = useCallback(
    (row: any, expanded?: boolean) =>
      onOpenForm(`${path.model}/${row.id}`, expanded, page.type === 'index' ? page.path : undefined),

    [page, path, onOpenForm]
  )
  const handleOpenSearch = useCallback(() => setActiveSearch(true), [])
  const handleCloseSearch = useCallback(() => setActiveSearch(false), [])

  useMountRequest(() => {
    if (!called && !loading) {
      handleRequestData()
    }
  })

  if (!card.active) {
    return <CollapsedCard title={model.title} page={page} />
  }

  if (activeSearch) {
    return <SearchCard page={page} onClose={handleCloseSearch} />
  }

  return (
    <Card style={{ width: card.width }}>
      <TableCardHeader page={page} card={card} onOpenSearch={handleOpenSearch} onRequestData={handleRequestData} />
      <CardTitle>
        <Display size='sm'>{model.title}</Display>
        {data.length > 0 && (
          <Display size='sm' className='second'>
            ({count})
          </Display>
        )}
      </CardTitle>
      <Pagination headerOffsets {...pagination} onChange={handleRequestData} />
      {loading ? (
        <Spinner />
      ) : (
        <Table
          modelMenuConfig={model}
          startCount={startTableCount}
          selectable={hasWriteAccess}
          columns={columns}
          visibleColumns={visibleColumns || {}}
          selectedRows={selectedRows}
          data={data}
          onSelectRow={handleSelectRow}
          onOpenRow={handleOpenRow}
        />
      )}
    </Card>
  )
}

export default TableCard
