import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Chips from '@/components/blocks/Chips'
import Tooltip from '@/components/blocks/Tooltip'
import Button from '@/components/controls/Button'
import Pagination from '@/components/controls/Pagination'
import Spinner from '@/components/controls/Spinner'
import { Text } from '@/components/typography/Text'
import Expand from '@/components/icons/common/Expand'
import LayoutRight from '@/components/icons/common/LayoutRight'
import Entity from '@/components/icons/search/Entity'
import ArrowLeft from '@/components/icons/search/ArrowLeft'

import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import useDebounce from '@/hooks/useDebounce'

import { useAppDispatch } from '@/redux/store'
import { selectQuerySearch, selectSearch, selectSearchPagination } from '@/redux/selectors/search'
import { clearSearchAction, requestSearchDataAction, setQuerySearchAction } from '@/redux/actions/search'
import { PageConfig } from '@/redux/actions/common/ui'

import { InputContainer, PaginationContainer, ResultRow, Results, SearchContainer, SearchInput } from './styles'

type Props = { page: PageConfig; onClose: () => void }
const SearchCard: React.FC<Props> = ({ page, onClose }) => {
  const dispatch = useAppDispatch()
  const { onOpenForm } = useCardsHelper()
  const { modelKey, ...config } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const titleConfig = useMemo(() => TITLE_GETTER_BY_MODEL_KEY[modelKey!], [modelKey])

  const { loading, called, data } = useSelector(selectSearch)
  const pagination = useSelector(selectSearchPagination)
  const query = useSelector(selectQuerySearch)

  const handleRequestData = useCallback(
    (pageIndex = 0) => dispatch(requestSearchDataAction({ modelKey, pageIndex })),
    [modelKey, dispatch]
  )
  const handleRequestDataDebounce = useDebounce(handleRequestData, 500)
  const handleChangeQuery = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setQuerySearchAction(e.target.value || ''))
      handleRequestDataDebounce(pagination.pageIndex)
    },
    [pagination.pageIndex, handleRequestDataDebounce, dispatch]
  )
  const handleOpen = useCallback(
    (id: string, expanded = false) =>
      () =>
        onOpenForm(`${config.path.model}/${id}`, expanded),
    [config, onOpenForm]
  )

  useEffect(() => {
    dispatch(clearSearchAction())
  }, [dispatch])

  const renderContent = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    if (called && data.length === 0) {
      return (
        <ResultRow>
          <Text size='sm' className='info'>
            Nothing found
          </Text>
        </ResultRow>
      )
    }
    return data.map((row) => {
      const title = titleConfig.getValue(row[titleConfig.key])
      return (
        <ResultRow key={row.id}>
          <Entity />
          <Text size='sm'>{title}</Text>
          <div className='controls'>
            <Tooltip position='bottom' arrowPosition='right' description='Expand'>
              <Button size='xs' icon='icon' styleType='secondary' variant='gray' onClick={handleOpen(row.id, true)}>
                <Expand />
              </Button>
            </Tooltip>
            <Tooltip position='bottom' arrowPosition='right' description='Open a new panel on the right'>
              <Button size='xs' icon='icon' styleType='secondary' variant='gray' onClick={handleOpen(row.id, false)}>
                <LayoutRight />
              </Button>
            </Tooltip>
          </div>
        </ResultRow>
      )
    })
  }, [loading, called, data, titleConfig, handleOpen])

  return (
    <SearchContainer>
      <InputContainer>
        <Button size='sm' icon='icon' styleType='tertiary' variant='gray' onClick={onClose}>
          <ArrowLeft />
        </Button>
        <SearchInput type='text' placeholder='Type to start search' value={query} onChange={handleChangeQuery} />
      </InputContainer>
      <PaginationContainer>
        <Chips.Space part={config.part} model={config.model} />
        <Pagination {...pagination} onChange={handleRequestData} />
      </PaginationContainer>
      <Results>{renderContent()}</Results>
    </SearchContainer>
  )
}

export default SearchCard
