import React, { useCallback, useMemo } from 'react'
import format from 'date-fns-tz/format'

import { Text } from '@/components/typography/Text'
import Button from '@/components/controls/Button'
import Checkbox from '@/components/controls/Checkbox'
import { Badge } from '@/components/blocks/Badge'
import { BadgeProps } from '@/components/blocks/Badge/types'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'

import ImageCell from '../ImageCell'

type StringProps = { value: string }
const String: React.FC<StringProps> = ({ value }) => (
  <Text size='xs' title={value}>
    {value}
  </Text>
)

type LinkProps = { value: string }
const Link: React.FC<LinkProps> = ({ value }) => {
  const handleOpenLink = useCallback(() => window && window.open(value, '_blank'), [value])
  return (
    <Button size='sm' styleType='link' onClick={handleOpenLink}>
      <Text size='xs' title={value}>
        {value}
      </Text>
    </Button>
  )
}

type BooleanProps = { value: boolean }
const Boolean: React.FC<BooleanProps> = ({ value }) => <Checkbox size='md' selected={value} />

type RelationProps = { options: { value: string; color?: string }[] }
const DEFAULT_BADGE_PROPS = { styleType: 'light' } as Partial<BadgeProps>
const Relation: React.FC<RelationProps> = ({ options }) => (
  <>
    {options.map(({ value, color }) => (
      <Badge key={value} background={color} {...(!color ? DEFAULT_BADGE_PROPS : {})}>
        <Text size='xs'>{value}</Text>
      </Badge>
    ))}
  </>
)

export type DataCellType = 'string' | 'link' | 'boolean' | 'image' | 'relation' | 'timestamp'
export type DataCellColors = Record<string, string>
export type DataCellValues = {
  type?: DataCellType
  modelKey?: string
  relationFormat?: 'enum' | 'type'
  colors?: DataCellColors
  getUrl?: (id: string) => string | undefined
}
type DataCellProps = DataCellValues & { row: any; selector: string }

const DataCell: React.FC<DataCellProps> = ({ selector, row, type, modelKey, relationFormat, colors = {}, getUrl }) => {
  const value = useMemo(() => row[selector], [row, selector])

  if (type === 'string') {
    return <String value={value} />
  }
  if (type === 'timestamp') {
    let dateString = ''

    if (value && value.toString().length === 10) {
      dateString = format(new Date(value * 1000), 'dd MMM yyyy, HH:mm:ss (z)')
    }
    if (value && value.toString().length === 13) {
      dateString = format(new Date(value), 'dd MMM yyyy, HH:mm:ss (z)')
    }
    return <String value={dateString} />
  }
  if (type === 'image') {
    return <ImageCell value={value} getUrl={getUrl} />
  }
  if (type === 'link') {
    return <Link value={value} />
  }
  if (type === 'boolean') {
    return <Boolean value={value} />
  }
  if (type === 'relation') {
    if (!value) {
      return <Relation options={[]} />
    }
    const isArray = Array.isArray(value)
    if (relationFormat === 'enum') {
      const options = isArray
        ? value.map((item) => ({ value: item, color: colors[item] }))
        : [{ value, color: colors[value] }]
      return <Relation options={options} />
    }
    if (relationFormat === 'type') {
      const { key, getValue } = TITLE_GETTER_BY_MODEL_KEY[modelKey!]
      const options = isArray
        ? value.map((item) => ({ value: getValue(item[key]) }))
        : [{ value: getValue(value[key]) }]
      return <Relation options={options} />
    }
    return null
  }

  return null
}

DataCell.defaultProps = {
  type: undefined,
  modelKey: undefined,
  relationFormat: undefined,
  getUrl: undefined,
  colors: {}
}

export default React.memo(DataCell)
