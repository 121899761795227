import styled, { css } from 'styled-components'

const cellHover = css`
    .cell.hover {
        display: none;
    }
    &:hover {
        .cell.default {
            display: none;
        }
        .cell.hover {
            display: flex;
            .right.default {
                display: flex !important;
            }
            .right.row {
                display: none !important;
            }
        }
    }
`
const content = css`
    display: flex;
    align-items: center;
    gap: 2px;
    height: 100%;
`

export const CellSC = {
    THFirst: styled.th`
        padding: 0 8px;
        ${cellHover}
        z-index: 6;
        &:hover {
            z-index: 7 !important;
        }
    `,
    TH: styled.th`
        padding: 0 6px;
        z-index: 5;
    `,
    TDFirst: styled.td`
        padding: 0 8px;
        ${cellHover}
        z-index: 3;
        &:hover {
            z-index: 4 !important;
        }
    `,
    TD: styled.td`
        padding: 0 8px;
        z-index: 1;

        &:hover {
            z-index: 2 !important;
        }
    `
}
export const Content = {
    THFirst: styled.div`
        ${content}
        justify-content: flex-end;
    `,
    TH: styled.div`
        ${content}
        gap: 3px;
    `,
    TDFirst: styled.div`
        ${content}
        justify-content: flex-end;
        gap: 6px;
        color: ${({ theme }) => theme.colors.gray['400']};

        .right {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: auto;
        }
    `,
    TD: styled.div`
        ${content}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .text {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    `
}
