declare global {
    interface Window {
        __APP_CONFIG__: string
        __APP_MODEL__: string
        Intercom: (operation: string, params?: any) => void
        intercomSettings: {
            app_id: string
            api_base: string
        }
    }
}

// REACT_APP_ prefix is needed for react scripts
// https://create-react-app.dev/docs/adding-custom-environment-variables/
interface AppConfig {
    REACT_APP_DOMAIN_URL: string
    REACT_APP_MEDIA_UPLOAD_URL: string
    REACT_APP_SUBSCRIPTION_GRAPHQL_URL: string
    REACT_APP_GRAPHQL_REGION: string
    REACT_APP_GRAPHQL_API_KEY: string

    REACT_APP_COGNITO_REGION: string
    REACT_APP_COGNITO_USER_POOL_ID: string
    REACT_APP_COGNITO_IDENTITY_POOL_ID: string
    REACT_APP_COGNITO_APP_CLIENT_ID: string

    REACT_APP_INTERCOM_APP_ID: string
}

const refineConfigProp = (conf: AppConfig, prop: keyof AppConfig, placeholder: string) => {
    if (!conf[prop]) {
        conf[prop] = process.env[prop] || ''
    } else {
        conf[prop] = conf[prop].replace(`\${${placeholder}}`, process.env[prop] || '')
    }
}

export const APP_CONFIG: string = typeof window !== 'undefined' ? window?.__APP_CONFIG__ || '{}' : '{}'
const config: AppConfig = JSON.parse(APP_CONFIG)
refineConfigProp(config, 'REACT_APP_DOMAIN_URL', 'DOMAIN_URL')
refineConfigProp(config, 'REACT_APP_MEDIA_UPLOAD_URL', 'MEDIA_UPLOAD_URL')
refineConfigProp(config, 'REACT_APP_SUBSCRIPTION_GRAPHQL_URL', 'SUBSCRIPTION_GRAPHQL_URL')
refineConfigProp(config, 'REACT_APP_GRAPHQL_REGION', 'GRAPHQL_REGION')
refineConfigProp(config, 'REACT_APP_GRAPHQL_API_KEY', 'GRAPHQL_API_KEY')

refineConfigProp(config, 'REACT_APP_COGNITO_REGION', 'COGNITO_REGION')
refineConfigProp(config, 'REACT_APP_COGNITO_USER_POOL_ID', 'COGNITO_USER_POOL_ID')
refineConfigProp(config, 'REACT_APP_COGNITO_IDENTITY_POOL_ID', 'COGNITO_IDENTITY_POOL_ID')
refineConfigProp(config, 'REACT_APP_COGNITO_APP_CLIENT_ID', 'COGNITO_APP_CLIENT_ID')

refineConfigProp(config, 'REACT_APP_INTERCOM_APP_ID', 'INTERCOM_APP_ID')

export default config
