import { gqlListItems } from '@/client/graphql'
import { ModelType } from '@/client/schema'
import { Client } from '@/redux/actions/common/client'
import { schema } from '../schema'

type Response = { items: any[]; nextToken: string | null }
export const requestTableData = async (
    modelKey: string,
    client: Client,
    variables: Record<string, any>,
    index?: string
) => {
    const query = gqlListItems(schema.models[modelKey] as ModelType, 'REFS', index)
    const response = await client?.query<{ [key: string]: Response }>({
        fetchPolicy: 'no-cache',
        query,
        variables: { input: variables }
    })
    const { items, nextToken } = response!.data[Object.keys(response!.data)[0]]
    return { items, nextToken }
}
