import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApolloProvider } from '@apollo/client'

import Spinner from '@/components/controls/Spinner'

import { requestClientAction } from '@/redux/actions/common/client'
import { selectClientData } from '@/redux/selectors/common/client'
import { useAppDispatch } from '@/redux/store'

export const ApolloProviderWithClient: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const clientState = useSelector(selectClientData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (clientState.client || clientState.loading) {
      return
    }
    dispatch(requestClientAction())
  }, [dispatch, clientState])

  if (!clientState.client || clientState.loading) {
    return <Spinner fullPage />
  }

  return <ApolloProvider client={clientState.client}>{children}</ApolloProvider>
}
