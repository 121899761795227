import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

export const SpinnerContainer = styled.div<{ fullPage?: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ fullPage }) => fullPage && 'height: 100vh;'}

    svg {
        animation: ${rotate} 700ms infinite linear;
    }
`
