import styled from 'styled-components'
import { Form } from 'formik'

import { Text } from '@/components/typography/Text'
import { Display } from '@/components/typography/Display'
import Button from '../../controls/Button'

interface TitleProps {
    center?: boolean
}
interface SuccessMessageWrapperProps {
    center?: boolean
}
interface ButtonsContainerProps {
    centerText?: boolean
    margin?: string
}

export const StyledLoginLayout = styled.div`
    position: relative;
    max-width: 360px;
    width: 360px;
    margin: auto;
    padding: 32px;
    border-radius: 18px;
    background: #ffffff;
`

export const BackButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 32px;
    left: -18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    background: #ffffff;
    border-radius: 18px;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
`

export const Title = styled(Display).attrs(() => ({ size: 'xs' }))<TitleProps>`
    color: ${({ theme }) => theme.colors.base.black};
    ${({ center = true }) => center && 'text-align: center;'}
`

export const StyledForm = styled(Form)`
    margin-top: 32px;
`

export const InputBlock = styled.div`
    margin-bottom: 10px;
`

export const FormError = styled.div`
    background: #fed7d2;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 10px;
`

export const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 10px;
    }
`

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
    margin-top: ${({ margin }) => margin || '40px'};
    ${({ centerText }) => centerText && 'text-align: center;'}
`

export const ActionButton = styled(Button)`
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
`

export const SuccessMessageWrapper = styled.div<SuccessMessageWrapperProps>`
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 8px;

    ${({ center }) =>
        center &&
        `
        p {
            text-align: center;
        }
    `}
`

export const Info = styled(Text).attrs(() => ({ size: 'sm' }))<{ margin?: string }>`
    display: inline;
    color: ${({ theme }) => theme.colors.gray['700']};
    ${({ margin }) =>
        margin &&
        `
        display: block;
        margin: ${margin};
    `}
`

export const LinkButton = styled(Info)`
    cursor: pointer;
    color: #276ef1;
    transition: color 100ms linear;

    &:hover {
        color: #e11900;
    }
`
