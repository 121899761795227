import React from 'react'

import { Text } from '@/components/typography/Text'

import { ImageContainer } from './styles'

type ImageCellProps = { value: string | string[] | undefined; getUrl?: (id: string) => string | undefined }
const ImageCell: React.FC<ImageCellProps> = ({ value, getUrl }) => {
  if (!value) {
    return null
  }
  if (!getUrl) {
    return <Text size='xs'>{value}</Text>
  }
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((item) => (
          <ImageContainer key={item}>
            <img className='image' loading='lazy' src={getUrl(item)} alt='' />
            <img className='popup' loading='lazy' src={getUrl(item)} alt='' />
          </ImageContainer>
        ))}
      </>
    )
  }
  return (
    <ImageContainer>
      <img className='image' loading='lazy' src={getUrl(value)} alt='' />
      <img className='popup' loading='lazy' src={getUrl(value)} alt='' />
    </ImageContainer>
  )
}

ImageCell.defaultProps = { getUrl: undefined }

export default ImageCell
