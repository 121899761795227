import { StylesConfig } from '@/components/controls/Button/types'
import { hexToRgb } from '@/helpers/hexToRgb'
import theme from '../theme'

export const DARK_GRAY_STYLE_TYPE_COLORS: StylesConfig = {
    primary: {
        original: {
            background: theme.colors.gray[900],
            border: theme.colors.gray[900],
            color: theme.colors.base.white
        },
        hover: {
            background: theme.colors.base.black,
            border: theme.colors.base.black
        },
        disabled: {
            background: theme.colors.gray[200],
            border: theme.colors.gray[200]
        }
    },
    secondary: {
        original: {
            background: theme.colors.base.white,
            border: theme.colors.gray[200],
            color: theme.colors.gray[500]
        },
        hover: {
            border: theme.colors.gray[500]
        },
        disabled: {
            border: theme.colors.gray[50],
            color: theme.colors.gray[300]
        }
    },
    tertiary: {
        original: { color: theme.colors.gray[500] },
        hover: {
            background: hexToRgb(theme.colors.base.black, 0.1),
            border: hexToRgb(theme.colors.base.black, 0.1)
        },
        disabled: { color: theme.colors.gray[300] }
    },
    link: {
        original: { color: theme.colors.gray[500] },
        hover: { color: theme.colors.gray[700] },
        disabled: { color: theme.colors.gray[300] }
    }
}
