import { createSelector } from '@reduxjs/toolkit'

import { cognitoClient } from '@/graphql/cognitoClient'
import { iamClient } from '@/graphql/iamClient'
// import { keyClient } from '@/graphql/keyClient'
import { ClientKey } from '../../actions/common/client'
import { ClientState } from '../../reducers/common/client'

import { RootState } from '../../store'
import { selectSelf } from '..'

const getClientByKey = (key: ClientKey | null) => {
    if (key === 'cognito') {
        return cognitoClient
    }
    if (key === 'iam') {
        return iamClient
    }
    return null
}

export const selectClientState = createSelector(selectSelf, (state: RootState) => state.client)
export const selectClientData = createSelector(selectSelf, (state: RootState) => {
    const { loading, clientKey } = state.client
    return { loading, client: getClientByKey(clientKey) }
})
export const selectClient = createSelector(selectClientState, (state: ClientState) => getClientByKey(state.clientKey))
