/* eslint-disable max-len */
import * as React from 'react'

function Entity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill='none' {...props}>
      <path
        className='stroke'
        d='M9.333 1.513v2.754c0 .373 0 .56.073.702a.667.667 0 00.291.292c.143.072.33.072.703.072h2.754m-2.487 3.334H5.333m5.334 2.666H5.333M6.667 6H5.333m4-4.667H5.867c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874c-.218.428-.218.988-.218 2.108v6.934c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874c.428.218.988.218 2.108.218h4.266c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874c.218-.428.218-.988.218-2.108V5.333l-4-4z'
        stroke='#98A2B3'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Entity)
