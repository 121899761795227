import { StylesConfig } from '@/components/controls/Menu/components/MenuItem/types'
import theme from '../theme'

export const MENU_ACTIVE_STYLE_TYPE_COLORS: StylesConfig = {
    primary: {
        original: {
            background: theme.colors.primary[500],
            color: theme.colors.base.white
        },
        hover: { background: theme.colors.primary[600] },
        disabled: {}
    },
    violet: {
        original: {
            background: theme.colors.violet[500],
            color: theme.colors.base.white
        },
        hover: { background: theme.colors.violet[600] },
        disabled: {}
    },
    moss: {
        original: {
            background: theme.colors.moss[500],
            color: theme.colors.base.white
        },
        hover: { background: theme.colors.moss[600] },
        disabled: {}
    },
    cyan: {
        original: {
            background: theme.colors.cyan[500],
            color: theme.colors.base.white
        },
        hover: { background: theme.colors.cyan[600] },
        disabled: {}
    }
}
