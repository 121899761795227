import React from 'react'
import { Field, FieldProps } from 'formik'

import { DatetimeProps } from '@/components/controls/Datetime'
import { Placeholder } from '@/components/blocks/Placeholder'

const Datetime = React.lazy(() => import(/* webpackChunkName: 'json-editor' */ '@/components/controls/Datetime'))

interface DatetimeFieldProps extends DatetimeProps {
  name: string
}

const DatetimeField: React.FC<DatetimeFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<Date | null>) => {
      const { isSubmitting } = form
      const onChange = (value: Date | null) => {
        form.setFieldValue(field.name, value)
      }

      return (
        <React.Suspense fallback={<Placeholder height='40px' />}>
          <Datetime {...field} {...props} disabled={isSubmitting || disabled} onChange={onChange} />
        </React.Suspense>
      )
    }}
  </Field>
)

export default DatetimeField
