import { loadSchemaAsText, Schema } from '../client/schema'
import { getIsServerSide } from './getIsServerSide'

const getSchemaJson = () => {
    if (getIsServerSide()) {
        return undefined
    }
    const modelBase64 = process.env.NODE_ENV === 'production' ? window.__APP_MODEL__ : process.env.REACT_APP_MODEL || ''
    return Buffer.from(modelBase64, 'base64').toString()
}

const modelJSON = getSchemaJson()
export const schema = modelJSON ? loadSchemaAsText(modelJSON) : ({ models: {} } as Schema)
