import React, { useCallback, useMemo, useState } from 'react'
import { List, arrayMove } from 'react-movable'

import SelectValue from '@/components/blocks/SelectValue'
import Plus from '@/components/icons/cardHeader/Plus'
import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import Button from '../Button'
import Select, { SelectOption } from '../Select'

import { ControlsRow, Values } from './styles'

export type EnumSelectProps = LargeFieldBlockProps & {
  options?: SelectOption[]

  disabled?: boolean
  value?: string[]

  onChange?: (values: string[]) => void
}
export const DEFAULT_VISIBLE_COUNT = 10

const EnumSelect: React.FC<EnumSelectProps> = ({
  options = [],

  disabled,
  value,

  onChange,

  ...fieldBlockProps
}) => {
  const [addMode, setAddMode] = useState(false)

  const valueOptions: SelectOption[] = useMemo(
    () => (value || []).map((item) => options.find(({ value: optionValue }) => optionValue === item)!),
    [options, value]
  )
  const selectOptions = useMemo(() => options.filter(({ value: item }) => !value?.includes(item)), [options, value])

  const handleLink = useCallback(() => {
    setAddMode(true)
  }, [])
  const handleCloseSelect = useCallback(() => {
    setAddMode(false)
  }, [])
  const handleSelect = useCallback(
    (newValue: string) => {
      onChange && onChange([...(value || []), newValue])
      setAddMode(false)
    },
    [value, onChange]
  )
  const handleDelete = useCallback(
    (deleteValue: string) => () => {
      const newValue = (value || []).filter((item) => item !== deleteValue)
      onChange && onChange(newValue)
    },
    [value, onChange]
  )

  return (
    <LargeFieldBlock {...fieldBlockProps}>
      {value && (
        <List<SelectOption>
          lockVertically
          values={valueOptions}
          onChange={({ oldIndex, newIndex }) => onChange && onChange(arrayMove(value, oldIndex, newIndex))}
          renderList={({ children, props }) => <Values {...props}>{children}</Values>}
          renderItem={({ value: option, isDragged, props: { ref, ...props } }) => (
            <SelectValue
              key={option.value}
              ref={ref}
              dndProps={{ ...props, isDragged }}
              controlsOnHover
              value={option}
              disabled={disabled}
              onClear={handleDelete(option.value)}
            />
          )}
        />
      )}
      {addMode ? (
        <Select options={selectOptions} onSelect={handleSelect} onClose={handleCloseSelect} />
      ) : (
        <ControlsRow>
          <Button
            type='button'
            size='sm'
            icon='left'
            styleType='tertiary'
            variant='gray'
            disabled={disabled || selectOptions.length === 0}
            onClick={handleLink}
          >
            <Plus />
            Add
          </Button>
        </ControlsRow>
      )}
    </LargeFieldBlock>
  )
}

EnumSelect.defaultProps = {
  options: [],

  value: undefined,
  disabled: false,

  onChange: undefined
}

export default EnumSelect
