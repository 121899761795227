export type TextSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs'
export type TextWeight = 'regular' | 'medium' | 'semibold' | 'bold'

export type TextSizeConfig = Record<TextSize, string>
export type TextWeightConfig = Record<TextWeight, string>

export const TEXT_SIZE_STYLES: TextSizeConfig = {
    xl: `
      font-size: 20px;
      line-height: 30px;
    `,
    lg: `
      font-size: 18px;
      line-height: 28px;
    `,
    md: `
      font-size: 16px;
      line-height: 24px;
    `,
    sm: `
      font-size: 14px;
      line-height: 20px;
    `,
    xs: `
      font-size: 12px;
      line-height: 18px;
    `,
    '2xs': `
      font-size: 10px;
      line-height: 12px;
    `
}

export const TEXT_WEIGHT_STYLES: TextWeightConfig = {
    regular: 'font-weight: 400;',
    medium: 'font-weight: 500;',
    semibold: 'font-weight: 600;',
    bold: 'font-weight: 700;'
}
