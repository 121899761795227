/* eslint-disable max-len */
import * as React from 'react'

function MoreDots(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M10 10.833a.833.833 0 100-1.666.833.833 0 000 1.666zM15.833 10.833a.833.833 0 100-1.666.833.833 0 000 1.666zM4.167 10.833a.833.833 0 100-1.666.833.833 0 000 1.666z'
        stroke='#667085'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(MoreDots)
