import * as React from 'react'

function ArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} fill='none' {...props}>
      <path
        className='stroke'
        d='M15.833 10H4.167m0 0L10 15.833M4.167 10L10 4.167'
        stroke='#667085'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(ArrowLeft)
