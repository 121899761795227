import { MENU_ACTIVE_STYLE_TYPE_COLORS } from '@/constants/styles/menu/active'
import { MENU_STYLE_TYPE_COLORS } from '@/constants/styles/menu/default'
import themeValues, { Theme } from '@/constants/styles/theme'
import { hexToRgb } from '@/helpers/hexToRgb'
import { getColorsStyles, getSvgColors } from '@/helpers/styles'

import { MenuItemProps } from './types'

export const getMenuItemStyles = ({
    theme,
    active = false,
    variant = 'primary'
}: Partial<MenuItemProps> & { theme: Theme }) => {
    const activeConfig = MENU_ACTIVE_STYLE_TYPE_COLORS[variant]
    const defaultConfig = MENU_STYLE_TYPE_COLORS[variant]
    const config = active ? activeConfig : defaultConfig
    const baseStyles = getColorsStyles({ theme, config })

    return `
      ${baseStyles}

      .menu-item-icon {
        .icon {
          ${getSvgColors(active ? activeConfig.original.color! : themeValues.colors.gray['400'])}
        }
        &.primary .icon {
          background: ${active ? activeConfig.original.color : activeConfig.original.background};
          ${getSvgColors(active ? activeConfig.original.background! : themeValues.colors.base.white)}
        }
      }

      &:hover .menu-item-icon.primary {
        .icon {
          opacity: 0.1;
        }
        .collapse-button {
          display: flex;
          background: ${
              active ? hexToRgb(themeValues.colors.base.white, 0.1) : hexToRgb(activeConfig.original.background!, 0.1)
          };
        }
      }
    `
}
