import { createReducer } from '@reduxjs/toolkit'

import { getObjectDiff } from '@/helpers/getObjectDiff'

import { requestFormDataAction, clearFormAction, requestDeleteEntityAction } from '../actions/form'
import { setSubscriptionDataAction } from '../actions/subscription'

export type FormState = {
    called: boolean
    loading: boolean
    data: any
}
export type FormReducerState = Record<string, FormState>
const initialFormState = {
    called: false,
    loading: false,
    data: undefined
}

const initialState = {} as FormReducerState

export const formReducer = createReducer(initialState, (builder) => {
    builder.addCase(requestFormDataAction.pending, (state, { meta }) => {
        const { id } = meta.arg
        if (!state[id]) {
            state[id] = { ...initialFormState }
        }
        state[id].called = true
        state[id].loading = true
    })
    builder.addCase(requestFormDataAction.fulfilled, (state, { payload, meta }) => {
        const { id } = meta.arg
        if (!state[id]) {
            state[id] = { ...initialFormState }
        }
        state[id].loading = false
        state[id].data = payload.data
    })
    builder.addCase(requestFormDataAction.rejected, (state, { meta }) => {
        const { id } = meta.arg
        if (!state[id]) {
            state[id] = { ...initialFormState }
        }
        state[id].loading = false
    })

    builder.addCase(requestDeleteEntityAction.fulfilled, (state, { payload }) => {
        const { id } = payload.data
        delete state[id]
    })

    builder.addCase(clearFormAction, (state, { payload }) => {
        delete state[payload]
    })

    builder.addCase(setSubscriptionDataAction, (state, { payload: { response } }) => {
        if (state[response.id]) {
            const diff = getObjectDiff(response.before || {}, response.after || {})
            diff.forEach((fieldKey: string) => {
                state[response.id].data[fieldKey] = response.after[fieldKey]
            })
        }
    })
})
