import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import AsyncSelect, { AsyncSelectProps, AsyncSelectValue } from '@/components/controls/AsyncSelect'

interface AsyncSelectFieldProps extends AsyncSelectProps {
  name: string
}

const AsyncSelectField: React.FC<AsyncSelectFieldProps> = ({ disabled, modelKey, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<AsyncSelectValue | undefined>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, `${field.name}.id`) || objectPath.get(form.errors, field.name)
      const onChange = (value: AsyncSelectValue) => {
        form.setFieldValue(field.name, value)
      }
      const onClear = () => {
        form.setFieldValue(field.name, undefined)
      }

      return (
        <AsyncSelect
          {...field}
          {...props}
          error={error}
          disabled={isSubmitting || disabled}
          modelKey={modelKey || field.name}
          onSelect={onChange}
          onClear={onClear}
        />
      )
    }}
  </Field>
)

export default AsyncSelectField
