import { CHECKBOX_ACTIVE_STYLE_TYPE_COLORS } from '@/constants/styles/checkbox/active'
import { CHECKBOX_STYLE_TYPE_COLORS } from '@/constants/styles/checkbox/default'
import { Theme } from '@/constants/styles/theme'
import { getColorsStyles } from '@/helpers/styles'

import { CheckboxProps } from './types'

export const CHECKBOX_STYLES_BY_TYPE = {
    default: {
        sm: 'border-radius: 4px;',
        md: 'border-radius: 6px;'
    },
    line: {
        sm: 'border-radius: 4px;',
        md: 'border-radius: 6px;'
    },
    circle: {
        sm: 'border-radius: 50%;',
        md: 'border-radius: 50%;'
    },
    radio: {
        sm: 'border-radius: 50%;',
        md: 'border-radius: 50%;'
    }
}
export const SVG_SIZE = {
    default: {
        sm: `
          svg {
            width: 12px;
            height: 12px;
          }
        `,
        md: `
          svg {
            width: 14px;
            height: 14px;
          }
        `
    },
    line: {
        sm: `
          svg {
            width: 12px;
            height: 12px;
          }
        `,
        md: `
          svg {
            width: 14px;
            height: 14px;
          }
        `
    },
    circle: {
        sm: `
          svg {
            width: 10px;
            height: 10px;
          }
        `,
        md: `
          svg {
            width: 14px;
            height: 14px;
          }
        `
    },
    radio: {
        sm: `
          svg {
            width: 6px;
            height: 6px;
          }
        `,
        md: `
          svg {
            width: 8px;
            height: 8px;
          }
        `
    }
}
export const CHECKBOX_STYLES_BY_SIZE = {
    sm: `
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    `,
    md: `
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    `
}

export const getCheckboxSizes = ({ type = 'default', size = 'sm' }: CheckboxProps) => `
  ${CHECKBOX_STYLES_BY_SIZE[size]}
  ${CHECKBOX_STYLES_BY_TYPE[type][size]}
  ${SVG_SIZE[type][size]}
`

export const getCheckboxStyles = ({
    theme,
    type = 'default',
    styleType = 'primary',
    selected = false
}: CheckboxProps & { theme: Theme }) => {
    const config = selected ? CHECKBOX_ACTIVE_STYLE_TYPE_COLORS[styleType] : CHECKBOX_STYLE_TYPE_COLORS[styleType]
    if (type === 'line') {
        return selected ? getColorsStyles({ theme, config: CHECKBOX_ACTIVE_STYLE_TYPE_COLORS[styleType] }) : ''
    }
    return getColorsStyles({ theme, config })
}
