import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../store'
import { selectSelf } from '.'
import { SearchState } from '../reducers/search'

export const selectSearchState = createSelector(selectSelf, (state: RootState) => state.search)
export const selectSearch = createSelector(selectSearchState, (state: SearchState) => ({
    loading: state.loading,
    called: state.called,
    data: state.data
}))
export const selectQuerySearch = createSelector(selectSearchState, (state: SearchState) => state.query)
export const selectSearchPagination = createSelector(selectSearchState, (state: SearchState) => ({
    pageIndex: state.pageIndex,
    nextTokens: state.nextTokens
}))
