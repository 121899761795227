/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
import * as ast from 'graphql/language/ast'
import { Kind } from 'graphql/language/kinds'
import { SelectionNode } from 'graphql/language/ast'
import { FieldArrayLike, FieldIndex, ModelType, TypeRef } from './schema'

const astCache: { [key: string]: ast.DocumentNode } = {}

export type SelectionSetOptions = 'FLAT' | 'REFS' | 'REFS_INDICES'
export type QueryAction = 'get' | 'list' | 'find'
export type MutationAction = 'create' | 'update' | 'delete' | 'onChange' | 'runAction'

export function gqlGetItem(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlQuery(model, 'get', selectionSetOptions)
}

export function gqlListItems(
    model: ModelType,
    selectionSetOptions?: SelectionSetOptions,
    index?: string
): ast.DocumentNode {
    return gqlQuery(model, 'list', selectionSetOptions, index)
}

export function gqlFindItems(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlQuery(model, 'find', selectionSetOptions)
}

function gqlQuery(
    model: ModelType,
    action: QueryAction,
    selectionSetOptions: SelectionSetOptions = 'FLAT',
    index?: string
): ast.DocumentNode {
    const cacheKey = getCacheKey('query', action, model.__key, selectionSetOptions, index)
    const cached = astCache[cacheKey]
    if (cached) {
        return cached
    }

    const ModelKey = title(model.__key)
    const sortIndex = index ? model.indices![index].hash === undefined : false
    const filterIndex = index ? model.indices![index].hash !== undefined : false

    let operationName: string
    let inputVarName: string
    let inputVarType: ast.TypeNode
    let selectionSet: ast.SelectionSetNode
    switch (action) {
        case 'get':
            operationName = `get${ModelKey}`
            inputVarName = 'id'
            inputVarType = astNonNullType(astNamedType('ID'))
            selectionSet = buildSelectionSet(model, action, selectionSetOptions)
            break
        case 'list':
            operationName = `list${ModelKey}${title(index)}${sortIndex ? 'Sort' : ''}`
            inputVarName = 'input'
            inputVarType = astNamedType(`List${ModelKey}${title(index)}${sortIndex ? 'Sort' : ''}Input`)
            if (filterIndex) {
                inputVarType = astNonNullType(inputVarType)
            }
            selectionSet = {
                kind: Kind.SELECTION_SET,
                selections: [
                    astSelection('items', buildSelectionSet(model, action, selectionSetOptions)),
                    astSelection('nextToken')
                ]
            }
            break
        case 'find':
            operationName = `find${ModelKey}`
            inputVarName = 'input'
            inputVarType = astNonNullType(astNamedType(`Find${ModelKey}Input`))
            selectionSet = {
                kind: Kind.SELECTION_SET,
                selections: [
                    astSelection('items', buildSelectionSet(model, action, selectionSetOptions)),
                    astSelection('nextToken')
                ]
            }
            break
        default:
            throw new Error(`Unsupported action ${action}`)
    }

    return (astCache[cacheKey] = {
        kind: Kind.DOCUMENT,
        definitions: [
            {
                kind: Kind.OPERATION_DEFINITION,
                operation: ast.OperationTypeNode.QUERY,
                name: astName(operationName),
                variableDefinitions: [
                    {
                        kind: Kind.VARIABLE_DEFINITION,
                        variable: astVariable(inputVarName),
                        type: inputVarType,
                        defaultValue: undefined,
                        directives: []
                    }
                ],
                directives: [],
                selectionSet: {
                    kind: Kind.SELECTION_SET,
                    selections: [
                        {
                            kind: Kind.FIELD,
                            name: astName(operationName),
                            arguments: [astArgument(inputVarName, astVariable(inputVarName))],
                            directives: [],
                            selectionSet
                        }
                    ]
                }
            }
        ]
    })
}

export function gqlCreateItem(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlMutation(model, 'create', selectionSetOptions)
}

export function gqlUpdateItem(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlMutation(model, 'update', selectionSetOptions)
}

export function gqlDeleteItem(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlMutation(model, 'delete', selectionSetOptions)
}

export function gqlOnChangeMutationItem(model: ModelType, selectionSetOptions?: SelectionSetOptions): ast.DocumentNode {
    return gqlMutation(model, 'onChange', selectionSetOptions)
}

export function gqlRunAction(model: ModelType): ast.DocumentNode {
    return gqlMutation(model, 'runAction', 'FLAT')
}

export function gqlOnChangeItem(
    model: ModelType,
    wildcard: boolean,
    selectionSetOptions?: SelectionSetOptions
): ast.DocumentNode {
    return gqlSubscription(model, 'onChange', wildcard, selectionSetOptions)
}

function gqlMutation(
    model: ModelType,
    action: MutationAction,
    selectionSetOptions: SelectionSetOptions = 'FLAT'
): ast.DocumentNode {
    const cacheKey = getCacheKey('mutation', action, model.__key, selectionSetOptions)
    const cached = astCache[cacheKey]
    if (cached) {
        return cached
    }

    const typeName = model.__key[0].toUpperCase() + model.__key.slice(1)
    const actionName = action[0].toUpperCase() + action.slice(1)

    const selections: ReadonlyArray<SelectionNode> = (() => {
        switch (action) {
            case 'onChange':
                return [
                    {
                        kind: Kind.FIELD,
                        name: astName(`${action}${typeName}`),
                        arguments: [astArgument('input', astVariable('input'))],
                        directives: [],
                        selectionSet: {
                            kind: Kind.SELECTION_SET,
                            selections: [
                                astSelection('id'),
                                astSelection('change'),
                                astSelection('before', buildSelectionSet(model, action, selectionSetOptions)),
                                astSelection('after', buildSelectionSet(model, action, selectionSetOptions))
                            ]
                        }
                    }
                ]
            case 'runAction':
                return [
                    {
                        kind: Kind.FIELD,
                        name: astName(`run${typeName}Action`),
                        arguments: [
                            astArgument('ids', astVariable('ids')),
                            astArgument('action', astVariable('action')),
                            astArgument('payload', astVariable('payload'))
                        ],
                        directives: []
                    }
                ]

            default:
                return [
                    {
                        kind: Kind.FIELD,
                        name: astName(`${action}${typeName}`),
                        arguments: [astArgument('input', astVariable('input'))],
                        directives: [],
                        selectionSet: buildSelectionSet(model, action, selectionSetOptions)
                    }
                ]
        }
    })()

    return (astCache[cacheKey] = {
        kind: Kind.DOCUMENT,
        definitions: [
            {
                kind: Kind.OPERATION_DEFINITION,
                operation: ast.OperationTypeNode.MUTATION,
                name: astName(`${actionName}${typeName}`),
                variableDefinitions:
                    action === 'runAction'
                        ? [
                              {
                                  kind: Kind.VARIABLE_DEFINITION,
                                  variable: astVariable('ids'),
                                  type: astNonNullType(astNamedType('[String!]')),
                                  defaultValue: undefined,
                                  directives: []
                              },
                              {
                                  kind: Kind.VARIABLE_DEFINITION,
                                  variable: astVariable('action'),
                                  type: astNonNullType(astNamedType(`${typeName}Action`)),
                                  defaultValue: undefined,
                                  directives: []
                              },
                              {
                                  kind: Kind.VARIABLE_DEFINITION,
                                  variable: astVariable('payload'),
                                  type: astNamedType('AWSJSON'),
                                  defaultValue: undefined,
                                  directives: []
                              }
                          ]
                        : [
                              {
                                  kind: Kind.VARIABLE_DEFINITION,
                                  variable: astVariable('input'),
                                  type: astNonNullType(astNamedType(`${actionName}${typeName}Input`)),
                                  defaultValue: undefined,
                                  directives: []
                              }
                          ],
                directives: [],
                selectionSet: {
                    kind: Kind.SELECTION_SET,
                    selections
                }
            }
        ]
    })
}

function gqlSubscription(
    model: ModelType,
    action: MutationAction,
    wildcard: boolean,
    selectionSetOptions: SelectionSetOptions = 'FLAT'
): ast.DocumentNode {
    const cacheKey = getCacheKey('subscription', action, model.__key, selectionSetOptions)
    const cached = astCache[cacheKey]
    if (cached) {
        return cached
    }

    const typeName = model.__key[0].toUpperCase() + model.__key.slice(1)
    const actionName = action[0].toUpperCase() + action.slice(1)
    return (astCache[cacheKey] = {
        kind: Kind.DOCUMENT,
        definitions: [
            {
                kind: Kind.OPERATION_DEFINITION,
                operation: ast.OperationTypeNode.SUBSCRIPTION,
                name: astName(`${actionName}${typeName}`),
                variableDefinitions: wildcard
                    ? []
                    : [
                          {
                              kind: Kind.VARIABLE_DEFINITION,
                              variable: astVariable('change'),
                              type: astNamedType('Change'),
                              defaultValue: undefined,
                              directives: []
                          },
                          {
                              kind: Kind.VARIABLE_DEFINITION,
                              variable: astVariable('id'),
                              type: astNamedType('ID'),
                              defaultValue: undefined,
                              directives: []
                          }
                      ],
                directives: [],
                selectionSet: {
                    kind: Kind.SELECTION_SET,
                    selections: [
                        {
                            kind: Kind.FIELD,
                            name: astName(`${action}${typeName}`),
                            arguments: wildcard
                                ? []
                                : [astArgument('change', astVariable('change')), astArgument('id', astVariable('id'))],
                            directives: [],
                            selectionSet: {
                                kind: Kind.SELECTION_SET,
                                selections: [
                                    astSelection('id'),
                                    astSelection('change'),
                                    astSelection('before', buildSelectionSet(model, action, selectionSetOptions)),
                                    astSelection('after', buildSelectionSet(model, action, selectionSetOptions))
                                ]
                            }
                        }
                    ]
                }
            }
        ]
    })
}

function getCacheKey(
    operation: 'query' | 'mutation' | 'subscription',
    action: string,
    modelKey: string,
    selectionSetOptions?: SelectionSetOptions,
    index?: string
) {
    let selectionSetOptionsKey = 'FLAT'
    if (selectionSetOptions) {
        selectionSetOptionsKey = `?${selectionSetOptions}`
    }

    let indexPart = ''
    if (index) {
        indexPart = `#${index}`
    }

    return `${operation}#${action}#${modelKey}${indexPart}${selectionSetOptionsKey}`
}

function buildSelectionSet(
    model: ModelType,
    action: QueryAction | MutationAction,
    selectionSetOptions: SelectionSetOptions
): ast.SelectionSetNode {
    const selections = []

    for (const field of Object.values(model.fields)) {
        switch (field.__kind) {
            case 'Scalar':
            case 'ArrayLikeOfScalar':
            case 'RefEnum':
            case 'ArrayLikeOfRefEnum':
                selections.push(astSelection(field.__key))
                break
            case 'RefType':
                switch (selectionSetOptions) {
                    case 'FLAT':
                        if (action === 'find' || action === 'onChange') {
                            selections.push(astSelection(`${field.__key}Id`))
                        } else {
                            selections.push(
                                astSelection(field.__key, {
                                    kind: Kind.SELECTION_SET,
                                    selections: [astSelection('id')]
                                })
                            )
                        }
                        break
                    case 'REFS':
                        selections.push(
                            astSelection(
                                field.__key,
                                buildSelectionSet((field as TypeRef).__ref as ModelType, action, 'FLAT')
                            )
                        )
                        break
                    case 'REFS_INDICES':
                        selections.push(
                            astSelection(
                                field.__key,
                                buildSelectionSet((field as TypeRef).__ref as ModelType, action, 'FLAT')
                            )
                        )
                        break
                }
                break
            case 'ArrayLikeOfRefType':
                switch (selectionSetOptions) {
                    case 'FLAT':
                        if (action === 'find' || action === 'onChange') {
                            selections.push(astSelection(`${field.__key}Ids`))
                        } else {
                            selections.push(
                                astSelection(field.__key, {
                                    kind: Kind.SELECTION_SET,
                                    selections: [astSelection('id')]
                                })
                            )
                        }
                        break
                    case 'REFS':
                        selections.push(
                            astSelection(
                                field.__key,
                                buildSelectionSet(
                                    ((field as FieldArrayLike).of as TypeRef).__ref as ModelType,
                                    action,
                                    'FLAT'
                                )
                            )
                        )
                        break
                    case 'REFS_INDICES':
                        selections.push(
                            astSelection(
                                field.__key,
                                buildSelectionSet(
                                    ((field as FieldArrayLike).of as TypeRef).__ref as ModelType,
                                    action,
                                    'FLAT'
                                )
                            )
                        )
                        break
                }
                break
            case 'Index':
                if (selectionSetOptions == 'REFS_INDICES') {
                    const set = buildSelectionSet((field as FieldIndex).__ref.__model as ModelType, action, 'FLAT')
                    selections.push(
                        astSelection(field.__key, {
                            kind: Kind.SELECTION_SET,
                            selections: [astSelection('items', set), astSelection('nextToken')]
                        })
                    )
                }
        }
    }

    return { kind: Kind.SELECTION_SET, selections }
}

function astName(value: string): ast.NameNode {
    return { kind: Kind.NAME, value }
}

function astNamedType(name: string): ast.NamedTypeNode {
    return { kind: Kind.NAMED_TYPE, name: astName(name) }
}

function astNonNullType(type: ast.NamedTypeNode | ast.ListTypeNode): ast.NonNullTypeNode {
    return { kind: Kind.NON_NULL_TYPE, type }
}

function astVariable(name: string): ast.VariableNode {
    return { kind: Kind.VARIABLE, name: astName(name) }
}

function astArgument(name: string, value: ast.ValueNode): ast.ArgumentNode {
    return { kind: Kind.ARGUMENT, name: astName(name), value }
}

function astSelection(name: string, selectionSet?: ast.SelectionSetNode): ast.SelectionNode {
    return { kind: Kind.FIELD, name: astName(name), arguments: [], directives: [], selectionSet }
}

function title(s?: string): string {
    return s ? s[0].toUpperCase() + s.slice(1) : ''
}

export function getFieldsNames(model: ModelType, selectionSetOptions: SelectionSetOptions) {
    const names = []

    for (const field of Object.values(model.fields)) {
        switch (field.__kind) {
            case 'Scalar':
            case 'ArrayLikeOfScalar':
            case 'RefEnum':
            case 'ArrayLikeOfRefEnum':
                names.push(field.__key)
                break
            case 'RefType':
                switch (selectionSetOptions) {
                    case 'FLAT':
                        names.push(`${field.__key}Id`)
                        break
                    case 'REFS':
                    case 'REFS_INDICES':
                        names.push(field.__key)
                        break
                }
                break
            case 'ArrayLikeOfRefType':
                switch (selectionSetOptions) {
                    case 'FLAT':
                        names.push(`${field.__key}Ids`)
                        break
                    case 'REFS':
                    case 'REFS_INDICES':
                        names.push(field.__key)
                        break
                }
                break
            case 'Index':
                if (selectionSetOptions == 'REFS_INDICES') {
                    names.push(field.__key)
                }
        }
    }

    return names
}
