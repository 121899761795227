import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'

import EnumSelect, { EnumSelectProps } from '@/components/controls/EnumSelect'

interface EnumSelectFieldProps extends EnumSelectProps {
  name: string
}

const EnumSelectField: React.FC<EnumSelectFieldProps> = ({ disabled, ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<string[]>) => {
      const { isSubmitting } = form
      const error = objectPath.get(form.errors, field.name)
      const onChange = (values: string[]) => {
        form.setFieldValue(field.name, values)
      }

      return <EnumSelect {...field} {...props} error={error} disabled={isSubmitting || disabled} onChange={onChange} />
    }}
  </Field>
)

export default EnumSelectField
