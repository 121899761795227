import * as React from 'react'

const LoadingCircle: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, ...props }) => (
  <svg width='2em' height='2em' viewBox='0 0 32 32' fill='none' {...props} className={`immutable ${className}`}>
    <path
      d='M30 16a14 14 0 11-28 0 14 14 0 0128 0h0z'
      stroke='#EFF4FF'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M16 2a14 14 0 0114 14' stroke='#155EEF' strokeWidth={4} strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export default React.memo(LoadingCircle)
