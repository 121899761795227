import { DISPLAY_SIZE_STYLES, DISPLAY_WEIGHT_STYLES } from '@/constants/styles/typography/display'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 24px;
    min-width: 600px;
    max-width: 600px;
`

export const Title = styled.div`
    ${DISPLAY_SIZE_STYLES.sm}
    ${DISPLAY_WEIGHT_STYLES.medium}
`

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
    padding: 8px 0;
    margin: 24px 0;
`

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`
