import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import {
  FieldWrapper,
  StyledForm,
  ActionButton,
  InputBlock,
  Title,
  ButtonsContainer,
  FormError,
  LinkButton
} from '@/components/layouts/LoginLayout/styles'
import LoginLayout from '@/components/layouts/LoginLayout'
import InputField from '@/components/fields/InputField'

import { FORGOT_PASSWORD_URL, HOME_URL, SIGNUP_URL } from '@/constants/routes'
import { LOGIN_FIELD, PASSWORD_FIELD } from '@/constants/forms/signIn'
import { mapAmplifyMessage } from '@/helpers/mapAmplifyMessage'
import loginSchema from '@/helpers/validators/forms/signIn'

import { useAppDispatch } from '@/redux/store'
import { setCognitoClientAction, setIamClientAction } from '@/redux/actions/common/client'

import RequireNewPasswordPage from '../RequireNewPasswordPage'

export interface LoginFormValues {
  [LOGIN_FIELD]: string
  [PASSWORD_FIELD]: string
}

const SignInPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [user, setUser] = useState()
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const handleForgotPassword = useCallback(() => {
    navigate(FORGOT_PASSWORD_URL)
  }, [navigate])
  const handleSignUp = useCallback(() => {
    navigate(SIGNUP_URL)
  }, [navigate])
  const handleCloseSetPassword = useCallback(() => {
    setUser(undefined)
  }, [])
  const handleSubmit = useCallback(
    async (values: LoginFormValues, actions: any) => {
      setLoading(true)
      setError(undefined)
      try {
        const signinUser = await Auth.signIn(values[LOGIN_FIELD], values[PASSWORD_FIELD])
        if (signinUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUser(signinUser)
        } else {
          dispatch(setCognitoClientAction())
          navigate(HOME_URL)
          actions.setSubmitting(false)
        }
      } catch (e: any) {
        dispatch(setIamClientAction())
        actions.setFieldError('password', mapAmplifyMessage(e.message))
      } finally {
        setLoading(false)
      }
    },
    [dispatch, navigate]
  )

  if (user) {
    return <RequireNewPasswordPage user={user} onBack={handleCloseSetPassword} />
  }

  return (
    <LoginLayout>
      <Title>Sign in</Title>
      <Formik
        initialValues={{ [LOGIN_FIELD]: '', [PASSWORD_FIELD]: '' } as LoginFormValues}
        validationSchema={loginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <StyledForm>
            {error && <FormError>{error}</FormError>}
            <InputBlock>
              <FieldWrapper>
                <InputField autoFocus autoComplete='username' inputSize='md' label='EMAIL ADDRESS' name={LOGIN_FIELD} />
              </FieldWrapper>
              <FieldWrapper>
                <InputField
                  autoComplete='password'
                  type='password'
                  inputSize='md'
                  label='PASSWORD'
                  name={PASSWORD_FIELD}
                />
              </FieldWrapper>
            </InputBlock>
            <LinkButton onClick={handleForgotPassword}>Forgot password?</LinkButton>
            <ButtonsContainer>
              <ActionButton type='submit' size='lg' fullWidth disabled={loading || !props.dirty}>
                Sign In
              </ActionButton>
              <ActionButton
                type='button'
                size='lg'
                fullWidth
                styleType='secondary'
                variant='gray'
                onClick={handleSignUp}
              >
                Create account
              </ActionButton>
            </ButtonsContainer>
          </StyledForm>
        )}
      </Formik>
    </LoginLayout>
  )
}

export default SignInPage
