// eslint-disable-next-line no-shadow
export enum UserGroup {
    USER = 'USER',
    ADMIN = 'ADMIN',
    GUEST = 'GUEST'
}

export type UserAttributes = {
    sub: string
    email: string
    name: string
    email_verified: string
}

export type CognitoUser = {
    [key: string]: any
    groups: UserGroup[]
}

export type UserData = {
    user?: CognitoUser
    attributes?: UserAttributes
}
