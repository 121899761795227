/* eslint-disable max-len */
import React from 'react'

function Documents(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        className='stroke'
        d='M11.667 9.167h-5M8.333 12.5H6.667m6.666-6.667H6.667m10-.166v8.666c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 01-1.092 1.093c-.535.272-1.235.272-2.635.272H7.333c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 01-1.092-1.093c-.273-.535-.273-1.235-.273-2.635V5.667c0-1.4 0-2.1.273-2.635a2.5 2.5 0 011.092-1.093c.535-.272 1.235-.272 2.635-.272h5.334c1.4 0 2.1 0 2.635.272a2.5 2.5 0 011.092 1.093c.273.534.273 1.235.273 2.635z'
        stroke='#98A2B3'
        strokeWidth={1.75}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default React.memo(Documents)
