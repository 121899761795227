import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'
import styled from 'styled-components'

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['200']};
`

export const SearchInput = styled.input`
    width: 100%;
    ${TEXT_SIZE_STYLES.sm}
    ${TEXT_WEIGHT_STYLES.medium}
    padding: 4px 8px;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.gray['900']};

    &::placeholder {
        color: ${({ theme }) => theme.colors.gray['400']};
    }
`

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['200']};
`

export const Results = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 90px);
    overflow: auto;
`

export const ResultRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 36px;
    padding: 6px 16px;
    transition: all 100ms linear;

    .controls {
        display: none;
        align-items: center;
        gap: 6px;
        margin-left: auto;
        transition: all 100ms linear;
    }

    .info {
        color: ${({ theme }) => theme.colors.gray['500']};
    }

    &:not(.info):hover {
        background: ${({ theme }) => theme.colors.gray['50']};

        .controls {
            display: flex;
        }
    }
`
