import { gqlCreateItem, gqlDeleteItem, gqlGetItem, gqlRunAction, gqlUpdateItem } from '@/client/graphql'
import { ModelType } from '@/client/schema'
import { Client } from '@/redux/actions/common/client'
import { schema } from '../schema'

export const requestFormData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlGetItem(schema.models[modelKey] as ModelType, 'REFS_INDICES')
    const response = await client?.query({ fetchPolicy: 'no-cache', query, variables })
    const data = response!.data[Object.keys(response!.data)[0]]
    return data
}

export const requestCreateEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlCreateItem(schema.models[modelKey] as ModelType)
    const response = await client?.query({ fetchPolicy: 'no-cache', query, variables })
    const data = response!.data[Object.keys(response!.data)[0]]
    return data
}
export const requestUpdateEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlUpdateItem(schema.models[modelKey] as ModelType)
    const response = await client?.query({ fetchPolicy: 'no-cache', query, variables })
    const data = response!.data[Object.keys(response!.data)[0]]
    return data
}
export const requestRunAction = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlRunAction(schema.models[modelKey] as ModelType)
    const response = await client?.query({ fetchPolicy: 'no-cache', query, variables })
    const data = response!.data[Object.keys(response!.data)[0]]
    return data
}
export const requestDeleteEntityData = async (modelKey: string, client: Client, variables: Record<string, any>) => {
    const query = gqlDeleteItem(schema.models[modelKey] as ModelType)
    const response = await client?.query({ fetchPolicy: 'no-cache', query, variables })
    const data = response!.data[Object.keys(response!.data)[0]]
    return data
}
